<main>
  <article>
    <!--#region content-->

    <div class="container-fluid">
      <div class="row align-items-start">
        <div class="col article">
          <div class="article-img align-items-center">
            <img src="assets/image/solid.jpg" title="" alt="" />
          </div>
        </div>
      </div>
      <div class="row align-items-start">
        <!--#region Left hand-->
        <div class="col-lg-3 m-15px-tb blog-aside">
          <!--#region video -->
<div class="widget widget-latest-post">
  <div class="widget-body">
    <div class="embed-responsive embed-responsive-21by9">
      <h5>Video loading...</h5>
      <youtube-player
        videoId="GtgVgtRMsyc"
        suggestedQuality="highres"
        style="width: 100%"
        id="youtube"
      >
      </youtube-player>
    </div>
  </div>
</div>
<!--#endregion -->

          <!--#region Rate -->
<div class="widget widget-latest-post">
  <div class="widget-title">
    <div class="row">
      <div class="col-6">
        <h3 style="color: #0396A6;" >Rate</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <p style="font-size: 12px">
          (Current {{ currentRate }} <i class="fa fa-star"></i>)
        </p>
      </div>
    </div>
  </div>
  <div class="widget-body">
    <div class="">
      <div class="rating">
        <!--#region stars-->
        <input
          type="radio"
          name="rating"
          id="rating-5"
          (click)="onRate(5)"
        />
        <label for="rating-5"></label>
        <input
          type="radio"
          name="rating"
          id="rating-4"
          (click)="onRate(4)"
        />
        <label for="rating-4"></label>
        <input
          type="radio"
          name="rating"
          id="rating-3"
          (click)="onRate(3)"
        />
        <label for="rating-3"></label>
        <input
          type="radio"
          name="rating"
          id="rating-2"
          (click)="onRate(2)"
        />
        <label for="rating-2"></label>
        <input
          type="radio"
          name="rating"
          id="rating-1"
          (click)="onRate(1)"
        />
        <label for="rating-1"></label>
        <!--#endregion-->

        <!--#region Emojis-->
        <div class="emoji-wrapper">
          <div class="emoji">
            <!--#region none -->
            <img src="assets/icons/0.png" />
            <!--#endregion-->

            <!--#region 1s -->
            <img src="assets/icons/00.png" />
            <!--#endregion-->

            <!--#region 2s -->
            <img src="assets/icons/2.png" />

            <!--#endregion-->

            <!--#region 3s -->
            <img src="assets/icons/3.png" />
            <!--#endregion-->

            <!--#region 4s -->
            <img src="assets/icons/4.png" />
            <!--#endregion-->

            <!--#region 5s -->
            <img src="assets/icons/5.png" />
            <!--#endregion-->
          </div>
        </div>

        <!--#endregion-->
      </div>

      <button
        type="button"
        class="btn input btn-outline-dark btn-rounded button-wrapper"
        (click)="onSetRate()"
      >
        Submit
      </button>
      <br />
      <div
        class="alert alert-warning"
        *ngIf="!firstRate == false"
        role="alert"
      >
        Be the first to rate this Content!
      </div>
      <div
        class="alert alert-success alert-dismissible fade show"
        role="alert"
        *ngIf="!success == false"
      >
        <strong>Success!</strong> Thank you for rating us!
      </div>
    </div>
  </div>
</div>
<!--#endregion -->

          <!--#region knowledge check -->

<div
  class="
    alert alert-dismissible
    fade
    show
    widget widget-author
    timeline-card
  "
  role="alert"
>
  <div
    class="media align-items-center"
    (click)="OpenPopUp(check, 'lg')"
  >
    <a role="button" href="javascript:void(0)">
      <h3
        
        style="font-size: 20px; font-weight: 700; margin: 0;color: #0396A6;"
      >
        Check your Knowledge!
      </h3>
    </a>

    <img src="assets/icons/trophy.png" class="follow" />
  </div>

  <button
    type="button"
    class="close"
    data-dismiss="alert"
    aria-label="Close"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<!--#region follow pop up-->
<!-- #region pop up screen for solution-->

<ng-template #check let-modal>
  <div class="modal-header">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <i class="fa fa-close"></i>
    </button>
  </div>

  <div class="modal-body">
    <div class="row h-100">
      <div class="col-12">
        <div class="align-items-center">
          <img src="assets/image/question.jpg" />
        </div>
        <div
          class="about-section bg-white rounded shadow-sm py-5 px-4"
        >
          <hr />
          <form class="form-group">
            <!--#region Q1-->
            <div class="row">
              <div class="col">
                <p>1. <u>Open Closed Principle state that:</u></p>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="q1"
                    id="q1r1"
                    value="A class can be modified, its open for changes."
                    [(ngModel)]="qestions.q1"
                  />
                  <label class="form-check-label" for="q1r1">
                    A class can be modified, its open for changes.
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="q1"
                    id="q1r2"
                    value="A class should be responsible on only one
                    functionality."
                    checked
                    [(ngModel)]="qestions.q1"
                  />
                  <label class="form-check-label" for="q1r2">
                    A class should be responsible on only one
                    functionality.
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="q1"
                    id="q1r3"
                    checked
                    value="A class is open for extentions."
                    [(ngModel)]="qestions.q1"
                  />
                  <label class="form-check-label" for="q1r3">
                    A class is open for extentions.
                  </label>
                </div>
              </div>
            </div>
            <!--#endregion-->
            <hr />
            <!--#region Q2-->
            <div class="row">
              <div class="col">
                <p>2. <u>Design Patterns</u></p>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="q2"
                    id="q2r1"
                    value="Are High level designs."
                    [(ngModel)]="qestions.q2"
                  />
                  <label class="form-check-label" for="q2r1">
                    Are High level designs.
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="q2"
                    id="q2r2"
                    value="Give an indepth instruction for a solutions."
                    checked
                    [(ngModel)]="qestions.q2"
                  />
                  <label class="form-check-label" for="q2r2">
                    Give an indepth instruction for a solutions.
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="q2"
                    id="q2r3"
                    checked
                    value="Give simple and general instructions on how to
                    make your code much better."
                    [(ngModel)]="qestions.q2"
                  />
                  <label class="form-check-label" for="q2r3">
                    Give simple and general instructions on how to
                    make your code much better.
                  </label>
                </div>
              </div>
            </div>
            <!--#endregion-->
            <hr />
            <!--#region Q3-->
            <div class="row">
              <div class="col">
                <p>
                  3. <u>Dependency Inversion Principle State that</u>
                </p>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="q3"
                    id="q3r1"
                    value="A class should only implement methods it needs."
                    [(ngModel)]="qestions.q3"
                  />
                  <label class="form-check-label" for="q3r1">
                    A class should only implement methods it needs.
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="32"
                    id="q3r2"
                    value="Classes shouldn't depend on each other,they should depend on abstractions."
                    checked
                    [(ngModel)]="qestions.q3"
                  />
                  <label class="form-check-label" for="q3r2">
                    Classes shouldn't depend on each other,they should
                    depend on abstractions.
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="q3"
                    id="q3r3"
                    checked
                    value="Classes can depend on eachother on some
                    conditions."
                    [(ngModel)]="qestions.q3"
                  />
                  <label class="form-check-label" for="q3r3">
                    Classes can depend on eachother on some
                    conditions.
                  </label>
                </div>
              </div>
            </div>
            <!--#endregion-->
          </form>
          <ng-template #check let-modal>
            <div class="modal-header">
              <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
              >
                <i class="fa fa-close"></i>
              </button>
            </div>

            <div class="modal-body">
              <div class="row h-100">
                <div class="col-12">
                  <div
                    class="
                      about-section
                      bg-white
                      rounded
                      shadow-sm
                      py-5
                      px-4
                    "
                  >
                    <div class="alert alert-{{ alert }}" role="alert">
                      {{ replay }}
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col">
                        <table class="table">
                          <thead class="thead">
                            <tr scope=" table-success">
                              <th scope="col ">Corract Answers</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              class="table-success"
                              *ngFor="let row of correct"
                            >
                              <td class="table-success">
                                {{ row }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col">
                        <table class="table">
                          <thead class="thead-danger">
                            <tr scope=" table-danger">
                              <th scope="col table-danger">
                                Wrong Answers
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              class="table-danger"
                              *ngFor="let wrong of wrong"
                            >
                              <td class="table-danger">
                                {{ wrong }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <div class="col">
            <button
              type="button"
              class="
                btn btn-outline-dark btn-rounded
                button-wrapper
                input
              "
              (click)="OpenPopUp(check, 'lg')"
              (click)="onCheck()"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- #endregion -->

<!--#endregion-->
<!--#endregion -->

        </div>
        <!--#endregion-->
        <!--#region Middle hand-->
        <div class="col-lg-6 m-15px-tb">
          <!--#region article-->
          <article class="article">
            <div class="article-title">
              <div class="row">
                <div class="col-6 col-md-9">
                  <h2>S.O.L.I.D Design Principles</h2>
                </div>
                <div class="col-6 col-md-3">
                  <span (click)="onChange()"
                    ><input
                      type="checkbox"
                      checked
                      data-on="Tunisian"
                      data-off="English"
                      data-toggle="toggle"
                      data-onstyle="info"
                      data-offstyle="secondary"
                  /></span>
                </div>
              </div>
            </div>
            <hr />
            <!--#region english article-->
            <div
              class="article-content"
              style="font-family: 'Segoe UI'"
              *ngIf="english == true"
            >
              <p>
                To build a
                <span style="color: red; font-weight: bold">solid</span> house
                you need to make a good plan. <br /><br />
                And since the Object-oriented programming concept is so close to
                our real life, we can consider the code here as our house, and
                to develop a good one we need a good design, and by design, we
                mean code structure.
              </p>
              <p>
                The <span style="color: red">problem</span> is, there may come a
                time when you start coding and notice that your code is getting
                messy or your application's performance is getting worse.
                <br />
                Sometimes you even find out that among the classes you created
                there are ones who have thousands of lines of code, literally
                each of them is responsible for doing different operations
                non-related to the others thus you can no longer identify the
                responsibility of each one.
                <br />
                Or finding two or more classes, that are responsible for doing
                the same action.
              </p>
              <p>
                We call this
                <span style="font-weight: bold">tight coupling</span>, which
                refers to the tight relation between different classes, because
                of which our code won’t be stable and organized anymore. and
                once your code is no longer readable, you as a developer won’t
                be able to understand what you wrote anymore. <br />It’s the
                same as being organized in our real life, if we refer to
                occupations as classes in our code. here for example we can’t
                make a baker and a violist help a mechanic fix a car, each has
                its own job that he can do it perfectly, and the same thing goes
                for developing a program in OOP.
              </p>
              <h4>What is the solution to all of these?</h4>
              <p>
                There is a solution to all these problems which is in our case
                the topic of this article: design principles and patterns.
                <br />Now let’s understand both these terms clearly. Generally,
                both of these designs are a set of instructions that will guide
                us to build our project.
              </p>
              <p>The difference though is:</p>
              <table class="table">
                <thead class="thead-light">
                  <tr scope=" table-primary">
                    <th scope="col bg-secondary">Design Principals</th>
                    <th scope="col ">Design Patterns</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Gives general instructions on How to act and think</td>
                    <td>
                      Gives instruction on what steps to do and how to implement
                      a solution in your code
                    </td>
                  </tr>
                  <tr>
                    <td>
                      A High Level Design (HLD): Doesn’t give you implementation
                      details it’s just a mind set on how to think, it gives
                      that big picture on how your code should be organized
                    </td>
                    <td>
                      A Low Level Design (LLD): Provide a complete design
                      process. We can refer to it as a complete HLD, converting
                      this later into detailed solution that shows you the
                      in-depth instructions.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Similar to a “Guide Book” it gives an overall view of the
                      solution
                    </td>
                    <td>
                      Similar to a “Manual Book”, it gives more in-depth
                      information and instruction than a guide book.
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <br />
              <p>
                Now let’s give a
                <span style="font-weight: bold">real-life</span> example about
                “<span style="color: red">training session</span>” and
                <span style="color: red">“workshop</span>” which may clear up
                the difference between these two.
                <br />

                In the training session, you hear a person talk and explain some
                concepts its like the act of ‘teaching’ On the other hand, a
                “workshop” is an advanced way of teaching where more
                instructions and details are given its an implementation or
                practice of the concepts you learned.
                <br />
                Well in our case design principles are referred to as a training
                session while design patterns are workshops.
              </p>
              <h4>Design Principles</h4>
              <p>
                Now we will get more in depth in understanding Design
                principles.<br />

                There are tons of design that we can implement but the most
                famous and important one is what we call “
                <span style="color: red; font-weight: bold"
                  >SOLID Design principle</span
                >” .
                <br />
                This later is a combination or a set of five design principles,
                that will help us make our code more understandable and
                flexible.
              </p>
              <p>
                Starting with the “<span style="font-weight: bold">S</span>” in
                SOLID which refers to:<br />
                <span style="color: red"
                  >Single Responsibility Principal (SRP)</span
                >
                <br />
                <br />
                It states that: “<span style="font-weight: bold"
                  >a class should have only one reason to change</span
                >”.<br />
                In simple terms, This design state that each class or function
                should be responsible for doing one single operation nothing
                more, and the content of each class has to be related to that
                single responsibility
                <br />
                <br />
                Now what do we achieve after implementing this design:
                <br />
                <br />
                <u>Reusability</u>: When your project gets bigger, maintaining
                it will become harder and more complex, in this case organizing
                your code and giving each class one responsibility will make it
                easy to understand, manipulate and maintain even for new
                developers who joined your team it would be easier for them to
                understand the workflow of the project and its structure
                quickly. <br />
                <br /><u>Flexible</u>: Here we have the ability to easily add a
                new feature to our code since we will have a clear view of each
                class’s main role hence adding new functionality will be an easy
                task to do <br />
                <br /><u>The Tight coupling issue is solved</u>: The problem we
                talked about earlier, on how responsibilities should be shared
                between classes, to loosen up this coupling we implement the SRP
                principle to separate these classes
                <br />
                <br />
                <u style="color: darkblue">Exemple...</u>
                <br />Lets give a simple and funny example to explain this
                concept:<br />
                We have chef who works in a restaurant, this chef is responsible
                of making meals, delivering the meals to customers and after
                they finish he wash the dishes to prepare new ones.
              </p>
              <img class="centralized-image" src="assets/image/1.PNG" />
              <br />
              <p>
                As we can see our chef is doing more than one action.<br /><br />
                The single responsibility principle isn’t applied here, and if
                we try to, our chef will only be doing one single action which
                is cooking meals, meanwhile, we can bring a dishwasher who will
                be responsible for washing dishes and finally a waiter to server
                the customers.
                <br /><br />
                Its just as simple as that
              </p>

              <img class="centralized-image" src="assets/image/2.PNG" />
              <br />
              <p>
                Here we applied the design principle and if we want to add any
                functionality it wont be hard for us since we already gave each
                class one single role.
                <br />
                <br />
                The next stop is the “<span style="font-weight: bold">O</span>”
                in SOLID which stands for:<br />
                <span style="color: red">Open-Closed Principal (OCP)</span>
                <br />
                <br />
                It states that: “<span style="font-weight: bold"
                  >software entities (classes, modules, functions, etc.) should
                  be open for extension, but closed for modification</span
                >”.<br />
                In simple terms it is similar to Polymorphism.<br />
                Let’s say we have a vehicle class, and this later can be for
                example a car, a plane, or a bus.<br />
                For now, we want this class to have two methods AddEngine() so
                it can work and ChangeColor()
              </p>
              <img class="centralized-image" src="assets/image/3.PNG" />
              <br />
              <p>
                But the trouble is, our vehicle may be a car or a plane, and the
                implementation of an engine varies from one to the other, thus
                the methods for adding engines can vary based on the vehicle
                type.<br /><br />
                In this case we notice that the vehicle can take many forms
                anytime, that’s why we call it polymorphism poly for many and
                morphism for forms.<br /><br />
                Also, according to the OCP, a class should be closed for
                modification and in our case we are trying to modify the class
                by changing the function so it can Add for us an engine for our
                plane.<br /><br />
                To resolve this issue, we simply need to extract the various
                shapes we require, divide them into separate classes that
                implement one abstract class, and by doing so, classes that
                implement it will be able to override its methods.
              </p>
              <img class="centralized-image" src="assets/image/4.PNG" />
              <br />
              <p>
                By making both car and plane classes implementing vehicle
                abstract class, both can override the method however they want,
                and this way we are correctly achieved the two conditions of the
                OCP.<br /><br />

                -<span style="color: red">class is open for extensions</span>
                since we still can add to it whatever features and
                functionalities we like.
                <br />
                -<span style="color: red"
                  >class is closed for modification</span
                >
                since now if we want to make any changes, we only have to create
                a new class that override the method we want to change depending
                on the situation.
                <br /><br />
                Now, let's talk about the “<span style="font-weight: bold"
                  >L</span
                >” in SOLID which stands for:<br />
                <span style="color: red"
                  >Liskov Substitution Principle (LSP)</span
                >
                <br />
                <br />
                It states that: “<span style="font-weight: bold"
                  >objects of a superclass shall be replaceable with objects of
                  its subclasses without breaking the application </span
                >”.<br />
                <br />
                This design principle extends the OCP, and by extending we mean
                that it added the substitution operation to the implementation
                of the OPC principle, which state that “if A is a subset of B,
                objects of type B may be replaced with Objects of type A”.<br /><br />
                In our case we can replace objects of vehicle type by object of
                car or plane type like the example below
              </p>
              <img class="centralized-image" src="assets/image/5.PNG" />
              <br /><br />
              <p>
                But this is not all what Liskov is about, we may have a problem
                here.<br /><br />
                The law states that a bicycle is a vehicle, well we can change
                the color of a bicycle but a bike doesn’t have an engine.<br /><br />
                In this case if we try to add a bicycle class that implement
                vehicle, the add engine method will throw an exception.
              </p>
              <img class="centralized-image" src="assets/image/6.PNG" /><br />
              <p>
                to fix this issue we can create an interface that consist of the
                Add Engine method and another one only for the change color
                method.<br /><br />
                And make our main class implement it like the following example
              </p>
              <img class="centralized-image" src="assets/image/7.PNG" /><br />
              <p>
                And for the other classes the car and plane classes wont change
                but the bicycle class now will have to only implement one
                interface
              </p>
              <img class="centralized-image" src="assets/image/8.PNG" /><br />
              <p>
                But there's another issue here: we can't replace objects of the
                vehicle type with objects of the bicycle type because the latter
                now implements the IChangeColor Interface rather than the
                Vehicle abstract class.
              </p>
              <img class="centralized-image" src="assets/image/9.PNG" /><br />
              <p>
                To fix this issue we have to replace Vehicle by IchnageColor
                interface instead like the following
              </p>
              <img class="centralized-image" src="assets/image/10.PNG" /><br />
              <p id="ISP">
                And because the Vehicle class implements the IChange Color
                Interface, everything will continue to work correctly.
                <br /><br />
                Now moving to the “<span style="font-weight: bold">I</span>” in
                SOLID which refers to:<br />
                <span style="color: red"
                  >Interface Segregation Principle (ISP)</span
                >
                <br />
                <br />
                It states that: “<span style="font-weight: bold"
                  >no client should be forced to depend on methods it does not
                  use. </span
                >”.<br />
                <br />
                In other words, instead of having a big interface with many
                methods we need to split it to many small ones so an object will
                never be forced to implement Interfaces that contain
                functionalities he doesn’t need.<br /><br />
                When do we have to implement this principle:<br /><br />
                -when an interface has provided many operations, but when
                implementing it most of these operations won’t be used or
                implemented like the previous example of the vehicle and the
                bicycle and how we had to split the methods within the vehicle
                class into two interfaces Add Engine and Change color.
              </p>
              <br /><br />
              Now moving to the “<span style="font-weight: bold">D</span>” in
              SOLID which refers to:<br />
              <span style="color: red"
                >Dependency Inversion Principle (DIP)</span
              >
              <br />
              <br />
              It states that: “<span style="font-weight: bold"
                >no client should be forced to depend on methods it does not
                use. </span
              >”.<br />
              also<br />
              "<span style="font-weight: bold"
                >Abstractions should not depend on details. Details should
                depend on abstractions</span
              >".

              <br />
              <br />
              <p>
                Which mean there shouldn’t be a direct interaction between high
                level and low level class, an abstraction would be the
                middleware between these two <br /><br />To simplify this let’s
                take it step by step and go back to the vehicle example<br /><br />
                In the beginning we said that the vehicle class has two methods
                add engine and change color<br /><br />
                Lets consider adding a new method wash vehicle.<br /><br />
                To begin with, adding a method is never a good idea because it
                violates the Open-Closed principle, which states that a class
                should never be modified and is only open for extension. So, how
                do you add this extension?<br />
                Another example is if you have two classes A and B, and the A
                class creates a B class instance.
              </p>
              <img class="centralized-image" src="assets/image/11.PNG" /><br />
              <p>
                Here we violated the saying"<span style="font-weight: bold"
                  >high level modules should not depend on low level
                  modules</span
                >" in other words A depends on B. <br /><br />
                Now lets imagine that we have a 100 of A classes and some
                changes came up where A needs to create an instance of class c
              </p>
              <img class="centralized-image" src="assets/image/12.PNG" /><br />
              <p>
                The code we implemented in the previous capture need to be done
                in all 99 other A classes.<br />
                Here when dependency inversion come into the scene.<br />
                The dependency between A classes and both B and C classes will
                be replaced with an abstraction, an interface in our case
              </p>
              <img class="centralized-image" src="assets/image/13.PNG" /><br />
              <p>
                As We can see we created an interface and made the class B
                implement it and class A create a reference for it, now if we
                add any method or make any changes since class A don’t depend
                directly o class B We wont Have any problem anymore .
                <br /><br />An abstraction, in our case an interface, will
                replace the dependency between A classes and both B and C
                classes.
              </p>
              <br />
              <hr />
              <p class="blockquote-footer">
                Feel free to rate the blog and send me feedback,questions or
                suggestions through the
                <span style="color: red; font-weight: bold">comment</span>
                section below. <br />To be updated for Upcoming content, please
                <span style="color: red; font-weight: bold">follow</span> this
                website.
              </p>
            </div>
            <!--#endregion -->
            <!--#region tunisian article-->
            <div
              *ngIf="tunisian == true"
              class="article-content"
              style="direction: rtl; display: inline-block; text-align: right"
            >
              <p>
                ديما يقلك باش تنجم تبني دار باهية يلزمك plan تحفون.<br /><br />
                و بما انو ال<span style="color: red; font-weight: bold"
                  >Object-oriented programming</span
                >
                يشبه برشا لواقعنا نجموا نعتبروا هوني الCode متاعنا هو الدار الي
                باش نبنيوها و الplan الي حكينا عليه هو ال design متاعو و بلغة
                اخرى ال code structure.<br /><br />
                المشكل هوني,لازم يجي وقة و انت تكودي و تفيق بلي الcode متاعك دخل
                بعضو و الperformance طاحت.<br />
                اصلا ساعات تلاحض بالي الcode الي كتبتو عادي تلق فيه 100 صطر و كل
                واحد يخدملك في حاجة ما عندها حتى علاقة بلوخرين الي يخليك تدخل
                بعضك و تنسى كل وحدة شنو لازمها تعمل في الاصل .<br /><br />
                كيف ما تنجم ساعات تلق classes يشبهوا لبعضهم لدرجة انهم تقريب
                يخدمولك في نفس الحاجة.<br />
                نفس الشيء كي تبدا ماكش منظم في حياتك.<br /><br />كان نتخيلوا
                الخدمة في دنيتنا على اساس هي classe في الcode متاعنا.ما نجموش
                مثال نجيبوطباخ و موسيقي باش يعاونوا ميكانيكي يصلح كرهبة.نفس
                الحاجة كيف نحبوا نراوها من ناحية الcode . <br /><br />
              </p>
              <h4>والحل؟</h4>
              <p>
                الحل هو موضوعنا<span style="font-weight: bold"
                  >design principles </span
                ><span style="font-weight: bold">و design patterns.</span><br />
                عموما الزوز ماهم الا مجموعت تعليمات و نصايح الي باش بعاونون في
                تنظيم الcode متاعنا.<br />
              </p>
              <table class="table">
                <thead class="thead-light">
                  <tr scope=" table-primary">
                    <th scope="col bg-secondary">Design Principals</th>
                    <th scope="col ">Design Patterns</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>مجموعة نصائح توريك كيفاش تفكر و تتصرف كي تكودي</td>
                    <td>
                      مجموعة تعليمات الي باش توريك الحل و كيفاش تخدمو زادا
                    </td>
                  </tr>
                  <tr>
                    <td>
                      High Level Design (HLD) <br />يعني ما تعتيكش تفاصيل على
                      شنو يلزمك تكتب ولا كيماش,ماهي الا باش تعطيك تصويرة كبيرة
                      على الcode متاعك كيفاه باش يتنظم.
                    </td>
                    <td>
                      Low Level Design (LLD)<br />
                      الي ماهو الا الHigh Level Design اما كامل,يعني باش يتعمق
                      في الحلول الي حكينا عليهم,و يوريك كيفاه تظبقهم
                    </td>
                  </tr>
                  <tr>
                    <td>
                      نجموا نشبهوه ب“Guide Book” الي ما يعطينا كان نظرة شاملة
                      للحل
                    </td>
                    <td>
                      نجموا نشبهوه ب “Manual Book” الي هو يعطي اكثر تفصيل
                      مالGuide Book.
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <br />
              <p>
                نجموا نقارنوهم الزوز بميثال بال “training session” و
                ال“workshop” .<br />
                في “training session” ماك الا تسمع في شكون يحكي و يفسرلك في
                فكرة,اما ال“workshop” متعمق اكثر مالتعليم, هو تطبيق للي تعلمت. و
                في حالتنا نجموا نشبهوا “training session” بالdesign principles
                وال“workshop” بال design patterns .
              </p>
              <h4>Design Principles</h4>
              <p>
                تو باش نزيدوا نتعمقوا و نفهموا الDesign principles,و الي من
                اشهرهم “SOLID Design principle”’الي ماهو الا يشمل 5 Design ياسر
                معروفين و مهمين.<br /><br />
                نبداو بال"<span style="font-weight: bold">S</span>"في “SOLID"<br />
                <span style="color: red"
                  >Single Responsibility Principal (SRP):</span
                ><br /><br />
                الSRP يقوللنا “<span style="font-weight: bold"
                  >a class should have only one reason to change</span
                >”, يعني كل class يلزمها تكون مسؤولة على خدمة وحدة لاكثر لا
                اقل.<br />
                و الي نربحوه من تطبيق ال Design هذا هو
                <br /><br />-"Reusability" متاع خدمتنا و الي نقصدوا بيها, ميثال
                كيف مشروعك يكبر و الcode متاعك يكثر و وقت لي تحب نهار انت ولا
                غيرك باش يبدل حاجة ولا باش يكمل يخدم وين وقفت,ما يفهم منو شيىء
                .<br />
                هوني وين بالحق الcode متاعك لازمو تنظيم <br />-Flexible: بما انو
                كلclass باش نعتيوها دور واحد ة كيف تجي باش تزيد functionality
                جديدة باش تعرف وين تحطها.<br /><br />
                باش نزيدوا نوضحوها,هات نقولوا عندنا chef يخدم في مطعم,يطيب, يقدم
                لماكلة, و بعد ما الclient يكمل يغسل الاطباق.<br />
              </p>
              <img class="centralized-image" src="assets/image/1.PNG" /><br />
              <p>
                كيف ما نراو الchef متاعنا يخدم في اكثر من حاجة و ماهوش قاعد يطبق
                في ال
                <span style="font-weight: bold"
                  >Single Responsibility Principal</span
                >
                و يشد في حاجة برك يخدمها.<br />
                هوني لحل نخليوا طباخنا يطيبلنا كهو,نجيبوا حد اخر يغسل هو الماعون
                و معاهم serveur. و وين تتزاد خدمة اخرى نجيبوا حد اخر يعملها.
              </p>
              <img class="centralized-image" src="assets/image/2.PNG" /><br />
              <p>
                بالنسبة لل"<span style="font-weight: bold">O</span>"في
                “SOLID"<br />
                <span style="color: red">Open-Closed Principal (OCP)</span
                ><br /><br />
                الOCP يقوللنا “<span style="font-weight: bold"
                  >software entities (classes, modules, functions, etc.) should
                  be open for extension, but closed for modification</span
                >”<br /><br />

                هات نقولوا عندنا وسيلة نقل,تنجم تكون كرهبة,كميونةةطيارة, ولا اي
                حاجة.<br />
                صنعنالها class و سميناها vehicle ,و زدنا فيها 2 methods ,وحدة
                مثال باش تزيدلنا Moteur للvehicle متاعنا و لخرى باش تبدللها
                لونها "()AddEngine " و "()ChangeColor " .<br />
              </p>
              <img class="centralized-image" src="assets/image/3.PNG" /><br />
              <p>
                المشكلة هوني موش نفس الطريقة الي نزيدوا بيها Moteur للكرهبة
                نفسها الي نزيدوه بيها للطيارة, ال implementation موش كيف كيف.<br />
                اما ال OCP يقوللنا الي الclass كان تصنعت ما يلزم يصيرلها حتى
                تغيير مرة اخرى وباش نجموا نحافظوا على القاعدة هاذه يلزمنا نخرجوا
                2 class جدد مل vehicle وحدة car و الخرى plane و الزوز هاذوما
                يورثو خاصيات الclass vehicle وبمافيهم الmethods و كل class
                تخدمهم بالطريقة اللازمة و الclass vehicle تولي abstract باش
                نجموا نعملوا هكا.<br />
              </p>
              <img class="centralized-image" src="assets/image/4.PNG" /><br />
              <p>
                توا نلقاو رواحنا طبقنا الزوز قواعد الي عطاهملنا ال OCP
                <br /><br />
                -اولا,"<span style="font-weight: bold"
                  >class is open for extensions </span
                >" بما انو نجمو نزيدوها اي features نحبوا عليهم منغير حتى مشكلة
                <br />-ثانيا,"<span style="font-weight: bold"
                  >class is closed for modification</span
                >
                " خاطر تو اي تغيير نحبوا نعملوه موش لازم يكون في الclass vehicle
                اما في اي class اخرى تimplementiha.
              </p>
              <br /><br />
              <p>
                بالنسبة لل"<span style="font-weight: bold">L</span>"في
                “SOLID"<br />
                <span style="color: red"
                  >Liskov Substitution Principle (LSP)</span
                ><br /><br />
                الLSP يقوللنا “<span style="font-weight: bold"
                  >objects of a superclass shall be replaceable with objects of
                  its subclasses without breaking the application </span
                >”<br /><br />
                الLSP يكمل الOCP,وهوني نقصدوا الي هو يزيدوا عملية الاستبدال
                "substitution" الي تعني اذا كان A Object ينجم يعوض Object B,اي
                Object اخر الtype متاعو B ينجم يتعوض ب Object الtype متاعو A.
                وفيحالتنا نجمو نعملو هكا زادا
              </p>
              <img class="centralized-image" src="assets/image/5.PNG" /><br />
              <p>
                اما عندنا مشكلة اخرى الي Liskov باش يحلهالنا.<br />القانون يعتبر
                الbicyclette وسيلة نقل كيف الكرهبة و الطيارةو كيف نجيو باش
                نزيدوا الbicyclette class مع الاخرين باش تطلعلنا exception خاطر
                صحيح احنا نجموا نبدلوا لون الbicyclette اما هي اصلا ما عندهاش
                moteur مالا كيفاه باش نحطو ال()methods AddEngine هوني...
              </p>
              <img class="centralized-image" src="assets/image/6.PNG" /><br />
              <p>
                باش يتصلح المشكل هاذا نصنعوا 2 interfaces وحدة نحطوا فيها ال Add
                Engine method و الاخرى change color method.
              </p>
              <img class="centralized-image" src="assets/image/7.PNG" /><br />
              <p>و الbicycle class الوحيدة الاي باش تتبدل</p>
              <img class="centralized-image" src="assets/image/8.PNG" /><br />
              <p>
                اما تو الSubstitution Principle معادش موجود على خاطر
                الbicyclette class ما عادش ت implementi ال vehicle class اما ال
                interface IchnageColor .
              </p>
              <img class="centralized-image" src="assets/image/9.PNG" /><br />
              <p>وباش نصلحوا المشكلة هاذي</p>
              <img class="centralized-image" src="assets/image/10.PNG" /><br />
              <p>
                بالنسبة لل"<span style="font-weight: bold">I</span>"في
                “SOLID"<br />
                <span style="color: red"
                  >Interface Segregation Principle (ISP)</span
                ><br /><br />
                الISP يقوللنا “<span style="font-weight: bold"
                  >no client should be forced to depend on methods it does not
                  use.</span
                >”<br /><br />
                و المقصود هوني الي كيف تكون عندنا Interface تقوملنا ب برشا
                حاجاتة لازم تتقسم باش مبعد كل class ما يكونش مجبور عليها انها ت
                implementi عمليات في ال Interface هاكي و هي ما حاجتهاش بيهم.<br /><br />
                بالنسبة لل"<span style="font-weight: bold">D</span>"في
                “SOLID"<br />
                <span style="color: red"
                  >Dependency Inversion Principle (DIP)</span
                ><br /><br />
                الDIP يقوللنا “<span style="font-weight: bold"
                  >high level modules should not depend on low level modules,
                  Both should depend on abstractions</span
                ><br />” يقلك و زادة <br />"<span style="font-weight: bold"
                  >Abstractions should not depend on details. Details should
                  depend on abstractions</span
                >"<br /><br />
                وهوني يحب يقول الي ما يلزمش يكون فما تفاعل مباشر(direct
                interaction ) مابين 2 class لازم يكون فما وسيط
                بيناتهم(middleware ) باش نبسطوها ،خلينا نرجعوا لمثال الvehicle
                ماللول قلنا الي الclass عندها 2 methods وحدة لاهية بالmoteur و
                الاخرى بلونها.<br /><br />
                توة نحبوا نزيدوا method اخرى باش تغسللنا الvehicle متاعنا (ساعة
                اول حاجة ، كيفاه زدنا الmethod هاذي موش فكرة باهية خاطر باش
                نوليو ما عادش نطبقوا مبدأ Open-Closed ، الي يقوللنا"a class
                should never be modified and is only open for extension" )<br />
                هات ناخذوا مثال A class باش تصنعلنا instance للB class .
              </p>
              <img class="centralized-image" src="assets/image/11.PNG" /><br />
              <p>
                هنا ما طبقناش ISP كي قال "high level modules should not depend
                on low level modules "بعبارة أخرى A هوني depends علىB. <br />هات
                نتخيلوا الي عندنا 100 class من A واضطرينا باش نزيدوا حاجات
                للclass A وين تلزينا باش نخليوها تصنعلنا instance لل class c.
              </p>
              <img class="centralized-image" src="assets/image/12.PNG" /><br />
              <p>
                توة تخيلوا ال code هاذا الي كتبناه لازمو يتعود 99 مرة. هوني وين
                نطبقوا ال dependency inversion .
              </p>
              <img class="centralized-image" src="assets/image/13.PNG" /><br />
              <p>
                و هوني كحل زدنا interface هي الي جات مابن الA class و الB class
                و لعبت دور الintermédiaire .
              </p>
              <br />
              <hr />
              <p class="blockquote-footer" style="direction: ltr">
                Feel free to rate the blog and send me feedback,questions or
                suggestions through the
                <span style="color: red; font-weight: bold">comment</span>
                section below. <br />To be updated for Upcoming content, please
                <span style="color: red; font-weight: bold">follow</span> this
                website.
              </p>
            </div>
            <!--#endregion -->
          </article>
          <!--#endregion-->
        </div>
        <br />
        <!--#endregion-->
        <!--#region right hand-->
        <div class="col-lg-3 m-15px-tb blog-aside">
          
          <app-sidebar></app-sidebar>
         
        </div>
        <!--#endregion-->
      </div>
      <div class="row">
        <div class="col">
          <app-comment></app-comment>
          <br /><br />
        </div>
      </div>
    </div>

    <!--#endregion-->
  </article>
  <div id="mybutton">
    <a class="btn feedback text-m3awaj" href="/" role="button"> Home >></a>
  </div>
  <!--
  <div id="knowledge-checks">
    <div class="floating-avatar">
      <img class="check-img" src="assets/icons/trophy.png" />
    </div>
  </div>-->
</main>
