<main>
    <article>
    <!--#region content-->
      <div class="container-fluid" >
        <div class="row align-items-start">
          <div class="col article ">
            <div class="article-img align-items-center">
              <img src="assets/image/api/apicover.jpg" title="" alt="" />
            </div>
          </div>
        </div>
        <div class="row align-items-start">
          <!--#region Left Hand-->
          <div class="col-lg-3 m-15px-tb blog-aside sticky" >
            <div class="">

              <!--#region video -->
  <div class="widget widget-latest-post ">
    <div class="widget-body">
      <div class="embed-responsive embed-responsive-21by9">
        <h5>Video loading...</h5>
        <youtube-player
          videoId="egQfAl8FFD4"
          suggestedQuality="highres"
          style="width: 100%"
          id="youtube"
        >
        </youtube-player>
      </div>
    </div>
  </div>
  <!--#endregion -->
 
              <!--#region Rate -->
  <div class="widget widget-latest-post">
    <div class="widget-title">
      <div class="row">
        <div class="col-6">
          <h3 style="color: #0396A6;" >Rate</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <p style="font-size: 12px">
            (Current {{ currentRate }} <i class="fa fa-star"></i>)
          </p>
        </div>
      </div>
    </div>
    <div class="widget-body">
      <div class="">
        <div class="rating">
          <!--#region stars-->
          <input
            type="radio"
            name="rating"
            id="rating-5"
            (click)="onRate(5)"
          />
          <label for="rating-5"></label>
          <input
            type="radio"
            name="rating"
            id="rating-4"
            (click)="onRate(4)"
          />
          <label for="rating-4"></label>
          <input
            type="radio"
            name="rating"
            id="rating-3"
            (click)="onRate(3)"
          />
          <label for="rating-3"></label>
          <input
            type="radio"
            name="rating"
            id="rating-2"
            (click)="onRate(2)"
          />
          <label for="rating-2"></label>
          <input
            type="radio"
            name="rating"
            id="rating-1"
            (click)="onRate(1)"
          />
          <label for="rating-1"></label>
          <!--#endregion-->

          <!--#region Emojis-->
          <div class="emoji-wrapper">
            <div class="emoji">
              <!--#region none -->
              <img src="assets/icons/0.png" />
              <!--#endregion-->

              <!--#region 1s -->
              <img src="assets/icons/00.png" />
              <!--#endregion-->

              <!--#region 2s -->
              <img src="assets/icons/2.png" />

              <!--#endregion-->

              <!--#region 3s -->
              <img src="assets/icons/3.png" />
              <!--#endregion-->

              <!--#region 4s -->
              <img src="assets/icons/4.png" />
              <!--#endregion-->

              <!--#region 5s -->
              <img src="assets/icons/5.png" />
              <!--#endregion-->
            </div>
          </div>

          <!--#endregion-->
        </div>

        <button
          type="button"
          class="btn input btn-outline-dark btn-rounded button-wrapper"
          (click)="onSetRate()"
        >
          Submit
        </button>
        <br />
        <div
          class="alert alert-warning"
          *ngIf="!firstRate == false"
          role="alert"
        >
          Be the first to rate this Content!
        </div>
        <div
          class="alert alert-success alert-dismissible fade show"
          role="alert"
          *ngIf="!success == false"
        >
          <strong>Success!</strong> Thank you for rating us!
        </div>
      </div>
    </div>
  </div>
  <!--#endregion -->

              <!--#region knowledge check -->

  <div
    class="
      alert alert-dismissible
      fade
      show
      widget widget-author
      timeline-card
          "
    role="alert"
  >
    <div
      class="media align-items-center"
      (click)="OpenPopUp(check, 'lg')"
    >
      <a role="button" href="javascript:void(0)">
        <h3
          
          style="font-size: 20px; font-weight: 700; margin: 0;color: #0396A6;"
        >
          Check your Knowledge!
        </h3>
      </a>

      <img src="assets/icons/trophy.png" class="follow" />
    </div>

    <button
      type="button"
      class="close"
      data-dismiss="alert"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!--#region follow pop up-->
  <!-- #region pop up screen for solution-->

  <ng-template #check let-modal>
    <div class="modal-header">
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="fa fa-close"></i>
      </button>
    </div>

    <div class="modal-body">
      <div class="row h-100">
        <div class="col-12">
          <div class="align-items-center">
            <img src="assets/image/question.jpg" />
          </div>
          <div
            class="about-section bg-white rounded shadow-sm py-5 px-4"
          >
            <hr />
            <form class="form-group">
              <!--#region Q1-->
              <div class="row">
                <div class="col">
                  <p>1. <u>It's always said that</u></p>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="q1"
                      id="q1r1"
                      value="All Web services are APIs but all APIs are not web services."
                      [(ngModel)]="qestions.q1"
                    />
                    <label class="form-check-label" for="q1r1">
                      All Web services are APIs but all APIs are not web services.
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="q1"
                      id="q1r2"
                      value="All APIs are Web Services but all Web Services are not APIs."
                      checked
                      [(ngModel)]="qestions.q1"
                    />
                    <label class="form-check-label" for="q1r2">
                      All APIs are Web Services but all Web Services are not APIs. 
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="q1"
                      id="q1r3"
                      checked
                      value="APIs and Web Sservices are the same thing."
                      [(ngModel)]="qestions.q1"
                    />
                    <label class="form-check-label" for="q1r3">
                      APIs and Web Sservices are the same thing.
                    </label>
                  </div>
                </div>
              </div>
              <!--#endregion-->
              <hr />
              <!--#region Q2-->
              <div class="row">
                <div class="col">
                  <p>2. <u>Parteners API is:</u></p>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="q2"
                      id="q2r1"
                      value="An API accessible by everyone."
                      [(ngModel)]="qestions.q2"
                    />
                    <label class="form-check-label" for="q2r1">
                      An API accessible by everyone.
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="q2"
                      id="q2r2"
                      value="Only visible to those who work within the same company."
                      checked
                      [(ngModel)]="qestions.q2"
                    />
                    <label class="form-check-label" for="q2r2">
                      Only visible to those who work within the same company.
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="q2"
                      id="q2r3"
                      checked
                      value="Only accessible via authentication system."
                      [(ngModel)]="qestions.q2"
                    />
                    <label class="form-check-label" for="q2r3">
                      Only accessible via authentication system.
                    </label>
                  </div>
                </div>
              </div>
              <!--#endregion-->
              <hr />
              <!--#region Q3-->
              <div class="row">
                <div class="col">
                  <p>
                   3. <u>REST is officially a :</u>
                  </p>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="q3"
                      id="q3r1"
                      value="Protocol."
                      [(ngModel)]="qestions.q3"
                    />
                    <label class="form-check-label" for="q3r1">
                      Protocol.
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="32"
                      id="q3r2"
                      value="Architectural Principle."
                      checked
                      [(ngModel)]="qestions.q3"
                    />
                    <label class="form-check-label" for="q3r2">
                      Architectural Principle.

                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="q3"
                      id="q3r3"
                      checked
                      value="API."
                      [(ngModel)]="qestions.q3"
                    />
                    <label class="form-check-label" for="q3r3">
                      API.
                    </label>
                  </div>
                </div>
              </div>
              <!--#endregion-->
            </form>
            <ng-template #check let-modal>
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  (click)="modal.dismiss('Cross click')"
                >
                  <i class="fa fa-close"></i>
                </button>
              </div>

              <div class="modal-body">
                <div class="row h-100">
                  <div class="col-12">
                    <div
                      class="
                        about-section
                        bg-white
                        rounded
                        shadow-sm
                        py-5
                        px-4
                      "
                    >
                      <div class="alert alert-{{ alert }}" role="alert">
                        {{ replay }}
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col">
                          <table class="table">
                            <thead class="thead">
                              <tr scope=" table-success">
                                <th scope="col ">Corract Answers</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                class="table-success"
                                *ngFor="let row of correct"
                              >
                                <td class="table-success">
                                  {{ row }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col">
                          <table class="table">
                            <thead class="thead-danger">
                              <tr scope=" table-danger">
                                <th scope="col table-danger">
                                  Wrong Answers
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                class="table-danger"
                                *ngFor="let wrong of wrong"
                              >
                                <td class="table-danger">
                                  {{ wrong }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <div class="col">
              <button
                type="button"
                class="
                  btn btn-outline-dark btn-rounded
                  button-wrapper
                  input
                "
                (click)="OpenPopUp(check, 'lg')"
                (click)="onCheck()"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- #endregion -->

  <!--#endregion-->
  <!--#endregion -->
        </div>
          </div>
          <!--#endregion-->
          <!--#region Middle hand-->
          <div class="col-lg-6 m-15px-tb">
            <!--#region article-->
            <article class="article">
              <div class="article-title">
                <div class="row">
                  <div class="col-6 col-md-9">
                    <h2>Application Programming Interface (API)</h2>
                  </div>
                  <div class="col-6 col-md-3">
                    <span (click)="onChange()"
                      ><input
                        type="checkbox"
                        checked
                        data-on="Tunisian"
                        data-off="English"
                        data-toggle="toggle"
                        data-onstyle="info"
                        data-offstyle="secondary"
                    /></span>
                  </div>
                </div>
              </div>
              <hr />
              <!--#region english article-->
              <div
                class="article-content"
                style="font-family: 'Segoe UI'"
                *ngIf="english == true"
              >
               <p>

                When we want to extract money from the bank, we don't directly get it, we do it either through the distributor or a bank teller.<br>
                The same thing goes with a library, store, Administration, etc.<br>
                Because what we are dealing with is either <span style="font-weight: bold">private</span>, <span style="font-weight: bold">valuable</span>, or <span style="font-weight: bold">sensitive</span> data, that needs protection.<br>
                And if we see it from another perspective, it's the same thing when we use a website, software, or a game...<br><br>
                So here, our client app which is, for example, the website interface we using won't directly get information from the database instead the API will take care of all the logic behind it.
               </p><br>
                <img class="centralized-image" src="assets/image/api/1.PNG" />
                <br />
                <h4>What Is An API?</h4>
                <p>
                  API stands for <span style="font-weight: bold; color: red;">Application Programming Interface</span>.<br>
                  We can call it the <span style="font-weight: bold;">brain</span> of our application, it's like a middleware responsible for creating a secured connection between a user and the database.<br>
                  And with each application we use, like youtube, Linked In, or Twitter we are actually using APIs.
                </p>
                <h4>How does it work?</h4>
                <p>
                  In simple terms, every time the user interacts with the graphic interface a request will be sent depending on the action he took, let's understand it with a simple example.<br><br>
                  when we add a comment in any kind of application a <span style="font-weight: bold; color: red;">POST Request</span>  will be sent to our API, it's like telling it "<span style="font-weight: bold">hey could you access the database and add this comment there?</span>" and like that the API will contact the database so it will add all information about your comment in it.<br><br>
                  And when we for example want to read some comments, we will be sending a <span style="font-weight: bold; color: red;">GET Request</span> to the database, it's like telling it "<span style="font-weight: bold;">could you bring me the comments from the database and send them back to me so that I can read them? </span>" and here the API will do the job then it will send you a <span style="font-weight: bold; color: red;">Response</span> containing all information you needed.<br><br>

                  A lot of other types of requests can be sent to communicate with the API and the other two famous are the <span style="font-weight: bold; color: red;">PUT</span> (for updating a value) and <span style="font-weight: bold; color: red;">DELETE</span> request.<br>
                  And the communication between the user and the API will be all about requesting data and responding to this later back.
                </p><br>
                <img class="centralized-image" src="assets/image/api/2.PNG" />
                <br>
                <h4>Types of APIs</h4>  
                <p>
                  Basically, there is four types:
                </p>
                  <h6>Open API:</h6>
                <p>  
                  (note: the one that all of us or most of us will start working with.)<br>
                  Referred to as a public API, since it's public and available for everyone and you can have access to it with the HTTP protocol. <br>
                  For example, the APIs youtube is using, since we can open it and get videos from it with no conditions, it's public.
                </p>
                  <h6>Partners API:</h6>
                <p>
                  It's more private than the Open API since here it will only be exposed to strategic business partners.<br>
                  which means, for example, you can have access to it throw a public portal but a secured authentication system is required, so you will need login credentials to have access to it.
                </p>  
                  <h6>Internal API:</h6>
                  <p>
                    These are more private than Partners APIs, it's only available for those who, for example, work in the same company and only those can access it and it won't be nor visible nor accessible for external parties or users.
                  </p>
                  <h6>Composite API:</h6>
                  <p>
                  Mostly used with microservices architecture, Since this type combines multiples APIs.<br> 
                  When getting one piece of information may lead to the interaction of multiple APIs between each other.
                  </p>
                <h4>What is the difference between a Web Service and an API?</h4>
                <p>
                  We may encounter these two words a lot thinking they are the same but actually they are not.<br>
                  As they always say: &nbsp;
                  "All web services are APIs but all APIs are not web services."<br><br>
                  In fact, a <span style="font-weight: bold;">Web Service</span> is a collection of protocols used in order to exchange information between programs, it only supports the <u>HTTP protocol</u> and the type of data that would be exchanged with these protocols will only be in <u>XML</u> files.<br><br>
                  In simple terms, It's based on the exchange of information between machines or programs over a network (thus it require the internet) instead of having an intermediate system that will take care of the communication and exchange of information like the case of the API here we will be having a <u>WSDL file</u> and this later is just an XML file that contains all functionalities our web service has or can do.
                </p><br>
                <img class="centralized-image" src="assets/image/api/3.PNG" />
                <br>
                <h4>REST API vs SOAP</h4>
                <p>
                  They are two different way that will help us transfer data.<br>
                  Let's clear up the first difference, <b>SOAP</b> is officially a <u>protocol</u> while <b>REST</b> is an <u>architectural principle</u>.<br><br>
                  Furthermore, SOAP is a communication protocol via the Internet its supports HTTP protocol, and data will be exchanged throw XML files, and looking back to some common opinions it's mostly used by <u>Web services</u> since they are close and it's used in variety of messaging systems.<br>
                  Concerning REST APIs they can be developed using any programming language, and support many data formats not just XML also they support both HTTP & HTTPS.
                </p><br>
                <img class="centralized-image" src="assets/image/api/sr.jpg" />
                <br>
                <h4>REST APIs Design Principle</h4>
                <a href="/letslearn/designprinciples&patterns" style="color: red;"><u>Learn more about Design Principles.</u></a>
                <br><br>
                <h6>Unifor Interface</h6>
                <p>
                  In simple terms, it means that if many endpoints sent the same request with the same URI they will get the same data.
                </p>
                <h6>Client-Server Decoupling</h6>
                <p>
                  The client and the server should never directly interact with each other, they only exchange information throw URIs which pass throw the API first.
                </p>
                <h6>Statelessness</h6>
                <p>unlike sessions, restful APIs aren't allowed to store any additional data related to the client in the application server</p>
                <h6>Cacheability</h6>  
                <p>The resource can be cacheable when needed to improve the performance.<br>
                  <a href="/letslearn/cache" style="color: red;"><u>Learn more about Sessions and Caching.</u></a></p>
                
                <h6>Layared System Architecture</h6>
                <p>Communication between client and server should happen to throw multiple layers (middleware).</p>
                
                <br />
                <hr />
                <p class="blockquote-footer">
                  Feel free to rate the blog and send me feedback,questions or
                  suggestions through the
                  <span style="color: red; font-weight: bold">comment</span>
                  section below. <br />To be updated for Upcoming content, please
                  <span style="color: red; font-weight: bold">follow</span> this
                  website.
                </p>
              </div>
              <!--#endregion -->
              <!--#region tunisian article-->
              <div
                *ngIf="tunisian == true"
                class="article-content"
                style="direction: rtl; display: inline-block; text-align: right"
              >
              <p>
                وقة الي نحبوا نخرجوا فلوس مالBank، ما نجموش نخروجهم directement،  نخرجوهم مال distributeur.
                كيف كيف كي نمشيوا لمكتبة، magazin, ولا إدارة...<br>
                هاذا كلو على خاطر المعلومات الي نتعاملوا معاهه يا <span style="font-weight: bold">de valeur</span>، يا <span style="font-weight: bold">privé</span>, يا <span style="font-weight: bold">sensible</span>.<br>
                و كي نجيوا نشوفوها من ناحية اخرى، نفس الحاجة قاعد يصير كيف نحلوا site, logiciel, ولا game مثال.<br>
                و هوني مثال الsite الي باش نستعملوه و الinterface graphique الي تصير فيها الinterraction موش باش تاخو معلومات directement مال data base اما هوني ال API هو الي باش بقوم بالدور و بالlogique الي قاعد يصير behind the scene.
  
                <img class="centralized-image" src="assets/image/api/1.PNG" />
                <br />
                <h4>شنوا هو الAPI ؟</h4>
                <p>
                  API يعني <span style="font-weight: bold; color: red;">Application Programming Interface</span>.<BR>
وهي عبارة على intermediate  باش يصنعلنا ثنية ولا طريق safe مابين المستعمل و الdata متاعو.<BR>
و كي نجيو نشوفو كل application نستخدموها مثال كيف youtube, Linked In, ولا Twitter في الحقيقة يستعملوا في APIs.

                </p>
                <h4> ؟كيفاش يخدم</h4>
                <p>
                  كل ما الuser يعمل action, تتصنع request  و تتبعث.  
                  هاة نبصطوها شوية في example صغير.<br>
                  وقة مثال نزيدوا commentaire في اي application 
                  فمل <span style="font-weight: bold; color: red;">POST Request</span> تتبعث لل API كيينو قلوا " <span style="font-weight: bold">تعمل عليا مزية ميسالش تمشي للdata base و تزيد الcommentaire متاعي غادي؟</span>" وقتها الAPI يحكي مع الdata base  و يقوم بالواجب.<br>
                  نفس الشيىء كان هالمرة مثال حبينا نقراو des commentaires، هوني نوليو نبعثوا <span style="font-weight: bold; color: red;">GET Request</span>>  للAPI وهوني بالي قوم بنفس ال عملية يحكي مع الdata base و يبعثلنا response فيها الcommentaires الي احنا طلبناهم.<br>
                  فما برشى انواع requests اخرين و زوز منهم زادا ياسر مستعملين كيف الPOST و الGET الي حكيناعليهم و هوما ال  <span style="font-weight: bold; color: red;">PUT</span>  ( كان نحبو نعملو تغيير ل معلممة deja مسجلة في الdata base) و ال<span style="font-weight: bold; color: red;">DELETE</span>  Request.<br>
                  وهكا ياجماعة تكون الcommunication ما بين الuser و الapplication ناهي الا exchange متاع requests و responses.
                 </p><br>
                <img class="centralized-image" src="assets/image/api/2.PNG" />
                <br>
                <h4>انواع الAPIs</h4>  
                <p>
هو كيف نجيوا نشفوا فما 4 انواع:
                </p>
                  <h6>Open API:</h6>
                <p> 
                   لولاني هو Open API ويتسمى زادا Public API على خاترو محلول لناس لكل وتنجم تخدم بيه وتستعملوا بProtocol HTTP
                  (على فكرة، هاذا اول واكثر API بش تخدم بيه )<br>
                  باهي على سبيل مثال، Youtube يستعمل فيه، ماداما نجموا نحلوا Videos  مغير حتى شروط، donc يتسمى public.
 
                  </p>
                  <h6>Partners API:</h6>
                <p>
                  
ثاني نجموا نلقاو Partner API، وهاذا تنجم تقول مسكر شويا كي نقرنوه مع Open API على خاتروا بش يولي يتبع استراتيجية شركاء عمل كهو، معناها هي نفسها محلولة لناس لكل اما ما تنجم تدخل للمعلمات لحشتك بيهم كان  Authentication System لهوني لزمك تحط بياناتك لتنجم تخليك تدخل وتوصل للهدف متعك.

                  </p>  
                  <h6>Internal API:</h6>
                  <p>

                    عانا زادا Internal API وكالعادة هاذا مسكر اكثر ملي قبلو وموجود كان لناس معينين، وهوني كمثال جماعة يخدموا في نفس الشركة باش يكونوا كان هوما الي ينجموا يدخلوا و يشوفوا الموقع من اصلو .
                  </p>
                  <h6>Composite API:</h6>
                  <p>
                 
وفي لخر عنا Composite API وهاذا مستعمل اكثر في كل ماهو Microservices Architecture على ختروا يربط برشا APIs لي بش تخاليك كيف انت تحب توصل لمعلومة، ينجم يقلك واحد من APIs انا بش نترأس لحكية وانا لنظم أمور لكل وكيفاه تحكيوا بينتكم بش توصل معلومة صحيحة. 

                  </p>
                <h4>شنوا الفرق مابين Web Service و API ؟</h4>
                <p>
                  هو بنتنا كلمتين هاذم يحكيوا برشا حاجات وكي تجي تشوف هما كيف كيف اما موش بالظبط والزوز زادا يقولو في نفس لحكاية وهي 
"كل ماهو <span style="font-weight: bold;">Web Service</span> راهو API اما راو موش كل API معناها Web service "<br><br>

وبناتنا، ريت Web Service هو مجموعة متاع Protocols تصنعوا بش تنجم تشارك معلومات في وصط Application، وتنجم تستعمل كان HTTP Protocol  بش توصل اذا كان تحب تشركها بما يسمى XML Files.<br> 

وصورة اوضح، نستعملوها كيف نحبو نبعثو معلومات متعنا مابين Programs في network هاكا علاه نستحقوا فيه لل Internet وهاذا يكون بفضل WSDL File، وكلمة جديدة هاذي هي just an XML File فيه كل ماهو Functionalities  متع Webservices  لينجم يعملهم 

                  </p><br>
                <img class="centralized-image" src="assets/image/api/3.PNG" />
                <br>
                <h4>REST API vs SOAP</h4>
                <p>
                  توا نحب نفهم فرق مبين REST API والSOAP
هوما زوز طرق معروفين بش تنجم تبعث معلومات
باهي يا سيدي خنفهموا اكثر شنوى فرق،<br> لحاسيلو عانا SOAP هو Protocol اما الRest هو عبارة على Architecture Principle 
ونزيدك اكثر،<br> SOAP هي Communication Protocol  و يخدم بال Internet  ويستعمل HTTP Protocol  في خدمة متعها، ومعلومات تتبعث باستعمال XML Files

وهوني كيف نجيوا نشوفو لي معظم  Web Services يمشيو بال SOAP خاتر يتماشاو مع بعضهم بحكم من قبل موجودة في برشا Messaging Systems 

وكيف نرجعوا لREST API نلقاوها تنجم تستعملها بأي Programming Language وقدرة بش تخدم برشا انواع متاع Data موش كان XML يعني

                  </p><br>
                <img class="centralized-image" src="assets/image/api/sr.jpg" />
                <br>
                <h4>REST APIs Design Principle</h4>
                <p>باهي يا سيدي نحبش نخبي عليكم كل ماهو REST API Design Principle 

            
                  
                  
                 
                  
                  </p>
                <a href="/letslearn/designprinciples&patterns" style="color: red;"><u>Learn more about Design Principles.</u></a>
                <br><br>
                <h6>Unifor Interface</h6>
                <p>
                  ونبداو بUniform Interface وهي تقوم على انوديما وقة برشا End Points تبعث نفس Request بنفس URI بش يخذو نفس معلومة
                </p>
                <h6>Client-Server Decoupling</h6>
                <p>
                  وعانا زادة  Client-Server Decoupling  وهوني نلقاو Client والServer لزمهمش يحكيو مع بعضهم Directly، ديما بش تلقاهم  يتشركو معلومات من خلال URI لهو بش يبعثها  لAPI 
                  
                </p>
                <h6>Statelessness</h6>
                <p> ولزمناش ننساو Statelessness 
                  ولي هو عكس Session خترو ما يخاليش API تسجل حتى معلومة lju Client  في Server Side
                  \لاق|<a href="/letslearn/cache" style="color: red;"><u>Learn more about Local Storage.</u></a>
                </p>
                <h6>Cacheability</h6>  
                <p>وكيف كيف عانا Cacheability  وهذي تخليك تخبي شوية معلومات في الlocal storage متاعك بش تنجم تقوي Performance 
                  <br>
                  <a href="/letslearn/cache" style="color: red;"><u>Learn more about Sessions and Caching.</u></a></p>
                
                <h6>Layared System Architecture</h6>
                <p>                  ونكملو حلقتنا بLayred System Architecture  وهي تقوم على Communication مابين Client والServer ولزمها تصير عن طريق برشة طبقات (Middleware ).
                </p>
                
            <br />
            <hr />
            <p class="blockquote-footer">
              Feel free to rate the blog and send me feedback,questions or
              suggestions through the
              <span style="color: red; font-weight: bold">comment</span>
              section below. <br />To be updated for Upcoming content, please
              <span style="color: red; font-weight: bold">follow</span> this
              website.
            </p>
              </div>
              <!--#endregion -->
            </article>
            <!--#endregion-->
          </div>
          <br />
          <!--#endregion-->
          <!--#region right hand-->
          <div class="col-lg-3 m-15px-tb blog-aside "  >
        
        <div class="sticky-sidebar" >
              <app-sidebar ></app-sidebar>
        <div></div>
            </div> 
           </div>
          <!--#endregion-->
        </div>
        <div class="row">
          <div class="col">
           
            <app-comment></app-comment>
         
           <br /><br />
          </div>
        </div>
      </div>
  
      <!--#endregion-->
    </article>
    <div id="mybutton">
      <a class="btn feedback text-m3awaj" href="/" role="button"> Home >></a>
    </div>
   
  </main>
