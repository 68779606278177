<div class="blog-aside ">
  <!--#region New -->
  <div class="widget widget-latest-post">
    <div class="widget-title">
      <h3 style="color: #0396A6;">What's New</h3>
    </div>
    <div class="widget-body">
      <div class="latest-post-aside media">
        <div class="lpa-left media-body">
          <div class="lpa-title">
            <h5>
              <a href="/letslearn/datainterchangeformats">Data Interchange Formats</a>
            </h5>
          </div>
          <p style="color: red; font-size: small">New!</p>
          <div class="lpa-meta">
            <a class="date"> 22 Apr 2022 </a>
          </div>
        </div>
        <div class="lpa-right">
          <a href="letslearn/datainterchangeformats">
            <img src="assets/image/datainterchangeformats/DataEnterchange.png" title="" alt="" />
          </a>
        </div>
      </div>
    </div>
    
    <hr />

    <div class="widget-body">
      <div class="latest-post-aside media">
        <div class="lpa-left media-body">
          <div class="lpa-title">
            <h5>
              <a href="/letslearn/microservices">Microservices</a>
            </h5>
          </div>
          <div class="lpa-meta">
            <a class="date"> 8 Oct 2021 </a>
          </div>
        </div>
        <div class="lpa-right">
          <a href="letslearn/microservices">
            <img src="assets/image/Microservice/MA.jpg" title="" alt="" />
          </a>
        </div>
      </div>
    </div>
    <hr/>
    <div class="widget-body">
      <div class="latest-post-aside media">
        <div class="lpa-left media-body">
          <div class="lpa-title">
            <h5>
              <a href="/letslearn/api"
                >APIs</a
              >
            </h5>
          </div>
          
          <div class="lpa-meta">
            <a class="date"> 20 SEP 2021 </a>
          </div>
        </div>
        <div class="lpa-right">
          <a href="letslearn/api">
            <img src="assets/image/api/apicover.jpg" title="" alt="" />
          </a>
        </div>
      </div>
    </div>
     
   
   
  
    
  </div>
  <!--#endregion -->

  <!--#region Author -->
  <div class="widget widget-author">
    <div class="widget-title">
      <h3 style="color: #0396A6;">Author</h3>
    </div>
    <div class="widget-body">
      <div class="media align-items-center">
        <div class="avatar">
          <img src="assets/image/image.jpg" title="" alt="" />
        </div>
        <div class="media-body">
          <h6>
            Mariem Moalla
            <br />
            <a
              class="muted"
              href="/letsmeet"
              style="font-size: 20px; color: mediumturquoise"
            >
              Visit >></a
            >
          </h6>
        </div>
      </div>
    </div>
  </div>
  <!--#endregion -->

  <!--#region follow -->

  <div
    class="
      alert alert-dismissible
      fade
      show
      widget widget-author
      timeline-card
    "
    role="alert"
  >
    <div
      class="media align-items-center"
      (click)="OpenPopUp(follow, 'lg')"
    >
      <a href="javascript:void(0)">
        <h3
          
          style="font-size: 20px; font-weight: 700; margin: 0;color: #0396A6;"
        >
          Follow for upcoming updates!
        </h3>
      </a>
      <img src="assets/icons/follow.png" class="follow" />
    </div>

    <button
      type="button"
      class="close"
      data-dismiss="alert"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!--#region follow pop up-->
  <!-- #region pop up screen for solution-->

  <ng-template #follow let-modal>
    <div class="modal-header">
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="fa fa-close"></i>
      </button>
    </div>

    <div class="modal-body">
      <div class="row h-100">
        <div class="col-12">
          <div
            class="about-section bg-white rounded shadow-sm py-5 px-4"
          >
          <section class="get-in-touch">
            <h1 class="title">Follow Us</h1>
            <p class="text-muted text fs-6">
              To recieve reminders about the last updates and new
              content fill this form and become a precious Follower!
            </p>
            <!--#region Notices-->
            <div
              class="alert alert-primary alert-dismissible fade show"
              role="alert"
              *ngIf="!checkingfollow == false"
            >
              Please wait while checking your email...
            </div>
            <div
              class="alert alert-success alert-dismissible fade show"
              role="alert"
              *ngIf="!followerCreated == false"
            >
              <strong>Success!</strong> Thank you for following way to
              learn.
            </div>
            <div
              class="alert alert-danger alert-dismissible fade show"
              role="alert"
              *ngIf="!followerExist == false"
            >
              <strong>hmmm... You are already a follower!</strong>
            </div>
            <!--#endregion-->
            
            <form class="contact-form row">
               <div class="form-field col-lg-6">
                <label class="label" for="fn">First Name</label>
                
                <input
                  id="fn"
                  class=" input-text js-input"
                  type="text"
                  name="firstName"
                  required
                  [(ngModel)]="follower.firstName"
                />
               </div>
               <div class="form-field col-lg-6 ">
                <label  class="label" for="ln">Last Name</label>
                
                <input
                  id="ln"
                  class="input-text js-input"
                  type="text"
                  name="lastName"
                  required
                  [(ngModel)]="follower.lastName"
                />
               </div>

               <div class="form-field col-lg-12">
                <label class="label" for="email">Email</label>
                <input
                  type="email"
                  id="email"
                  class="input-text js-input"
                  name="email"
                  [(ngModel)]="follower.email"
                  required
                />
               </div>
               <div class="form-field col-lg-12" >
                  <input class="submit-btn" type="submit" value="Submit" (click)="onFollow()"> 
               </div>
            </form>
         </section>
           
        </div>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- #endregion -->

  <!--#endregion-->
  <!--#endregion -->

</div>