 <!--#region comments section-->
 <div class="contact-form article-comment">
    <h4>Comments</h4>
    <p class="text-muted text fs-6">
      Do you have any question, review or suggestion, leave a comment
      and a response will be sent to you very soon.
    </p>
    <div
      class="alert alert-primary alert-dismissible fade show"
      role="alert"
      *ngIf="!commentwait == false"
    >
      We are sending your message...
    </div>
    <div
      class="alert alert-success alert-dismissible fade show"
      role="alert"
      *ngIf="!commentsent == false"
    >
      <strong>Success!</strong> Thank you for Leaving a comment, a
      response will be sent to you soon.
    </div>
    <div
      class="alert alert-danger alert-dismissible fade show"
      role="alert"
      *ngIf="!tryagain == false"
    >
      <strong>hmmm... There is some problem, try again!</strong>
    </div>

    <br />

    <form id="contact-form" method="POST">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <select
              class="form-control input"
              id="exampleFormControlSelect1"
              name="subject"
              [(ngModel)]="comment.subject"
            >
              <option selected disabled>Subject</option>
              <option>Question</option>
              <option>Suggestion</option>
              <option>Review</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <input
              name="Email"
              id="email"
              placeholder="Email "
              class="form-control input"
              type="email"
              [(ngModel)]="comment.email"
            />
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <textarea
              name="questions"
              id="message"
              placeholder="Content"
              rows="4"
              class="form-control input"
              [(ngModel)]="comment.questions"
            ></textarea>
          </div>
        </div>
        <div class="col-md-12">
          <div class="send">
            <button
              type="button "
              class="
                btn btn-outline-dark
                input
                btn-rounded
                button-wrapper
              "
              (click)="onComment()"
            >
              Submit <i class="arrow"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!--#endregion-->
  
