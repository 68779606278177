import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.css']
})
export class SocialMediaComponent implements OnInit {
  //language
  public english: boolean = true;
  public tunisian: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }
  onChange() {
    if (this.english == true) {
      this.english = false;
      this.tunisian = true;
  
    } else {
      this.english = true;
      this.tunisian = false;
    }
    console.log("eng:" + this.english + "tun:" + this.tunisian);
  }
}
