import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// MDB Angular Free
import { WavesModule, ButtonsModule, IconsModule } from 'angular-bootstrap-md'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PortfoliolayoutComponent } from './layouts/portfoliolayout/portfoliolayout.component';
import { BloglayoutComponent } from './layouts/bloglayout/bloglayout.component';
import { HomelayoutComponent } from './layouts/homelayout/homelayout.component';
import { HomeComponent } from './components/home/home.component';
import { SolidComponent } from './components/solid/solid.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './Components/FixedComponents/footer/footer.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { JwtComponent } from './components/jwt/jwt.component';
import { CacheComponent } from './components/cache/cache.component';
import { SidebarComponent } from './components/fixedcomponents/sidebar/sidebar.component';
import { CommentComponent } from './components/fixedcomponents/comment/comment.component';
import { ApiComponent } from './components/api/api.component';
import { MicroservicesComponent } from './components/microservices/microservices.component';
import { CloudComponent } from './components/cloud/cloud.component';
import { ValueReferenceTypesComponent } from './components/value-reference-types/value-reference-types.component';
import { SocialMediaComponent } from './components/social-media/social-media.component';
import { AuthlayoutComponent } from './layouts/authlayout/authlayout.component';
import { AdminlayoutComponent } from './layouts/adminlayout/adminlayout.component';
import { DatainterchangeformatsComponent } from './components/datainterchangeformats/datainterchangeformats.component'
@NgModule({
  declarations: [
    AppComponent,
    PortfoliolayoutComponent,
    BloglayoutComponent,
    HomelayoutComponent,
    FooterComponent,
    HomeComponent,
    SolidComponent,
    JwtComponent,
    CacheComponent,
    SidebarComponent,
    CommentComponent,
    ApiComponent,
    MicroservicesComponent,
    CloudComponent,
    ValueReferenceTypesComponent,
    SocialMediaComponent,
    AuthlayoutComponent,
    AdminlayoutComponent,
    DatainterchangeformatsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    YouTubePlayerModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    CommonModule,
    WavesModule,
    ButtonsModule,
    IconsModule,
    ReactiveFormsModule,
    ClipboardModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
