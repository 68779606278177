import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Question } from 'src/app/Models/Question';
import { Rate } from 'src/app/Models/Rate';
import { BlogService } from 'src/app/Services/blog.service';
import { OneforwholeService } from 'src/app/Services/oneforwhole.service';

@Component({
  selector: 'app-datainterchangeformats',
  templateUrl: './datainterchangeformats.component.html',
  styleUrls: ['./datainterchangeformats.component.css','../../app.component.css']
})
export class DatainterchangeformatsComponent implements OnInit {

 //rate
 public currentRate: number;
 public newRate: Rate = new Rate(0);
 public success: boolean = false;
 public firstRate: boolean = false;
 //popup
 public closeResult = '';
 public isCreated: Boolean = false;
 //anguage
 public english: boolean = true;
 public tunisian: boolean = false;
 //quiz
 public qestions = new Question("No answer was chosen...", "No answer was chosen...", "No answer was chosen...");
 public score = 0;
 public replay: string;
 public correct: Array<string> = [];
 public wrong: Array<string> = [];
 public alert: string;
 constructor(private blogservice: BlogService, private modalService: NgbModal, private oneforwhole: OneforwholeService) { }

 ngOnInit(): void {

   this.start();
   this.incriment();
 }
 start() {
   //
   this.blogservice.getCurrentRate("a2c9b429-4e1e-4629-31d8-08da24628a80").subscribe((data: any) => {
     this.currentRate = data;
     if (this.currentRate == 0) this.firstRate = true;
     console.log(this.currentRate);
   })
 }
 onRate(i: number) {

   this.newRate.grade = i;
   console.log(this.newRate.grade);
 }
 delay(ms: number) {
   return new Promise(resolve => setTimeout(resolve, ms));
 }
 onSetRate() {
   console.log(this.newRate.grade);
   if (this.newRate.grade != 0) {
     this.blogservice.setRate("dee21952-ae1b-48d5-ad10-08d965a020d8", this.newRate).subscribe(async data => {
       this.success = true;
       await this.delay(3000);
       this.success = false;
       console.log(data);
       this.ngOnInit();
     });
   } else console.log("got 0 rate")

 }
 OpenPopUp(content, size) {
   // { windowClass: "myCustomModalClass" }
   this.modalService.open(content, { size: size, ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
     this.closeResult = `Closed with: ${result}`;
   },
     (reason) => {
       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
     });
 }
 private getDismissReason(reason: any): string {
   this.correct = [];
   this.wrong = [];
   if (reason === ModalDismissReasons.ESC) {
     this.isCreated == false;
     return 'by pressing ESC';
   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
     this.isCreated == false;

     return 'by clicking on a backdrop';
   } else {
     return `with: ${reason}`;
   }
 }
 incriment() {
   //"
   this.oneforwhole.incriment("7be2796a-3947-4182-a691-08da24629d3c").subscribe(data => console.log(data));
 }
 onChange() {
   if (this.english == true) {
     this.english = false;
     this.tunisian = true;

   } else {
     this.english = true;
     this.tunisian = false;
   }
   console.log("eng:" + this.english + "tun:" + this.tunisian);
 }
 onCheck() {
   console.log(this.qestions);

   if (this.qestions.q1 == "False") {
     this.score++;
     this.correct.push("False");
   }
   else {
     this.wrong.push(this.qestions.q1);
   }
   if (this.qestions.q2 == "True") {
     this.score++;
     this.correct.push("True");
   }
   else {
     this.wrong.push(this.qestions.q2);
   }
   if (this.qestions.q3 == "True") {
     this.score++;
     this.correct.push("True");
   }
   else {
     this.wrong.push(this.qestions.q3);
   }
   console.log(this.score);
   if (this.score == 0) {
     this.replay = "Maybe you should try again...";
     this.alert = "danger";
   }
   if (this.score == 3) {
     this.replay = "Great Job you have answered all of the questions correctly!";
     this.alert = "success";
   }
   if (this.score != 0 && this.score != 3) {
     this.replay = "You did good!Check the incorrect answers and try again later.";
     this.alert = "warning";
   }
   this.delay(1000);

   this.score = 0;

 }
}

