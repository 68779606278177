import { Component, OnInit } from '@angular/core';
import { Comment } from 'src/app/Models/Comment';
import { BlogService } from 'src/app/Services/blog.service';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css']
})
export class CommentComponent implements OnInit {
//comment
public result: boolean;
public comment: Comment = new Comment("", "", "");
public commentsent = false;
public tryagain = false;
public commentwait = false;
  constructor(private blogservice: BlogService) { }

  ngOnInit(): void {
  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  onComment() {
    console.log(this.comment);
    this.commentwait = true;
    this.blogservice.comment(this.comment, "e33cfa9b-4c46-429f-ffd7-08d9717747d5").subscribe(async (data: any) => {
      this.commentwait = false;
      this.result = data;
      if (this.result == true) {
        this.commentsent = true;
        await this.delay(5000);
        this.commentsent = false;
  
      } else {
        this.tryagain = true;
  
        await this.delay(3000);
        this.tryagain = false;
  
      }
    });
  }
}
