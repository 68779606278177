<main>
    <article>
    <!--#region content-->
      <div class="container-fluid" >
        <div class="row align-items-start">
          <div class="col article ">
            <div class="article-img align-items-center">
              <img src="assets/image/cloud/clouding.png" title="" alt="" />
            </div>
          </div>
        </div>
        <div class="row align-items-start">
          <!--#region Left Hand-->
          <div class="col-lg-3 m-15px-tb blog-aside sticky" >
            <div class="">

              <!--#region video -->
  <div class="widget widget-latest-post ">
    <div class="widget-body">
      <div class="embed-responsive embed-responsive-21by9">
        <h5>Video loading...</h5>
        <youtube-player
          videoId="yXbu9H0dUDY"
          suggestedQuality="highres"
          style="width: 100%"
          id="youtube"
        >
        </youtube-player>
      </div>
    </div>
  </div>
  <!--#endregion -->
 
              <!--#region Rate -->
  <div class="widget widget-latest-post">
    <div class="widget-title">
      <div class="row">
        <div class="col-6">
          <h3 style="color: #0396A6;" >Rate</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <p style="font-size: 12px">
            (Current {{ currentRate }} <i class="fa fa-star"></i>)
          </p>
        </div>
      </div>
    </div>
    <div class="widget-body">
      <div class="">
        <div class="rating">
          <!--#region stars-->
          <input
            type="radio"
            name="rating"
            id="rating-5"
            (click)="onRate(5)"
          />
          <label for="rating-5"></label>
          <input
            type="radio"
            name="rating"
            id="rating-4"
            (click)="onRate(4)"
          />
          <label for="rating-4"></label>
          <input
            type="radio"
            name="rating"
            id="rating-3"
            (click)="onRate(3)"
          />
          <label for="rating-3"></label>
          <input
            type="radio"
            name="rating"
            id="rating-2"
            (click)="onRate(2)"
          />
          <label for="rating-2"></label>
          <input
            type="radio"
            name="rating"
            id="rating-1"
            (click)="onRate(1)"
          />
          <label for="rating-1"></label>
          <!--#endregion-->

          <!--#region Emojis-->
          <div class="emoji-wrapper">
            <div class="emoji">
              <!--#region none -->
              <img src="assets/icons/0.png" />
              <!--#endregion-->

              <!--#region 1s -->
              <img src="assets/icons/00.png" />
              <!--#endregion-->

              <!--#region 2s -->
              <img src="assets/icons/2.png" />

              <!--#endregion-->

              <!--#region 3s -->
              <img src="assets/icons/3.png" />
              <!--#endregion-->

              <!--#region 4s -->
              <img src="assets/icons/4.png" />
              <!--#endregion-->

              <!--#region 5s -->
              <img src="assets/icons/5.png" />
              <!--#endregion-->
            </div>
          </div>

          <!--#endregion-->
        </div>

        <button
          type="button"
          class="btn input btn-outline-dark btn-rounded button-wrapper"
          (click)="onSetRate()"
        >
          Submit
        </button>
        <br />
        <div
          class="alert alert-warning"
          *ngIf="!firstRate == false"
          role="alert"
        >
          Be the first to rate this Content!
        </div>
        <div
          class="alert alert-success alert-dismissible fade show"
          role="alert"
          *ngIf="!success == false"
        >
          <strong>Success!</strong> Thank you for rating us!
        </div>
      </div>
    </div>
  </div>
  <!--#endregion -->

              <!--#region knowledge check -->

  <div
    class="
      alert alert-dismissible
      fade
      show
      widget widget-author
      timeline-card
          "
    role="alert"
  >
    <div
      class="media align-items-center"
      (click)="OpenPopUp(check, 'lg')"
    >
      <a role="button" href="javascript:void(0)">
        <h3
          
          style="font-size: 20px; font-weight: 700; margin: 0;color: #0396A6;"
        >
          Check your Knowledge!
        </h3>
      </a>

      <img src="assets/icons/trophy.png" class="follow" />
    </div>

    <button
      type="button"
      class="close"
      data-dismiss="alert"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!--#region follow pop up-->
  <!-- #region pop up screen for solution-->

  <ng-template #check let-modal>
    <div class="modal-header">
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="fa fa-close"></i>
      </button>
    </div>

    <div class="modal-body">
      <div class="row h-100">
        <div class="col-12">
          <div class="align-items-center">
            <img src="assets/image/question.jpg" />
          </div>
          <div
            class="about-section bg-white rounded shadow-sm py-5 px-4"
          >
            <hr />
            <form class="form-group">
              <!--#region Q1-->
              <div class="row">
                <div class="col">
                  <p>1. <u>Scaling one's Server based on the cloud concept happens whith no need of configurations:</u></p>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="q1"
                      id="q1r1"
                      value="True"
                      [(ngModel)]="qestions.q1"
                    />
                    <label class="form-check-label" for="q1r1">
                      True
                    </label>
                    
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="q1"
                      id="q1r2"
                      value="False"
                      checked
                      [(ngModel)]="qestions.q1"
                    />
                    <label class="form-check-label" for="q1r2">
                      False 
                    </label>
                  </div>
                 
                </div>
              </div>
              <!--#endregion-->
              <hr />
              <!--#region Q2-->
              <div class="row">
                <div class="col">
                  <p>2. <u>Traditional server concept is based on the Virtualisation:</u></p>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="q2"
                      id="q2r1"
                      value="True"
                      [(ngModel)]="qestions.q2"
                    />
                    <label class="form-check-label" for="q2r1">
                      True
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="q2"
                      id="q2r2"
                      value="False"
                      checked
                      [(ngModel)]="qestions.q2"
                    />
                    <label class="form-check-label" for="q2r2">
                      False                    </label>
                  </div>
                  
                </div>
              </div>
              <!--#endregion-->
              <hr />
              <!--#region Q3-->
              <div class="row">
                <div class="col">
                  <p>
                   3. <u>Adapting Shared Hosting Server Concept, many persons can run their applications each in a different VM.:</u>
                  </p>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="q3"
                      id="q3r1"
                      value="True"
                      [(ngModel)]="qestions.q3"
                    />
                    <label class="form-check-label" for="q3r1">
                      True
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="32"
                      id="q3r2"
                      value="False"
                      checked
                      [(ngModel)]="qestions.q3"
                    />
                    <label class="form-check-label" for="q3r2">
                      False

                    </label>
                  </div>
                 
                </div>
              </div>
              <!--#endregion-->
            </form>
            <ng-template #check let-modal>
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  (click)="modal.dismiss('Cross click')"
                >
                  <i class="fa fa-close"></i>
                </button>
              </div>

              <div class="modal-body">
                <div class="row h-100">
                  <div class="col-12">
                    <div
                      class="
                        about-section
                        bg-white
                        rounded
                        shadow-sm
                        py-5
                        px-4
                      "
                    >
                      <div class="alert alert-{{ alert }}" role="alert">
                        {{ replay }}
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col">
                          <table class="table">
                            <thead class="thead">
                              <tr scope=" table-success">
                                <th scope="col ">Corract Answers</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                class="table-success"
                                *ngFor="let row of correct"
                              >
                                <td class="table-success">
                                  {{ row }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col">
                          <table class="table">
                            <thead class="thead-danger">
                              <tr scope=" table-danger">
                                <th scope="col table-danger">
                                  Wrong Answers
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                class="table-danger"
                                *ngFor="let wrong of wrong"
                              >
                                <td class="table-danger">
                                  {{ wrong }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <div class="col">
              <button
                type="button"
                class="
                  btn btn-outline-dark btn-rounded
                  button-wrapper
                  input
                "
                (click)="OpenPopUp(check, 'lg')"
                (click)="onCheck()"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- #endregion -->

  <!--#endregion-->
  <!--#endregion -->
        </div>
          </div>
          <!--#endregion-->
          <!--#region Middle hand-->
          <div class="col-lg-6 m-15px-tb">
            <!--#region article-->
            <article class="article">
              <div class="article-title">
                <div class="row">
                  <div class="col-6 col-md-9">
                    <h2>Cloud Computing</h2>
                  </div>
                  <div class="col-6 col-md-3">
                    <span (click)="onChange()"
                      ><input
                        type="checkbox"
                        checked
                        data-on="Tunisian"
                        data-off="English"
                        data-toggle="toggle"
                        data-onstyle="info"
                        data-offstyle="secondary"
                    /></span>
                  </div>
                </div>
              </div>
              <hr />
              <!--#region english article-->
              <div
                class="article-content"
                style="font-family: 'Segoe UI'"
                *ngIf="english == true"
              >
              <p><span data-preserver-spaces="true">"<span style="color: #ff0000;"><strong>living the problem is the best way to understand the solution</strong></span>"</span></p>
              <p><span data-preserver-spaces="true">And cloud computing is a solution for a big problem, that we are going to explain now.</span></p>
              <p><span data-preserver-spaces="true">So before understanding the cloud concept let's first try to understand why it was invented, what problems we encountered in the past, and previous concepts that we used to follow.</span></p>
              <p></p>
              <h4>Traditional Server Concept:</h4>
              <p><span data-preserver-spaces="true">In the past, and even now, people started working with physical servers.</span></p>
              <p><span data-preserver-spaces="true">When someone finishes working and developing his projects and it's ready to be published, one of the first things he will be needing is a big machine that will run his application 24/7 nonstop.</span></p>
              <p><span data-preserver-spaces="true">This machine is called a server, it's a big computer that will run our application for us.</span></p>
              <p><span data-preserver-spaces="true">These servers usually cost between $1000-$3000 and buying one means that we have to afford a suitable environment for it (a cold one) in addition to being fully responsible for taking care of the server's maintenance.</span></p>
              <p><span data-preserver-spaces="true">Having your own server means that you are fully responsible for keeping the data saved and safe from any external third party and harmful access.</span></p>
              <p><span data-preserver-spaces="true">This may look like a disadvantage and a big responsibility but at the same time, it's not, since you can then afford the best security.</span></p>
              <p></p>
              <h4>Virtualization:</h4>
              <p><span data-preserver-spaces="true">It's the process of creating multiple partitions from one single machine.</span></p>
              <p><span data-preserver-spaces="true">Which means creating from one single computer multiple virtual versions of it.</span></p>
              <p><span data-preserver-spaces="true">In fact, there is a software that we install in our computer(in our case, server), and it is called VMware this later will split our application and operating system layers into small computers called&nbsp;</span><span style="color: #ff0000;"><strong>virtual machines (VM)</strong></span></p>
              <p><span data-preserver-spaces="true">Each one of them can run on whatever OS we want as if they are separated and don't belong to the same computer(server).</span></p>
              <p></p>
              <h4>Virtual Private Server Concept (VPS):</h4>
              <p><span data-preserver-spaces="true">This concept was revolutionary compared to the previous concept.</span></p>
              <p><span data-preserver-spaces="true">We no longer own a local server in our organization like previously but now we will own a&nbsp;</span><span style="color: #00ff00;"><strong><span data-preserver-spaces="true">virtual&nbsp;</span></strong></span><span data-preserver-spaces="true">one.</span></p>
              <p><span data-preserver-spaces="true">And buy virtual, we will have a third party called server provider, and this later will provide us with servers that we can use from distance.</span></p>
              <p><span data-preserver-spaces="true">And this way we will no longer be responsible for taking care of the server, neither the suitable environment it needs or all other responsibilities because the service provider will take care of all of it.</span></p>
              <p><span data-preserver-spaces="true">The VPS concept is based on virtualization, and here we can understand that we can work on multiple VMs, and depending on our needs we may consume one or more.</span></p>
              <img class="centralized-image" src="assets/image/cloud/1.PNG" />
            
              <p></p>
              <h4>Shared Hosting Concept:</h4>
              <p><span data-preserver-spaces="true">This concept is similar to the VPS concept, one difference is that instead of owning a server and using it for your own needs or business, here the server will be&nbsp;</span><span style="color: #00ff00;"><strong><span data-preserver-spaces="true">shared&nbsp;</span></strong></span><span data-preserver-spaces="true">which means one server can run multiple applications thanks to virtualization and the different existing VMs.</span></p>
              <p><span data-preserver-spaces="true">Adapting this concept, even using one server, many persons can run their applications each in a different VM.</span></p>
              <img class="centralized-image" src="assets/image/cloud/2.jpg" />
              <p></p>
              <h4>Cloud Computing:</h4>
              <p><span data-preserver-spaces="true">Now cloud computing took the shared hosting concept to the next level.</span></p>
              <p><span data-preserver-spaces="true">Now it's much bigger and all resources are available and can be delivered throw the internet such as tools and servers, databases, networking, software, etc.</span></p>
              <p><span data-preserver-spaces="true">And manipulating all of these resources may happen just by working with a cloud provider (exp: <em>Microsoft Azure</em>, <em>Google Cloud</em>, <em>Amazon Web Services</em>, etc.)</span></p>
              <p><span data-preserver-spaces="true">And one of the many reasons why cloud computing was invented is to make it easier for users to get their applications to market, assuring better security for our data and especially saving for us the cost of equipment and maintenance.</span></p>
              <img class="centralized-image" src="assets/image/cloud/3.PNG" />
              <p></p>
              <h4>Why Cloud computing?</h4>
              <p><span data-preserver-spaces="true">There is a lot of reason that encourage us to migrate our projects to the cloud and we can mention that:</span></p>
              <p></p>
              <ul>
              <li><span data-preserver-spaces="true">Our services will always be <span style="color: #ff0000;">available</span> which means if any problem came up we don't need to worry about our data cause the traffic will be transferred to other servers.</span></li>
              </ul>
              <p></p>
              <ul>
              <li><span data-preserver-spaces="true">We don't need to worry about managing the <span style="color: #ff0000;">scaling</span> of our resources when it's needed, everything will happen automatically and the user can work on his project simultaneously, and we just need to manage the configuration of scaling beforehand.</span></li>
              </ul>
              <p></p>
              <ul>
              <li><span data-preserver-spaces="true">Any changes or updates you make to your project will happen <span style="color: #ff0000;">automatically</span> without interrupting anything, meanwhile, your app will still be running.</span></li>
              </ul>
              <p></p>
              <ul>
              <li><span data-preserver-spaces="true">If any issue or disaster happened you don't have to worry about your data because thanks to data replication your information can always be <span style="color: #ff0000;">recovered</span>.</span></li>
              </ul>
              <p></p>
              <h4>
                Types of Cloud Computing:
              </h4>
              <p><span data-preserver-spaces="true">Exactly we've got 3 types:</span></p>
              <p></p>
              <h6>
                Public Cloud:
              </h6>
              <p><span data-preserver-spaces="true">It includes all services managed and handled by the cloud which we can not own physically and we share it with others.</span></p>
              <p></p>
              <h6>
              Private Cloud:  
              </h6>
              <p><span data-preserver-spaces="true">It represents all resources dedicated and located in your own organization like servers and network tools.</span></p>
              <p></p>
              <h6>
                Hybrid Cloud:
              </h6>
              <p><span data-preserver-spaces="true">It will allow data to move between the public and your private environment.</span></p>
              <p></p>
              <h4>
                Categories of Cloud Computing:
              </h4>
              <p></p>
              <p><span data-preserver-spaces="true">Generally, there are 3 Main Categories of cloud computing depending on what services are responsible for managing which layers of the different functions:</span></p>
              <p></p>
              <h6>
                IAAS (Infrastructure as a Service):
              </h6>
              <p><span data-preserver-spaces="true">All services belonging to this category represent all resources you need to work on the infrastructure of your project or website.</span></p>
              <p><span data-preserver-spaces="true">The user will be the one managing, building, and manipulating the infrastructure of the application(role: administrator).</span></p>
              <p><span data-preserver-spaces="true">Meanwhile, all hardware is on the cloud provider and you don't need to worry about them.</span></p>
              <p><span data-preserver-spaces="true">exp: VMs</span></p>
              <p></p>
              <h6>
                PAAS (Platform as a Service):
              </h6>
              <p><span data-preserver-spaces="true">Now the cloud provider will take care of managing the whole infrastructure of your app and you will only have to develop your project, choose your tools, and take care of data management thanks to the resources provided by the cloud(role: developer)</span></p>
              <p></p>
              <h6>
                SAAS (Software as a Service):
              </h6>
              <p><span data-preserver-spaces="true">We kind of use this category daily, here the cloud will provide you the whole application or software as a service, you&rsquo;ll be only a consumer.</span></p>
              <p>&nbsp;</p>
              <img class="centralized-image" src="assets/image/cloud/4.jpg" />
                
                <br />
                <hr />
                <p class="blockquote-footer">
                  Feel free to rate the blog and send me feedback,questions or
                  suggestions through the
                  <span style="color: red; font-weight: bold">comment</span>
                  section below. <br />To be updated for Upcoming content, please
                  <span style="color: red; font-weight: bold">follow</span> this
                  website.
                </p>
              </div>
              <!--#endregion -->
              <!--#region tunisian article-->
              <div
                *ngIf="tunisian == true"
                class="article-content"
                style="direction: rtl; display: inline-block; text-align: right"
              >
              
              <p><span data-preserver-spaces="true">"<span style="color: #ff0000;"><strong>ما تنجم تفهم solution تصنعت الا ما تفهم ال problem الي هي توجدة على خاطرو</strong></span>"</span></p>
              <p><span data-preserver-spaces="true">و الcloud computing زادة ماهوا الا solution تصنعت باش تحللنا problems كبار الي باش نفهموهم توة.</span></p>
              <p></p>
              <h4>Traditional Server Concept:</h4>
              <p><span data-preserver-spaces="true">قبل و حتى توة العباد كانت ديما مستانسة تخدم ب des serveurs physique, يعني وين عندها projet باش تخدمو و يبدا حاضر باش العباد تبدا تستعمل فيه،اول حاجة يفكروا فيها هو انهم يشريوا machine كبيرة, تخدم 24/7 منغير ما توقف باش الapplication الي تخدم فيها تكون accessible للناس الكل في اي وقة.</span></p>
              <p><span data-preserver-spaces="true">الmachines هاذوما اسمهم des serveurs سومهم يتراوح على الاقل مابين 1000$-3000$, apart هاذا، موش الفايدة نشريوهم كهو اما زادة يلزم نوفرولهم الenvironement اللازم و نزيدو مالفوق الmaintenance متاع الserveur كان صارتلوا اي مشكلة.</span></p>
              <p><span data-preserver-spaces="true">ما ننساوش الي le fait انو يكون عندك الserveur متاعك يلزمك تتحمل مسؤولية الsecurity متاعو خاتر en fin احنا باش نخبيوا فيه معلومات متاع ناس باش يستعملوا ال application وخلاوا ثيقتهم باش يعطيونا معلوماتهم الخاصة.</span></p>
             <p></p>
              <h4>Virtualization:</h4>
              <p><span data-preserver-spaces="true">هي عملية باش تخلينا، منmachine وحدة، نصنعوا برشىpartitions  اخرين.</span></p>
              <p><span data-preserver-spaces="true"> يعني من ordinateur وحدة نصنعوا برشى نسخ افتراضية ليه و كل نسخة منهم باش تولي تخدم على اي système d'exploitation الي تحب عليه indépendamment مال OS الي يمشي بيه الordinateur متاعك originally.</span></p>
              <p><span data-preserver-spaces="true">الحاصل، فما software نصبوه في الmachine متاعن، اسموا VMware، و هو الي باش يقسملنا الdevice لبرشى </span><span style="color: #ff0000;"><strong>virtual machines (VM)</strong></span></p>
              <p></p>
              <h4>Virtual Private Server Concept (VPS):</h4>
              <p><span data-preserver-spaces="true">الconcept هاذا عمل برشى changement par rapport للconcept الي قبلو.</span></p>
              <p><span data-preserver-spaces="true">الserveur توة ماعادش réellement موجود معانا اما  ولا</span><span style="color: #00ff00;"><strong><span data-preserver-spaces="true">virtual&nbsp;</span></strong></span><span data-preserver-spaces="true">.</span></p>
              <p><span data-preserver-spaces="true">و كي قلنا virtual نجموا نفهمو هوني الي باش يكون فما طرف ثالث اسمه server provider و هو الي باش يوفرنا الserveur الي باش نخدمو بيه a distance.</span></p>
              <p><span data-preserver-spaces="true">و هكا نوليو ماعادش مسؤلين لا على الsecurity لا على الenvironemet.</span></p>
              <p><span data-preserver-spaces="true">الconcept هاذا basé على الvirtualisation donc نجمو نفهموا هوني الي الserveur باش يتقسم لvirtual machines و tout dépend من احتياجاتنا نجمو نستعملو as much VMs as we want  بماانو الserveur dédié ليلنا احنا كهو. </span></p>
              <img class="centralized-image" src="assets/image/cloud/1.PNG" />
            
              <p></p>
              <h4>Shared Hosting Concept:</h4>
              <p><span data-preserver-spaces="true">الconcept هاذا ياسر يشبه لل virtual private server concept (aka VPS) الفرق الوحيد انو توا الserveur الي كان dédiée لمستعمل واحد باش يولي ينجم يستعملوه برشى اخرين؟ كيفاش؟ </span></p>
              <p><span data-preserver-spaces="true">بكل بصاطة احنا الساعة قلنا ال الserveur مقسم لVMs يعني توا يولي برشا ناس ينجمو يتشاركوا في serveur و كل واحد منهم يخدم مثال بVM منغير ما يقلق حد.</span></p>
              <img class="centralized-image" src="assets/image/cloud/2.PNG" />
              <p></p>
              <h4>Cloud Computing:</h4>
              <p><span data-preserver-spaces="true"> الcloud طار بالShared Hosting Server Concept لlevel اخر.</span></p>
              <p><span data-preserver-spaces="true">كبر فيه و خلاّ كل الresources الي تتخيلهم و تستحقهم، موجودين a partir de l'internet, مثال كيف les serveurs, databases, software,etc.
                </span></p>
              <p><span data-preserver-spaces="true">ونجموا نتحكمو بكل الresources هاذوما a partir مالcloud provider portal كيف (<em>Microsoft Azure</em>, <em>Google Cloud</em>, <em>Amazon Web Services</em>, etc.)</span></p>
              <p><span data-preserver-spaces="true">و واحد مالاسباب المهمة الاخرى الي توجد على خاطرها الcloud computing انو باش يوفرلنا sécurité ماخير لمعلوماتنا و يوفرلنا المصروف الي كنا نجموا نخرجوه على les équipement الي كنا باش نشريوهم و نتلهاو بيهم.</span></p>
              <img class="centralized-image" src="assets/image/cloud/3.PNG" />
              <p></p>
              <h4>Why Cloud computing?</h4>
              <p><span data-preserver-spaces="true">كيف نجيوا نشوفوا فما برشى اسباب تشجعنا باش نعملوا migration مالlocal environment متاعنا للcloud, و منهم:</span></p>
              <p></p>
              <ul>
              <li><span data-preserver-spaces="true">اي service نشريوه ديما باش نلقاوه disponible, يعني كان صارت اي مشكلة في الserveur الي خبينا فيه الdata متاعنا، الtraffic لكل باش يمشي لserveur اخر في بلاصة نوعا ما بعيدة و safe وين باش تتخبا معلوماتنا غادي.</span></li>
              </ul>
              <p></p>
              <ul>
              <li><span data-preserver-spaces="true">ما عادش باش نبقاو نفكروا في الscaling متاع les serveurs الي نستعملوا فيهم كيف نوصلوا لun certain point على خاطر كل هاذا باش يصير automatiquement و الcloud  باش يتلها بيه. ما علينا كان نخدمو الconfiguration متاعو مالول كهو</span></li>
              </ul>
              <p></p>
              <ul>
              <li><span data-preserver-spaces="true">كل تغييرات و updates تعملهم موش باش ياكلوا برشى وقة و يعطلوا الخدمة متاعك.</span></li>
              </ul>
             
              <p></p>
              <h4>
                Types of Cloud Computing:
              </h4>
              <p><span data-preserver-spaces="true"> عندنا exactement 3types الي هوما:</span></p>
              <p></p>
              <h6>
                Public Cloud:
              </h6>
              <p><span data-preserver-spaces="true">يمثل كل les resources الي يكون الcloud هو الي يتلها بيهم و مسؤول عليهم و الي بحد ذاتهم مانجموش نملكوهم réellement امانستعملوهم virtulaly.</span></p>
              <p></p>
              <h6>
              Private Cloud:  
              </h6>
              <p><span data-preserver-spaces="true">يشمل كل الresources الي نملكوهم و نكونوا احنا مسؤولين عليهم كيف في الtraditional server concept.</span></p>
              <p></p>
              <h6>
                Hybrid Cloud:
              </h6>
              <p><span data-preserver-spaces="true">هو كيف الcoctail ما بين الpublic و الprivate cloud وين تنجم تخدم بresources مالجيهتين و عبر الcloud provider تتلها بكل orchestration و communication مابين les resources من different types.</span></p>
              <p></p>
              <h4>
                Categories of Cloud Computing:
              </h4>
              <p></p>
              <p><span data-preserver-spaces="true">كيف نحكيو في الcas générale عندنا 3 categories اساسية و التقسيم dépend مالtypes متاع les services الي نخدموا بيهم:</span></p>
              <p></p>
              <h6>
                IAAS (Infrastructure as a Service):
              </h6>
              <p><span data-preserver-spaces="true">كل service ينتمي للcategorie هاذه هو عبارة على les resources الي تنجم تخدم على الinfrastructure متاحم كيف O/S, middlewares و غيرهم.</span></p>
              <p><span data-preserver-spaces="true">وقة الي الcloud provider باش يتلها بكل ماهو hardware, مثال لservice كيف هكا : Virtual Machines.
                هوني نجمو نقولو you are an administrator.</span></p>
              <p></p>
              <h6>
                PAAS (Platform as a Service):
              </h6>
              <p><span data-preserver-spaces="true">في الcas هاذا الcloud provider هو الي باش يتلها بالhardware و الinfrastructure الزوز و الuser ما عليه كان يdevelopi الprojet متاعو و يعملو deployement. هوني نجمو نقولوyou are a developer .</span></p>
              <p></p>
              <h6>
                SAAS (Software as a Service):
              </h6>
              <p><span data-preserver-spaces="true">الcategorie نستعملو فيها كل يوم و مافيبالناش، هوني الcloud باش يعطيك الapplication حاضرة يعني هوني you are just a consumer.
                مثال لservice مالcategorie هاذه الgmail.</span></p>
              <p>&nbsp;</p>
              <img class="centralized-image" src="assets/image/cloud/4.jpg" />
                
            <br />
            <hr />
            <p class="blockquote-footer">
              Feel free to rate the blog and send me feedback,questions or
              suggestions through the
              <span style="color: red; font-weight: bold">comment</span>
              section below. <br />To be updated for Upcoming content, please
              <span style="color: red; font-weight: bold">follow</span> this
              website.
            </p>
              </div>
              <!--#endregion -->
            </article>
            <!--#endregion-->
          </div>
          <br />
          <!--#endregion-->
          <!--#region right hand-->
          <div class="col-lg-3 m-15px-tb blog-aside "  >
        
        <div class="sticky-sidebar" >
              <app-sidebar ></app-sidebar>
        <div></div>
            </div> 
           </div>
          <!--#endregion-->
        </div>
        <div class="row">
          <div class="col">
           
            <app-comment></app-comment>
         
           <br /><br />
          </div>
        </div>
      </div>
  
      <!--#endregion-->
    </article>
    <div id="mybutton">
      <a class="btn feedback text-m3awaj" href="/" role="button"> Home >></a>
    </div>
   
  </main>
