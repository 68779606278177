export class Follower {
    firstName: string;
    lastName: string;
    email: string;

    constructor(email: string,
        lastName: string,
        firstName: string) {
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;

    }
}