<main>
  <article>
    <!--#region content-->

    <div class="container-fluid">
      <div class="row align-items-start">
        <div class="col article">
          <div class="article-img align-items-center">
            <img src="assets/image/JWT/jwtcover.png" title="" alt="" />
          </div>
        </div>
      </div>
      <div class="row align-items-start">
        <!--#region Left hand-->
        <div  class="col-lg-3 m-15px-tb blog-aside sticky" >
           <!--#region video -->
           <div class="widget widget-latest-post">
            <div class="widget-body">
              <div class="embed-responsive embed-responsive-21by9">
                <h5>Video loading...</h5>
                <youtube-player
                  videoId="OjOgezW3OKA"
                  suggestedQuality="highres"
                  style="width: 100%"
                  id="youtube"
                >
                </youtube-player>
              </div>
            </div>
          </div>
          <!--#endregion -->

          <!--#region Rate -->
          <div class="widget widget-latest-post">
            <div class="widget-title">
              <div class="row">
                <div class="col-6">
                 <h3 >Rate</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <p style="font-size: 12px">
                    (Current {{ currentRate }} <i class="fa fa-star"></i>)
                  </p>
                </div>
              </div>
            </div>
            <div class="widget-body">
              <div class="">
                <div class="rating">
                  <!--#region stars-->
                  <input
                    type="radio"
                    name="rating"
                    id="rating-5"
                    (click)="onRate(5)"
                  />
                  <label for="rating-5"></label>
                  <input
                    type="radio"
                    name="rating"
                    id="rating-4"
                    (click)="onRate(4)"
                  />
                  <label for="rating-4"></label>
                  <input
                    type="radio"
                    name="rating"
                    id="rating-3"
                    (click)="onRate(3)"
                  />
                  <label for="rating-3"></label>
                  <input
                    type="radio"
                    name="rating"
                    id="rating-2"
                    (click)="onRate(2)"
                  />
                  <label for="rating-2"></label>
                  <input
                    type="radio"
                    name="rating"
                    id="rating-1"
                    (click)="onRate(1)"
                  />
                  <label for="rating-1"></label>
                  <!--#endregion-->

                  <!--#region Emojis-->
                  <div class="emoji-wrapper">
                    <div class="emoji">
                      <!--#region none -->
                      <img src="assets/icons/0.png" />
                      <!--#endregion-->

                      <!--#region 1s -->
                      <img src="assets/icons/00.png" />
                      <!--#endregion-->

                      <!--#region 2s -->
                      <img src="assets/icons/2.png" />

                      <!--#endregion-->

                      <!--#region 3s -->
                      <img src="assets/icons/3.png" />
                      <!--#endregion-->

                      <!--#region 4s -->
                      <img src="assets/icons/4.png" />
                      <!--#endregion-->

                      <!--#region 5s -->
                      <img src="assets/icons/5.png" />
                      <!--#endregion-->
                    </div>
                  </div>

                  <!--#endregion-->
                </div>

                <button
                  type="button"
                  class="btn input btn-outline-dark btn-rounded button-wrapper"
                  (click)="onSetRate()"
                >
                  Submit
                </button>
                <br />
                <div
                  class="alert alert-warning"
                  *ngIf="!firstRate == false"
                  role="alert"
                >
                  Be the first to rate this Content!
                </div>
                <div
                  class="alert alert-success alert-dismissible fade show"
                  role="alert"
                  *ngIf="!success == false"
                >
                  <strong>Success!</strong> Thank you for rating us!
                </div>
              </div>
            </div>
          </div>
          <!--#endregion -->

          <!--#region knowledge check -->

          <div
            class="
              alert alert-dismissible
              fade
              show
              widget widget-author
              timeline-card
            "
            role="alert"
          >
            <div
              class="media align-items-center"
              (click)="OpenPopUp(check, 'lg')"
            >
              <a role="button" href="javascript:void(0)">
                <h3
                  
                  style="font-size: 20px; font-weight: 700; margin: 0;color: #0396A6;"
                >
                  Check your Knowledge!
                </h3>
              </a>

              <img src="assets/icons/trophy.png" class="follow" />
            </div>

            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <!--#region follow pop up-->
          <!-- #region pop up screen for solution-->

          <ng-template #check let-modal>
            <div class="modal-header">
              <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
              >
                <i class="fa fa-close"></i>
              </button>
            </div>

            <div class="modal-body">
              <div class="row h-100">
                <div class="col-12">
                  <div class="align-items-center">
                    <img src="assets/image/question.jpg" />
                  </div>
                  <div
                    class="about-section bg-white rounded shadow-sm py-5 px-4"
                  >
                    <hr />
                    <form class="form-group">
                      <!--#region Q1-->
                      <div class="row">
                        <div class="col">
                          <p>1. <u>OAuth2.0:</u></p>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="q1"
                              id="q1r1"
                              value="Is a Authorization protocol."
                              [(ngModel)]="qestions.q1"
                            />
                            <label class="form-check-label" for="q1r1">
                              Is a Authorization protocol.
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="q1"
                              id="q1r2"
                              value="Is a Authentication protocol."
                              checked
                              [(ngModel)]="qestions.q1"
                            />
                            <label class="form-check-label" for="q1r2">
                              Is a Authentication protocol.
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="q1"
                              id="q1r3"
                              checked
                              value="A class is open for extentions."
                              [(ngModel)]="qestions.q1"
                            />
                            <label class="form-check-label" for="q1r3">
                              Permit Individual to server authentication
                            </label>
                          </div>
                        </div>
                      </div>
                      <!--#endregion-->
                      <hr />
                      <!--#region Q2-->
                      <div class="row">
                        <div class="col">
                          <p>2. <u>What is the structure of a JWT</u></p>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="q2"
                              id="q2r1"
                              value="Header/Payload/Signature."
                              [(ngModel)]="qestions.q2"
                            />
                            <label class="form-check-label" for="q2r1">
                              Header/Payload/Signature.
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="q2"
                              id="q2r2"
                              value="Subject/Payload/Signature."
                              checked
                              [(ngModel)]="qestions.q2"
                            />
                            <label class="form-check-label" for="q2r2">
                              Subject/Payload/Signature.
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="q2"
                              id="q2r3"
                              checked
                              value="Header/Content/Signature."
                              [(ngModel)]="qestions.q2"
                            />
                            <label class="form-check-label" for="q2r3">
                              Header/Content/Signature.
                            </label>
                          </div>
                        </div>
                      </div>
                      <!--#endregion-->
                      <hr />
                      <!--#region Q3-->
                      <div class="row">
                        <div class="col">
                          <p>
                            3.
                            <u
                              >In which part of the token is Signing algorithm
                              mentioned</u
                            >
                          </p>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="q3"
                              id="q3r1"
                              value="Payload"
                              [(ngModel)]="qestions.q3"
                            />
                            <label class="form-check-label" for="q3r1">
                              Payload
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="32"
                              id="q3r2"
                              value="Signature"
                              checked
                              [(ngModel)]="qestions.q3"
                            />
                            <label class="form-check-label" for="q3r2">
                              Signature
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="q3"
                              id="q3r3"
                              checked
                              value="Header"
                              [(ngModel)]="qestions.q3"
                            />
                            <label class="form-check-label" for="q3r3">
                              Header
                            </label>
                          </div>
                        </div>
                      </div>
                      <!--#endregion-->
                    </form>
                    <ng-template #check let-modal>
                      <div class="modal-header">
                        <button
                          type="button"
                          class="close"
                          aria-label="Close"
                          (click)="modal.dismiss('Cross click')"
                        >
                          <i class="fa fa-close"></i>
                        </button>
                      </div>

                      <div class="modal-body">
                        <div class="row h-100">
                          <div class="col-12">
                            <div
                              class="
                                about-section
                                bg-white
                                rounded
                                shadow-sm
                                py-5
                                px-4
                              "
                            >
                              <div class="alert alert-{{ alert }}" role="alert">
                                {{ replay }}
                              </div>
                              <hr />
                              <div class="row">
                                <div class="col">
                                  <table class="table">
                                    <thead class="thead">
                                      <tr scope=" table-success">
                                        <th scope="col ">Corract Answers</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        class="table-success"
                                        *ngFor="let row of correct"
                                      >
                                        <td class="table-success">
                                          {{ row }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div class="col">
                                  <table class="table">
                                    <thead class="thead-danger">
                                      <tr scope=" table-danger">
                                        <th scope="col table-danger">
                                          Wrong Answers
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        class="table-danger"
                                        *ngFor="let wrong of wrong"
                                      >
                                        <td class="table-danger">
                                          {{ wrong }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                    <div class="col">
                      <button
                        type="button"
                        class="
                          btn btn-outline-dark btn-rounded
                          button-wrapper
                          input
                        "
                        (click)="OpenPopUp(check, 'lg')"
                        (click)="onCheck()"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <!-- #endregion -->

          <!--#endregion-->
          <!--#endregion -->

        </div>
        <!--#endregion-->
        <!--#region Middle hand-->
        <div class="col-lg-6 m-15px-tb">
          <!--#region article-->
          <article class="article">
            <div class="article-title">
              <div class="row">
                <div class="col-6 col-md-9">
                  <h2>Json Web Tokens</h2>
                </div>
                <div class="col-6 col-md-3">
                  <span (click)="onChange()"
                    ><input
                      type="checkbox"
                      checked
                      data-on="Tunisian"
                        data-off="English"
                        data-toggle="toggle"
                        data-onstyle="info"
                        data-offstyle="secondary"
                  /></span>
                </div>
              </div>
            </div>
            <hr />
            <!--#region english article-->
            <div
              class="article-content"
              style="font-family: 'Segoe UI'"
              *ngIf="english == true"
            >
              <p>
                Privacy has always been an important factor that we shouldn't
                mess with.
              </p>
              <p>
                But the thing is, when you use a website, game or any kind of
                platform most of them require your personal data like email,
                name, phone number, location, credit card...
                <br />
                And of course all of that is for a descent reasons like,
                delivery services, contact reasons, or in order to buy a
                product,etc.
              </p>
              <p>
                All of these are personal data that we may not want to share
                with others but we have to so the other party can
                <span style="font-weight: bold">IDENTIFY</span> us.
                <br />
                lets imagine it as a movie theater where you need to show your
                ticket so the ticket agent will identify you and let you in.
                <br /><br />
                But along our
                <span style="font-weight: bold">IDENTITY</span> there is another
                imprtant thing which is
                <span style="font-weight: bold"> permission</span> that we will
                understand later on.
              </p>
              <h4>What is Authentication and Autorization?</h4>
              <p>
                Lets say we have a hotel, and a group of people are staying
                there for a couple of days.
                <br />The first thing the receptionist do is receive the tourist
                and check their identties if they exist in the list or not (this
                is authentication) its the phase of identifying people(users in
                our case) this is the part where you expose or give your
                personal information to a third party.
                <br />
                But the thing is, the hotel isn't accessible for everyone even
                after being identified as tourists there.What does that mean?
                <br />
                well for exemple night clubs arent allowed for kids under the
                age of 18, thus they are not AUTHORIZED to go there.
              </p>
              <img class="centralized-image" src="assets/image/JWT/avsa.png" />
              <br />
              <p>
                Anyway, this topic is based on these laters,
                <span style="color: red">authentication</span> and
                <span style="color: red">authorization</span>.
              </p>
              <p>
                In fact developers didn't take it lightly, because identifying a
                person and getting his data is a risky task to do and a big
                responsabiliy to carry.
                <br />Developers had to invent a secure method to prevent others
                from having access to a user's personal data. <br />
                <br />Now
                <span style="color: red">technically</span> speaking.<br />
                Before jumping to json web tokens let's understand some
                important related point which is authentication protocols.
                <br />
                <span style="color: red">OAuth2.0</span> and<span
                  style="color: red"
                  >OpenID Connect</span
                >
                .
              </p>
              <h4>What is OAuth2.0?</h4>
              <p>
                It's a protocol that gives a person the ability to use third
                party application like (facebook, google, etc. ) To
                authenticate.<br />
                So most likely it's not meant to make a server authorize a
                person but a server authorize another server it's a server to
                server authentication rather than individual to server
                authentication,<br />which mean the current app you are login in
                wont identify you by your user name and password, but by
                accessing to a third party application and getting data from it.
              </p>
              <h5>How does it work ?</h5>
              <p>
                Well first a request to access a third-party's info will be
                sent, and after the user approve a token will be generated
                automatically and sent to the original server containing the
                provided data that he needs
              </p>
              <img
                class="centralized-image"
                src="assets/image/JWT/exemple.png"
              />
              <br />
              <h4>What is a Token?</h4>
              <p>
                Let's simply concidered it as the ID card that represent who you
                are. <br />Just by showing it to the app it will authorize you.
              </p>
              <br />
              <h4>What is OpenID Connect AKA (OIDC)?</h4>

              <p>
                First it's an identity layer that works on top of OAuth2.0.<br />
                And a protocol that will help us simply implement the
                traditional concept of identifying a user ...
              </p>
              <img
                class="centralized-image"
                src="assets/image/JWT/simple.jpeg"
              />
              <br />
              <p>
                Now let's focus on the concept of the OIDC.<br /><br />
                <span style="font-weight: bold">Participant</span><br />
                Is the resource owner(user),it's the person for whom we are
                asking to get information.<br /><br />
                <span style="font-weight: bold">ID Token (JWT)</span><br />
                It's like an identity card that contains claims(attribute) which
                are informations about the resource owner.<br />
                And this later is represented as the famous Json Web Token.<br /><br />
              </p>
              <h4>What is JWT(json web token)?</h4>
              <p>
                Defined as a secure way for transmitting informations between
                parties as json object. Its like a sealed envelop.
              </p>
              <img
                class="centralized-image"
                src="assets/image/JWT/Capture.PNG"
              /><br />
              <p>
                JWT are used not only for authentication but authorization
                too.<br />
                In fact once the user is logged in each request he send the JWT
                will e included As well.<br /><br />

                It will allow the user to access resources that are permitted
                and prevent him from getting to the ones which are not.<br />

                Let's get more into it and talk about the papers delivered throw
                this envelop (the structure of JWT).
              </p>
              <h4>JWT Structure</h4>
              <p>
                Json web tokens are separated into 3 parts.<br /><br />
                <span style="font-weight: bold">Header</span><br />
                It carry,<br />
                -Type of the token which is JWT in our case.<br />
                -Signing algorithm that we are going to use to encode our
                token(SHA256,RSA,etc ).<br /><br />
                <span style="font-weight: bold">Payload</span><br />
                It contain the claims we talked about earlier which are the
                claimed data about our user it is composed of a:<br />
              </p>
              <h6>1-Subject:</h6>
              <p>
                It's an ID given by an identity provider, in our case its OpenID
                Connect assigned to the user.<br />
              </p>
              <h6>2-Issuing authority:</h6>
              <p>
                The one responsible on issuing the token, It's is our identity
                provider<br />
              </p>
              <h6>3-Audience:</h6>
              <p>
                The third party who is going to claim the token to identify the
                user which is for exemple the client app you are logging in.<br />
              </p>
              <h6>4-Issue Date:</h6>
              <p>The creation date of the token<br /></p>
              <h6>5-Expiration date:</h6>
              <p>
                The date after which the token won't be valid anymore<br /><br />
                <span style="font-weight: bold">Signature</span><br />
                The signature is our seal, it's a combination of all the encoded
                header, encoded payload, and a secret (a password to assure
                integration and nonreputation) and the crypting algorithm.
              </p>
              <img class="centralized-image" src="assets/image/JWT/map.PNG" />

              <br />
              <hr />
              <p class="blockquote-footer">
                Feel free to rate the blog and send me feedback,questions or
                suggestions through the
                <span style="color: red; font-weight: bold">comment</span>
                section below. <br />To be updated for Upcoming content, please
                <span style="color: red; font-weight: bold">follow</span> this
                website.
              </p>
            </div>
            <!--#endregion -->
            <!--#region tunisian article-->
            <div
              *ngIf="tunisian == true"
              class="article-content"
              style="direction: rtl; display: inline-block; text-align: right"
            >
              <p>معلوماتنا الخاصة ديما يلزمها تكون حاجة ما نستخفوش بيها.</p>
              <p>
                اما المشكلة وقت الي تجي باش تستخدم تطبيق والا تدخل لموقع يطلب
                منك معلوماتك الخاصة كيف نومرمك , البلاصة الي تسكن فيها، credit
                card...
                <br /><br />
                و بالطبيعة هاذا كلوا لأسباب مقنعة كيف، خدمة التوصيل،
                المراسلة،الدفع و غيرهم.
              </p>
              <p>
                المعلومات الكل هاذي خاصة و صعيب علينا إنا نعطيوها اما نضطروا
                باش نعملوا هكا باش الطرف لاخر ينجم
                <span style="font-weight: bold">يعرغنا</span>
                .
                <br />
                نجمو نتخيلوا الوضعية هاذي كسينيما وين يلزمك تاخوا تذكرة باش
                الطرف لاخر يعرفك

                <br /><br />
                المرحلة هاذي نجموا ناديولها
                <span style="font-weight: bold; color: red"
                  >Authentication</span
                >
                و مع المرحلة هاذي فما حاجة اخرى ما يلزمناش ننساوها و الي هي ال
                permission و بلغة اخرى ال
                <span style="font-weight: bold; color: red">Authorization</span>
              </p>
              <h4>شنوا ال Authenticationو الAuthorization؟</h4>
              <p>
                هات نقولوا عندنا Hotel و سيّاح حجزوا باش يبقاو ايامات غادي.<br />
                اول حاجة تصير في الاستقبال هي ال identification phase الي باش
                نتعرفو فيها علي السياح و نتفقدوا معلوماتهم الخاصّة. اما المشكلة
                موش على خاطر تعرفوا عليك وخلاوك تدخل يعني عندك access لل Hotel
                الكل.<br />
                شمعناها،مثال الصغار الي عمارهم اقل من 18 ماعندهمش (Permission)
                باش يدخلوا لل boite de nuit. وهوني نرجعو للزوز حالات الي حكينا
                عليهم <span style="color: red">authentication</span> و
                <span style="color: red">authorization</span>...
              </p>
              <img class="centralized-image" src="assets/image/JWT/avsa.png" />
              <br />
              <p>
                الحاصل، developers ما ستخفوش بالحاجة هاذي
                <br />على خاطرها مسؤلية كبيرة، هاكا علاش جابوا حل secure يمنع اي
                طرف خارجي من انو ياخو معلومات المستعمل متاعنا.. <br />
                <br />
                توّة نحكيو شوية <span style="color: red">technique</span>،اما
                قبل ما نبداو نحكيو على ال Json Web Token هات نفهموا زوز نقاط
                مهمين مربوطين بيه الي هوما
                <span style="color: red">OAuth2.0</span> protocol و
                <span style="color: red">OpenID Connect</span>.
              </p>
              <h4>شنوّا هو OAuth2.0؟</h4>
              <p>
                هو protocol يوفر للapplication متاعنا الامكانية انها تاخو
                معلوماتنا الخاصّة من application اخرى و تتعرف علينا من عبرها،موش
                بالطريقة التقليدية و الاصلية و الي هي le fait إنك تعتي UserName
                و Password<br />. لحاجة هاذي ديما نراوها كيف ندخلوا لwebsite
                ولّا app و تقوللنا تنجم تعمل sign in ولّا sign up بالcompte
                Facebook متاعك ولّا gmail مثال...
              </p>
              <h4>كيفاش تصير العمليّة؟</h4>
              <p>
                اوّل حاجة الapplication إلّي احنا باش نسجلوا فيها تبعث request
                للthird party app (مثال Facebook..) باش تاخو المعلومات اللّازمة
                من عندو و مبعد ما الuser يوافق على انّو الapplication هاذي تاخو
                الdata من عندو، يصنع TOKEN فيه المعلومات اللّازمة و يبعثو لل
                original server.
              </p>
              <img
                class="centralized-image"
                src="assets/image/JWT/exemple.png"
              />
              <br />
              <h4>مالا شنوّة الToken؟</h4>
              <p>
                بكل بساطة نجموا نعتبروها بطاقة التّعريف متاعنا الِّي كل ما
                نوريوها للapplication تتعرّف علينا.
              </p>
              <br />
              <h4>شنوَّا ال OpenID Connect aka (OIDC)؟</h4>

              <p>
                هي طبقة تخدم فوق ال OAuth2.0 الي حكينا عليها سَّعا. <br />
                يعني هو زادا protocol اما ماهو الّا باش يخلِّينا نطبقوا عمليّة
                ال Authentication العادية متاعنا (بال user name و password..)
              </p>
              <img
                class="centralized-image"
                src="assets/image/JWT/simple.jpeg"
              />
              <br />
              <p>
                توّة هات نحكيو على ال concepts متاعها<br /><br />
                <span style="font-weight: bold">Participant</span><br />
                هو ال user , الشخص الِّي احنا باش ناخذوا ال data من عنده.<br /><br />
                <span style="font-weight: bold">ID Token (JWT)</span><br />

                هي عبارة بطاقة تعريف الِّي فيها claims و الِّي هوما معلومات على
                ال resource owner (user)<br />. و ال ID هاذا يعرف بال JWT
              </p>
              <h4>شنوَّة ال JWT (Json Web Token)؟</h4>
              <p>
                تعتبر طريقة secure نحولوا بيها الdata متاعنا من مصدر لمصدر آخر
                في وسط Json object<br />. نجمو نغزرولوا من ناحية إنُّو جواب
                مختوم.
              </p>
              <img
                class="centralized-image"
                src="assets/image/JWT/Capture.PNG"
              /><br />
              <p>
                امّا ال JWT ما نستعملوهاش كان باش نتعرفوا على ال authentication
                (user ) امّا زادة لل authorization phase.<br />
                في كل request تتبعث الJWT يتحط فيها و وقتها وين الapplocation
                تتفقد الToken و ترى كان الuser عنده access لresource معينة تخليه
                ماكانش لا
              </p>
              <h4>JWT Structure</h4>
              <p>
                :الJWT مقسْمة ل3 اجزاء<br />
                <span style="font-weight: bold">Header</span><br />
                و فيها:<br />
                -نوعية ال Toke(في حاتنا json web token) <br />-signing
                algorithm, الِّي باش نستعملوه باش نكوديوا ال Token متاعنا.<br /><br />
                <span style="font-weight: bold">Payload</span><br />
                فيهم الclaims الِّي حكينا عليهم السَّعة الِّي يشملوا المعلومات
                متاع الuser. و تتكون من:
              </p>
              <h6>1-Subject:</h6>
              <p>
                هو ID يعطيه الIdentity Provider إلي هو في حالتنا OpenID Connect
                لل User.<br />
              </p>
              <h6>2-Issuing authority:</h6>
              <p>
                هو الطرف ٕلي باش يعطينا ال token إلي هو الIdentity provider.<br />
              </p>
              <h6>3-Audience:</h6>
              <p>
                هو ال third party إلي باش باش يستعمل ال token لغرض إنو يعرف
                الuser شكون و في حالتنا ماهي إلّا الapplication ,إلّي نستعملوا
                فيها.
              </p>
              <h6>4-Issue Date:</h6>
              <p>هي الdate إلي تصنع فيها الToken<br /></p>
              <h6>5-Expiration date:</h6>
              <p>
                هي ال date إلي الصلحيَّة متاع الtoken توفا فيه<br /><br />
                <span style="font-weight: bold">Signature</span><br />
                هي ال date إلي الصلحيَّة متاع الtoken توفا فيها
              </p>
              <img class="centralized-image" src="assets/image/JWT/map.PNG" />

              <br />
              <hr />
              <p class="blockquote-footer">
                Feel free to rate the blog and send me feedback,questions or
                suggestions through the
                <span style="color: red; font-weight: bold">comment</span>
                section below. <br />To be updated for Upcoming content, please
                <span style="color: red; font-weight: bold">follow</span> this
                website.
              </p>
            </div>
            <!--#endregion -->
          </article>
          <!--#endregion-->
        </div>
        <br />
        <!--#endregion-->
        <!--#region right hand-->
        <div class="col-lg-3 m-15px-tb blog-aside">
         
          <app-sidebar></app-sidebar>
        </div>
        <!--#endregion-->
      </div>
      <div class="row">
        <div class="col">
         <app-comment></app-comment>
          <br /><br />
        </div>
      </div>
    </div>

    <!--#endregion-->
  </article>
  <div id="mybutton">
    <a class="btn feedback text-m3awaj" href="/" role="button"> Home >></a>
  </div>
</main>
