export class Comment {
    email: string;
    subject: string;
    questions: string;

    constructor(email: string,
        subject: string,
        questions: string) {
        this.email = email;
        this.subject = subject;
        this.questions = questions;

    }
}