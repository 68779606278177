<main id="bg">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-sm-6 d-flex justify-content-center text-center">
        <div>
          <div class="avatar">
            <a href="mariem-moalla">
              <img src="assets/image/image.jpg" />
            </a>
          </div>
          <div class="content">
            <h1>Let's Meet</h1>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 d-flex justify-content-center text-center">
        <div clas="img-with-text">
          <div class="avatar">
            <a href="letslearn">
              <img
                class="centralized-image"
                style="width: 100%; margin: 20px -1px"
                src="assets/icons/waytolearnlogo.png"
              />
            </a>
          </div>
          <span class="content">
            <h1>Let's Learn</h1>
          </span>
        </div>
      </div>
    </div>
  </div>
</main>
