<!--#region Navbar-->

<nav class="navbar navbar-expand-lg navbar-light fixed-top shadow ps-3 " id="navbar">
  <div class="container-fluid">
    <img
      src="assets/icons/waytolearnlogo.png"
      style="width: 57px; height: auto"
    />
    &nbsp;&nbsp;
    <a class="navbar-brand" href="letslearn#home" id="logo">WAY TO LEARN</a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent" >
      <ul class="navbar-nav ml-auto" >
        <li class="nav-item dropdown" >
          <a
            class="nav-link dropdown-toggle nav-text  text-light"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            href="javascript:void(0)"
            style="float:right;" 
          >
            Topics
          </a>
          <div class="dropdown-menu " aria-labelledby="navbarDropdown"   style="background-color: #011c40;">
            <a
              class="dropdown-item nav-text  text-light"
              style="float:right;" 
              href="letslearn/designprinciples&patterns"
              >S.O.L.I.D</a
            >
            <a class="dropdown-item nav-text  text-light" href="letslearn/jsonwebtokens"
              >JWT</a
            >
            
            <a class="dropdown-item nav-text  text-light" href="letslearn/cache"
              >Caching</a
            >
            <a class="dropdown-item nav-text  text-light" href="letslearn/api"
              >APIs</a
            >
            <a class="dropdown-item nav-text  text-light" href="letslearn/microservices"
            >Microservices</a
          >
          <a class="dropdown-item nav-text  text-light" href="letslearn/cloudcomputing"
          >Cloud Computing</a
          >
          <a class="dropdown-item nav-text  text-light" href="/letslearn/value&referencetypes"
          >Value&Reference Type</a
          >
          <a class="dropdown-item nav-text  text-light" href="/letslearn/datainterchangeformats"
          >Data Interchange Formats</a
          >
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link nav-text text-light" href="letslearn/socialmedia"style="float:right;" 
            >Social Media</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link nav-text text-light" href="letslearn#contact"style="float:right;" 
            >Contact</a
          >
        </li>
       
      </ul>
    </div>
  </div>
</nav>
<!--#endregion-->


<div style="z-index: -1">
  <router-outlet></router-outlet>
</div>
<div style="z-index: 3">
  <app-footer></app-footer>
</div>
