<!--#region Cover-->
<div class="parallax bg1"></div>
<!--#endregion-->

<!--#region Social Maedia-->
<section class=" bg0">
<br>
<div class="p-5 text-center  ">
  <h1 class="mb-3" style="color: #011C40;">Social Media Latest</h1>
</div>
<div class="shadow-1-strong my-5 p-5 container" id="leatslearn">
  <div  id="gallery"> 
      <div class="cont">
        <div class="row">
        <div class="col-sm-4 mb-4" >
        <div class="card" >
          
            <img src="https://scontent.ftun15-1.fna.fbcdn.net/v/t39.30808-6/274356344_126411443244196_7427234011543680711_n.jpg?_nc_cat=104&ccb=1-5&_nc_sid=8bfeb9&_nc_ohc=Ul3fnuZ4hpwAX8RVu1C&_nc_ht=scontent.ftun15-1.fna&oh=00_AT8fyAKkejRMjFfz99bthAuzCAvk3_ibxnbwG7UjlQSjQw&oe=6255A82C" alt="" class="card-img-top">
            <div class="card-body">
            <h5 class="card-title">HTTP Status Code</h5>
            <p class="card-text" style="direction: rtl;  text-align: right" >وقة تبدا تكودي، ينجموا يعرضوك الارقام هاذوما ديما.
              اسمهم HTTP Status Code.</p>
            <a target="blank" href="https://www.facebook.com/waytolearnn/photos/a.104560468762627/126411373244203/" class="btn btn-outline-success btn-sm">View Post</a>&nbsp;
            <a target="blank" href="https://www.facebook.com/waytolearnn" class="btn btn-outline-primary btn-sm" ><i class="fa fa-facebook"></i></a>
          </div>
          
        </div>
        </div>
      <div class="col-sm-4 mb-4" >
      <div class="card" >
          <img src="https://media-exp1.licdn.com/dms/image/C4E22AQGLjQD3l3VLew/feedshare-shrink_2048_1536/0/1645292434160?e=2147483647&v=beta&t=DdS1Ws_Sd04SZdfPjyOKubpv9WQkhgUY9C39bdnPiJo" alt="" class="card-img-top">
          <!--<div class="card_parallex card-img-top" style="  background-image: url(  https://scontent.fnbe1-2.fna.fbcdn.net/v/t39.30808-6/273988203_125611203324220_8346623375479487314_n.png?_nc_cat=105&ccb=1-5&_nc_sid=730e14&_nc_ohc=s5MaNYrnhxsAX8f5COm&_nc_ht=scontent.fnbe1-2.fna&oh=00_AT-KdxQFq5P5trqygzll-QHSxyAMTwG6eH6hJ7QlKXbWAA&oe=6214D814) ;"></div>--> 
          <div class="card-body">
            <h5 class="card-title">Value&Reference Type</h5>
            <p class="card-text">Did you ever wonder where all the variables we create in our code are saved?</p>
          
            <a target="blank" href="https://www.linkedin.com/company/way-to-learn/">View Post</a>&nbsp;

            <a target="blank" href="https://www.linkedin.com/company/way-to-learn/" class="btn btn-outline-info btn-sm"><i class="fa fa-linkedin"></i></a>
          </div>
          </div>
        </div>
        <div class="col-sm-4 mb-4" c>
        <div class="card" >
          <img src="assets/image/HomePage/in5.jpg" alt="" class="card-img-top">
          <div class="card-body">
            <h5 class="card-title">SOLID</h5>
            <p class="card-text">There may come a time when you start coding and notice that your code is getting messy or ...</p>
          
            <a target="blank" href="https://www.instagram.com/p/CYca72CLg41/?utm_source=ig_web_copy_link" class="btn btn-outline-success btn-sm">View Post</a>&nbsp;
            <a target="blank" href="https://www.instagram.com/waytolearnn/" class="btn btn-outline-danger btn-sm"><i class="fa fa-instagram"></i></a>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="wrap">
  <button  class="button"><a href="/letslearn/socialmedia">See More</a></button>
</div>
<br><br>
</section>
<!--#endregion"-->

<!--#region carsool-->
<div class="parallax bg2">
  <br>
<div class="p-5 text-center">
  <h1 class="mb-3" style="color: #011C40;">Latest Content</h1>
</div>
<div class="section" id="carousel">
  <div class="container">
      <div class="row">
          <div class="col-md-8 mr-auto ml-auto">

              <!--#region Carousel Card -->
              <div class="card card-raised card-carousel">
                  <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" data-interval="3000">
                    <ol class="carousel-indicators">
                      <li data-target="#carouselExampleIndicators"  data-slide-to="0" class="active"></li>
                      <li data-target="#carouselExampleIndicators" data-slide-to="1" class=""></li>
                      <li data-target="#carouselExampleIndicators" data-slide-to="2" class=""></li>
                    </ol>
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <img class="d-block w-100" src="assets/image/Microservice/MA.jpg" alt="First slide">
                        
                        <div class="carousel-caption d-none d-md-block">

                        </div>
                      </div>
                      <div class="carousel-item">
                        <img class="d-block w-100" src="assets/image/cloud/clouding.png"  alt="Second slide">
                        <div class="carousel-caption d-none d-md-block">
                        </div>
                      </div>
                      <div class="carousel-item">
                        <img class="d-block w-100" src="assets/image/api/apicover.jpg" alt="Third slide">
                        <div class="carousel-caption d-none d-md-block">

                        </div>
                      </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                      <i class="fa fa-angle-left" style="color: black;"></i>
                      <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                      <i class="fa fa-angle-right" style="color: black;"></i>
                      <span class="sr-only">Next</span>
                    </a>
                  </div>
              </div>
              <!--#endregion Carousel Card -->

          </div>
      </div>
  </div>
</div>
</div>

<!--#endregion-->

<!--#region Topics-->
<section class="bg3">
<br>
<div class="p-5 text-center">
  <h1 class="mb-3" style="color: #011C40;">Topics</h1>
</div>
<!--#region Search Bar-->
<div class="container-search ">
  <div class="row justify-content-center">

      <div class="col-8" >
         
          <div class="input-group mb-3 glow-button">
            
            <input type="text" list="contents" [(ngModel)]="searchValue" name="content" class="form-control input-text" placeholder="Search products...." aria-label="Recipient's username" aria-describedby="basic-addon2">
              <div class="input-group-append"> <button class="btn costum-green-outline btn-sm" type="button" (click)="search()"><i class="fa fa-search"></i></button> </div>
          </div>
          <datalist id="contents">
            <option value="Value & Reference Type">
            <option value="Cloud Computin">            
            <option value="Microservices">
            <option value="APIs">
            <option value="Caching">
            <option value="JWT Json Web Tokens">
            <option value="S.O.L.I.D Design Principles">
            <option value="Data Interchange Formats">
          </datalist>
      </div>
  </div>
</div>
<!--#endregion-->
<div class="shadow-1-strong  p-5" id="leatslearn">
  
  <div class="row">
    <!--#region data interchange formats-->
       <div class="col-lg-4 col-md-6 mt-4 pt-2">

        <div class="blog-post rounded border">
            
        <div class="blog-img d-block overflow-hidden position-relative">
          <img
            src="assets/image/datainterchangeformats/DataEnterchange.png"
            class="img-fluid rounded-top img-size"
            alt=""
          />
          <div class="overlay rounded-top bg-dark"></div>
  
          <div class="post-meta">
            <a
              href="/letslearn/datainterchangeformats"
              class="text-light d-block text-right"
            >
              {{ datainterchangeformats }}
              <i class="fa fa-star" style="color: yellow"></i
            ></a>
            <a href="/letslearn/datainterchangeformats" class="text-light read-more"
              >Read More <i class="mdi mdi-chevron-right"></i
            ></a>
          </div>
        </div>
          <div class="content p-3">
            <small class="text-muted p float-right">22th Apr, 22</small>
            <!--<small
                ><a href="javascript:void(0)" class="text-primary"
                  >Marketing</a
                ></small
              >-->
            <h4 class="mt-2">
              <a class="text-dark title" href="/letslearn/datainterchangeformats"
                >Data Interchange Formats</a
              >
            </h4>
            <p class="text-muted mt-2">
              Whenever we have a sort of communication between either two systems or applications we need to create a way to exchange data between each party.          </div>
        </div>
      </div>
      <!--#endregion-->
  
    <!--#region value & reference type card -->
    <div class="col-lg-4 col-md-6 mt-4 pt-2">

      <div class="blog-post rounded border">
          
      <div class="blog-img d-block overflow-hidden position-relative">
        <img
          src="assets/image/Value & Reference types/cover.png"
          class="img-fluid rounded-top img-size"
          alt=""
        />
        <div class="overlay rounded-top bg-dark"></div>

        <div class="post-meta">
          <a
            href="/letslearn/value&referencetypes"
            class="text-light d-block text-right"
          >
            {{ valuereferencecurrentrate }}
            <i class="fa fa-star" style="color: yellow"></i
          ></a>
          <a href="/letslearn/value&referencetypes" class="text-light read-more"
            >Read More <i class="mdi mdi-chevron-right"></i
          ></a>
        </div>
      </div>
        <div class="content p-3">
          <small class="text-muted p float-right">13th Jan, 22</small>
          <!--<small
              ><a href="javascript:void(0)" class="text-primary"
                >Marketing</a
              ></small
            >-->
          <h4 class="mt-2">
            <a class="text-dark title" href="/letslearn/value&referencetypes"
              >Value & Reference Type</a
            >
          </h4>
          <p class="text-muted mt-2">
            In programming, even types such as integer, string, boolean, and others are divided into two categories depending on the way they are saved in memory.        </p>
        </div>
      </div>
    </div>
    <!--#endregion-->

    <!--#region cloud computing card -->
    <div class="col-lg-4 col-md-6 mt-4 pt-2">

      <div class="blog-post rounded border">
        <div class="blog-img d-block overflow-hidden position-relative">
          <img
            src="assets/image/cloud/clouding.png"
            class="img-fluid rounded-top img-size"
            alt=""
          />
          <div class="overlay rounded-top bg-dark"></div>
    
          <div class="post-meta">
            <a
              href="/letslearn/cloudcomputing"
              class="text-light d-block text-right"
            >
              {{ cloudcurrentrate }}
              <i class="fa fa-star" style="color: yellow"></i
            ></a>
            <a href="/letslearn/cloudcomputing" class="text-light read-more"
              >Read More <i class="mdi mdi-chevron-right"></i
            ></a>
          </div>
        </div>
        <div class="content p-3">
          <small class="text-muted p float-right">10th Nov, 21</small>
          <!--<small
              ><a href="javascript:void(0)" class="text-primary"
                >Marketing</a
              ></small
            >-->
          <h4 class="mt-2">
            <a class="text-dark title" href="/letslearn/cloudcomputing"
              >Cloud Computing</a
            >
          </h4>
          <p class="text-muted mt-2">
            "living the problem is the best way to understand the solution"
            And cloud computing is a solution for a big problem, that we are going to explain now...        </p>
        </div>
      </div>
    </div>
    <!--#endregion-->
    
    <!--#region microservices card -->
<div class="col-lg-4 col-md-6 mt-4 pt-2">
  <div class="blog-post rounded border">
    <div class="blog-img d-block overflow-hidden position-relative">
      <img
        src="assets/image/Microservice/MA.jpg"
        class="img-fluid rounded-top img-size"
        alt=""
      />
      <div class="overlay rounded-top bg-dark"></div>

      <div class="post-meta">
        <a
          href="/letslearn/microservices"
          class="text-light d-block text-right"
        >
          {{ microserviceRate }}
          <i class="fa fa-star" style="color: yellow"></i
        ></a>
        <a href="/letslearn/microservices" class="text-light read-more"
          >Read More <i class="mdi mdi-chevron-right"></i
        ></a>
      </div>
    </div>
    <div class="content p-3">
      <small class="text-muted p float-right">9th Oct, 21</small>
      <!--<small
          ><a href="javascript:void(0)" class="text-primary"
            >Marketing</a
          ></small
        >-->
      <h4 class="mt-2">
        <a class="text-dark title" href="/letslearn/microservices"
          >Microservices</a
        >
      </h4>
      <p class="text-muted mt-2">
        Recently we talked about APIs, what they are, how they work, and when we need them.In fact, if we work on a project that uses only one single API we call it ...
      </p>
    </div>
  </div>
</div>
<!--#endregion-->

    <!--#region api card -->
<div class="col-lg-4 col-md-6 mt-4 pt-2">
  <div class="blog-post rounded border">
    <div class="blog-img d-block overflow-hidden position-relative">
      <img
        src="assets/image/api/apicover.jpg"
        class="img-fluid rounded-top img-size"
        alt=""
      />
      <div class="overlay rounded-top bg-dark"></div>

      <div class="post-meta">
        <a
          href="/letslearn/api"
          class="text-light d-block text-right"
        >
          {{ apicurrentRate }}
          <i class="fa fa-star" style="color: yellow"></i
        ></a>
        <a href="/letslearn/api" class="text-light read-more"
          >Read More <i class="mdi mdi-chevron-right"></i
        ></a>
      </div>
    </div>
    <div class="content p-3">
      <small class="text-muted p float-right">20th Sep, 21</small>
      <!--<small
          ><a href="javascript:void(0)" class="text-primary"
            >Marketing</a
          ></small
        >-->
      <h4 class="mt-2">
        <a class="text-dark title" href="/letslearn/api"
          >APIs</a
        >
      </h4>
      <p class="text-muted mt-2">
        When we want to extract money from the bank, we don't directly get it, we do it either through the distributor or a bank teller... 
      </p>
    </div>
  </div>
</div>
<!--#endregion-->

    <!--#region cache card -->
<div class="col-lg-4 col-md-6 mt-4 pt-2">
  <div class="blog-post rounded border">
    <div class="blog-img d-block overflow-hidden position-relative">
      <img
        src="assets/image/cache/cachecover.jpg"
        class="img-fluid rounded-top img-size"
        alt=""
      />
      <div class="overlay rounded-top bg-dark"></div>

      <div class="post-meta">
        <a
          href="/letslearn/cache"
          class="text-light d-block text-right"
        >
          {{ cachingcurrentRate }}
          <i class="fa fa-star" style="color: yellow"></i
        ></a>
        <a href="/letslearn/cache" class="text-light read-more"
          >Read More <i class="mdi mdi-chevron-right"></i
        ></a>
      </div>
    </div>
    <div class="content p-3">
      <small class="text-muted p float-right">6th Sep, 21</small>
      <!--<small
          ><a href="javascript:void(0)" class="text-primary"
            >Marketing</a
          ></small
        >-->
      <h4 class="mt-2">
        <a class="text-dark title" href="/letslearn/cache"
          >Caching</a
        >
      </h4>
      <p class="text-muted mt-2">
        Local storage is so important and we need it a lot when it comes to saving data related to our application in the browser whether for... 
      </p>
    </div>
  </div>
</div>
<!--#endregion-->

    <!--#region jwt card -->
    <div class="col-lg-4 col-md-6 mt-4 pt-2">
      <div class="blog-post rounded border">
        <div class="blog-img d-block overflow-hidden position-relative">
          <img
            src="assets/image/JWT/jwtcover.png"
            class="img-fluid rounded-top img-size"
            alt=""
          />
          <div class="overlay rounded-top bg-dark"></div>

          <div class="post-meta">
            <a
              href="/letslearn/jsonwebtokens"
              class="text-light d-block text-right"
            >
              {{ jwtcurrentRate }}
              <i class="fa fa-star" style="color: yellow"></i
            ></a>
            <a href="/letslearn/jsonwebtokens" class="text-light read-more"
              >Read More <i class="mdi mdi-chevron-right"></i
            ></a>
          </div>
        </div>
        <div class="content p-3">
          <small class="text-muted p float-right">23th Aug, 21</small>
          <!--<small
              ><a href="javascript:void(0)" class="text-primary"
                >Marketing</a
              ></small
            >-->
          <h4 class="mt-2">
            <a class="text-dark title" href="/letslearn/jsonwebtokens"
              >JWT Json Web Tokens</a
            >
          </h4>
          <p class="text-muted mt-2">
            Privacy has always been an important factor that we shouldn't mess
            with. But the thing is, when you use a website, game or any kind
            of...
          </p>
        </div>
      </div>
    </div>
    <!--#endregion-->

    <!--#region solid card-->

    <div class="col-lg-4 col-md-6 mt-4 pt-2">
      <div class="blog-post rounded border">
        <div class="blog-img d-block overflow-hidden position-relative">
          <img
            src="assets/image/solid.jpg"
            class="img-fluid rounded-top img-size"
            alt=""
          />
          <div class="overlay rounded-top bg-dark"></div>
          <!--#region on hover card-->
          <div class="post-meta">
            <a
              href="/letslearn/designprinciples&patterns"
              class="text-light d-block text-right"
            >
              {{ solidcurrentRate }}
              <i class="fa fa-star" style="color: yellow"></i
            ></a>
            <a
              href="/letslearn/designprinciples&patterns"
              class="text-light read-more"
              >Read More <i class="mdi mdi-chevron-right"></i
            ></a>
          </div>
          <!--#endregion-->
        </div>
        <div class="content p-3">
          <small class="text-muted p float-right">16th Aug, 21</small>
          <!--<small
            ><a href="javascript:void(0)" class="text-primary"
              >Marketing</a
            ></small
          >-->
          <h4 class="mt-2">
            <a
              class="text-dark title"
              href="/letslearn/designprinciples&patterns"
              >S.O.L.I.D Design Principles</a
            >
          </h4>
          <p class="text-muted mt-2">
            Design principle are a set of rules made by some software engineers
            giving us a general view on how the structure of our code should
            be...
          </p>
        </div>
      </div>
      <!--end blog post-->
    </div>
    <!---#endregion-->
  </div>
</div>
</section>
<!---#endregion-->

