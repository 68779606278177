<!--#region Cover-->

<div class="parallax bg1">
    <br><br>
    <h1 class="mb-3" style="color: #011C40;">Visit  our Pages</h1>
        <div class="cols  shadow-1-strong  p-5">
    
            
                  <div class="col-md-3 " style="padding-right: 10px; padding-bottom: 10px;" ontouchstart="this.classList.toggle('hover');">
                      <div class="container">
                          <div class="front" style="background-image: url(assets/image/HomePage/fb.jpg)">
                              <div class="inner">
                                  <p >Facebook</p>
                    <span>Way To Learn</span>
                              </div>
                          </div>
                          <div class="back">
                              <div class="inner">
                                <p>Our Facebook page will provide you with the latest updates and new content.</p>
                                <a target="blank" href="https://www.facebook.com/waytolearnn/" class="btn btn-outline-dark btn-sm">&nbsp;Visit &nbsp; </a>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-3 " style="padding-right: 10px;padding-bottom: 10px;" ontouchstart="this.classList.toggle('hover');">
                    <div class="container">
                        <div class="front" style="background-image: url(assets/image/HomePage/yb.jpg)">
                            <div class="inner">
                                <p>Youtube</p>
                  <span>Way To Learn</span>
                            </div>
                        </div>
                        <div class="back">
                            <div class="inner">
                                <p>You can find all our videos and tutorials in our Youtube chanel.</p>
                                <a target="blank" href="https://www.youtube.com/channel/UCkSGRd7CgyjMvy8qSc0u-tg" class="btn btn-outline-dark btn-sm">&nbsp;Visit &nbsp; </a>
                            </div>
                        </div>
                    </div>
        
                  </div>
                  <div class="col-md-3 " style="padding-right: 10px;padding-bottom: 10px;" ontouchstart="this.classList.toggle('hover');">
                      <div class="container">
                          <div class="front" style="background-image: url(assets/image/HomePage/in.jpg)">
                              <div class="inner">
                                  <p>Instagram</p>
                    <span>@waytolearnn</span>
                              </div>
                          </div>
                          <div class="back">
                              <div class="inner">
                                  <p>Follow us on Instagram to share with you some wonderfull tips.</p>
                                  <a target="blank" href="https://www.instagram.com/waytolearnn/" class="btn btn-outline-dark btn-sm">&nbsp;Visit &nbsp; </a>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-3 " style="padding-right: 10px;padding-bottom: 10px;" ontouchstart="this.classList.toggle('hover');">
                      <div class="container">
                          <div class="front" style="background-image: url(assets/image/HomePage/ln.jpg)">
                              <div class="inner">
                                  <p>LinkedIn</p>
                    <span>Way To Learn</span>
                              </div>
                          </div>
                          <div class="back">
                              <div class="inner">
                                  <p>Follow us on LinkedIn to know more about our future updates.</p>
                                  <a target="blank" href="https://www.linkedin.com/company/way-to-learn" class="btn btn-outline-dark btn-sm">&nbsp;Visit &nbsp; </a>
                              </div>
                          </div>
                      </div>
    
                  </div>
    
</div>

<!--#endregion-->

<!--#region Social Maedia-->
<section class=" bg0">
    <br><br>
    <h1 class="mb-3" style="color: #011C40;">Our Social Media Content</h1>
    <div class="container" >
        <!--#region Switch languages-->
        <div class="jumbotron ">
            <div class="df-switch">
              <button type="button" class="btn btn-lg btn-toggle" data-toggle="button" aria-pressed="false" autocomplete="off" (click)="onChange()">
                <div class="inner-handle"></div>
                <div class="handle"></div>
              </button>
            </div>
          </div>
           <!--#endregion-->
        
        <!--#region English-->
    <div *ngIf="english == true">
        <div class="row pt-5 m-auto" >
          <div class="col-md-3 pb-3">
            <div class="card card-custom bg-white border-white border-0">
              <div class="card-custom-img" style="background-image: url(https://media-exp1.licdn.com/dms/image/C4E22AQGLjQD3l3VLew/feedshare-shrink_2048_1536/0/1645292434160?e=1648080000&v=beta&t=HJl5BviYe45C0F4BGyTl3dZI0qP9xHlZ5v13SoH_dcU);"></div>
              <div class="card-custom-avatar">
                <img class="img-fluid" src="assets/icons/linkedinhome.png" alt="Avatar" />
              </div>
              <div class="card-body" style="overflow-y: auto" >
                <h4 class="card-title">Value&Reference Type</h4>
                <p class="card-text" >Did you ever wonder where all the variables we create in our code are saved?<br>Typically we would think of memory, but do you really think it's as simple as this?</p>
              </div>
              <div class="card-footer" style="background: inherit; border-color: inherit;">
                <a target="blank" href="https://www.linkedin.com/posts/way-to-learn_waytolearn-blog-it-activity-6900856643068727296-Phfa" class="btn btn-outline-primary">Learn more</a>
              </div>
            </div>
          </div>
          <div class="col-md-3 pb-3">
            <div class="card card-custom bg-white border-white border-0">
              <div class="card-custom-img" style="background-image: url(https://media-exp1.licdn.com/dms/image/C4E22AQFiyJJMK1QpZw/feedshare-shrink_2048_1536/0/1642583225360?e=1648080000&v=beta&t=oZLw0s84p5XIg9Ow2r-7z0eU1lA3HzA1eayYg7h7k6g);"></div>
              <div class="card-custom-avatar">
                <img class="img-fluid" src="assets/icons/linkedinhome.png" alt="Avatar" />
              </div>
              <div class="card-body"style="overflow-y: auto" >
                <h4 class="card-title">Microservice Architecture</h4>
                <p class="card-text">How to build a correct Microservice Architecture?<br>
                    Most of the time when you start working on a Microservice Architecture you need to regroup all the functionalities depending on their type, role, common tasks, etc.<br>
                    That's why it has to be built upon a set of design principles, and the choice depends on the situation you are in...</p>
              </div>
              <div class="card-footer" style="background: inherit; border-color: inherit;">
                
                <a target="blank" href="https://www.linkedin.com/posts/way-to-learn_waytolearn-blog-it-activity-6889493402598064128-XYpI" class="btn btn-outline-primary">Learn more</a>
              </div>
            </div>
          </div>
          <div class="col-md-3 pb-3">
            <div class="card card-custom bg-white border-white border-0">
              <div class="card-custom-img" style="background-image: url(https://media-exp1.licdn.com/dms/image/C4E22AQHMEUBsVflDGg/feedshare-shrink_2048_1536/0/1642274378029?e=1648080000&v=beta&t=UXcRKSK_6YL6NNw1_hcAYOG9pMIloPlIaFhQybCVel4);"></div>
              <div class="card-custom-avatar">
                <img class="img-fluid" src="assets/icons/linkedinhome.png" alt="Avatar" />
              </div>
              <div class="card-body"style="overflow-y: auto" >
                <h4 class="card-title">HTTP<br> Requests</h4>
                <p class="card-text">How do we communicate with our application?<br>
                    We speak so we can communicate with other humans, but what to do when communicating with an application?<br>
                    In simple terms, every time the user interacts with the graphic interface a request will be sent depending on the action he took, let's understand it with a simple example, to read more about it</p>
              </div>
              <div class="card-footer" style="background: inherit; border-color: inherit;">
                
                <a target="blank" href="https://www.linkedin.com/posts/way-to-learn_waytolearn-blog-it-activity-6888197996895371266-bCtC" class="btn btn-outline-primary">Learn more</a>
              </div>
            </div>
          </div>
          <div class="col-md-3 pb-3">
            <div class="card card-custom bg-white border-white border-0">
              <div class="card-custom-img" style="background-image: url(https://media-exp1.licdn.com/dms/image/C4D22AQG64qKSOXs7XQ/feedshare-shrink_2048_1536/0/1641542066551?e=1648080000&v=beta&t=8ZYGAQzj2NtAYzDXUewwasZup0lS_Hl21AJ66Ncu5wk);"></div>
              <div class="card-custom-avatar">
                <img class="img-fluid" src="assets/icons/linkedinhome.png" alt="Avatar" />
              </div>
              <div class="card-body"style="overflow-y: auto" >
                <h4 class="card-title">Caching and Local Storage<br></h4>
                <p class="card-text">
                    Have you ever wondered what is the main difference between Cache and Cookies?<br>
                    With caching we can store frequently used data that will be saved temporarily on the local hard disk so we can easily retrieve it anytime we need it, so what can we do thanks to Cookies?</p>
              </div>
              <div class="card-footer" style="background: inherit; border-color: inherit;">
                
                <a target="blank" href="https://www.linkedin.com/posts/way-to-learn_waytolearn-blog-it-activity-6885126460567240704-E-eO" class="btn btn-outline-primary">Learn more</a>
              </div>
            </div>
          
         </div> 
        </div>
        <div class="row pt-5 m-auto" *ngIf="english == true">
            <div class="col-md-3 pb-3">
              <div class="card card-custom bg-white border-white border-0">
                <div class="card-custom-img" style="background-image: url(https://media-exp1.licdn.com/dms/image/C4D22AQET7uJAAVrEJw/feedshare-shrink_2048_1536/0/1640691324132?e=1648080000&v=beta&t=U8R5lVLARh9A93n1EKJnhraYp3XG8smQE1hxW8K5WWQ);"></div>
                <div class="card-custom-avatar">
                  <img class="img-fluid" src="assets/icons/linkedinhome.png" alt="Avatar" />
                </div>
                <div class="card-body" style="overflow-y: auto" >
                  <h4 class="card-title">Virtualisation Concept</h4>
                  <p class="card-text" >
                    Virtualisation isn't as complicated as you might think...<br>
                    It's the process of creating multiple partitions from one single machine.<br>
                    there is software that we install in our computer, this later will split our application and operating system layers into small computers called virtual machines (VM)...</p>
                </div>
                <div class="card-footer" style="background: inherit; border-color: inherit;">
                  <a target="blank" href="https://www.linkedin.com/posts/way-to-learn_waytolearn-blog-it-activity-6881558187711971328-dyXba" class="btn btn-outline-primary">Learn more</a>
                </div>
              </div>
            </div>
            <div class="col-md-3 pb-3">
              <div class="card card-custom bg-white border-white border-0">
                <div class="card-custom-img" style="background-image: url(https://media-exp1.licdn.com/dms/image/C4D22AQGsD6NIisg2dQ/feedshare-shrink_2048_1536/0/1640377401316?e=1648080000&v=beta&t=wPb6frrLfoVdnUpQKwLiVG7MbhuF5V8nNTk7xVfzGOc);"></div>
                <div class="card-custom-avatar">
                  <img class="img-fluid" src="assets/icons/linkedinhome.png" alt="Avatar" />
                </div>
                <div class="card-body"style="overflow-y: auto" >
                  <h4 class="card-title">SOLID Design Principles</h4>
                  <p class="card-text">Build it right with SOLID!<br>
                    There may come a time when you start coding and notice that your code is getting messy or your application's performance is getting worse.<br>
                    There is a solution to all these problems which is in our case the topic of this article: Design principle</p>
                </div>
                <div class="card-footer" style="background: inherit; border-color: inherit;">
                  
                  <a target="blank" href="https://www.linkedin.com/posts/way-to-learn_waytolearn-blog-it-activity-6880241499456458752-JBZf" class="btn btn-outline-primary">Learn more</a>
                </div>
              </div>
            </div>
        </div>
    </div>
        <!--#endregion-->
    
        <!--#region Tunisian-->
        <div *ngIf="tunisian == true">
          <div class="row pt-5 m-auto" >

              <div class="col-md-3 pb-3">
                <div class="card card-custom bg-white border-white border-0">
                  <div class="card-custom-img" style="background-image: url(https://scontent.ftun16-1.fna.fbcdn.net/v/t39.30808-6/273988203_125611203324220_8346623375479487314_n.png?_nc_cat=105&ccb=1-5&_nc_sid=730e14&_nc_ohc=-1pvshPfMgMAX8L3-Jg&_nc_ht=scontent.ftun16-1.fna&oh=00_AT-FokI1fChc7TYr0Dq7g6j-IfzyR6OXvZZjm4EVRk_zgg&oe=6216D254);"></div>
                  <div class="card-custom-avatar">
                    <img class="img-fluid" src="assets/icons/facebookhome.png" alt="Avatar" />
                  </div>
                  <div class="card-body" style="overflow-y: auto" >
                    <h4 class="card-title">All you need to knwo about us</h4>
                    <p class="card-text"style="direction: rtl; text-align: right" >
                        كلsite و كلprojet تصنع عندوا قصتو الي تميزو على غيرو.<br>
                        و Way To Learn زادا بدا بحكاية، طموح، و إرادة قوية باش نوصلوا لهدف ما وصلّوا حتى حد قبل
                        الsite الي باش يخليك تقرأ على الي حاجتك بيه في اقصر وقة.<br>

                        الي يخليلك الارادة باش تقرأ الموضوع باللغة الي ترتحك.<br>
                        
                        هاكا علاش Way to learn توجد باش يخليك تتعلم اسرع، تتطور اكثر، تفهم اوضح، و توفر الوقة.
                    </p>
                  </div>
                  <div class="card-footer" style="background: inherit; border-color: inherit;">
                    <a target="blank" href="https://www.facebook.com/waytolearnn/photos/a.113460711205936/125611206657553" class="btn btn-outline-primary">Learn more</a>
                  </div>
                </div>
              </div>
              <div class="col-md-3 pb-3">
                <div class="card card-custom bg-white border-white border-0">
                  <div class="card-custom-img" style="background-image: url(https://scontent.ftun16-1.fna.fbcdn.net/v/t39.30808-6/273936771_125319996686674_4989254706757526092_n.jpg?_nc_cat=101&ccb=1-5&_nc_sid=8bfeb9&_nc_ohc=JMZ3jXGMw8wAX-8zrfO&_nc_ht=scontent.ftun16-1.fna&oh=00_AT9yqopF4Sa9VevE21oHY3gc2DphCDNT7o7P9fFwlP5Eng&oe=6216A9F3);"></div>
                  <div class="card-custom-avatar">
                    <img class="img-fluid" src="assets/icons/facebookhome.png" alt="Avatar" />
                  </div>
                  <div class="card-body" style="overflow-y: auto" >
                    <h4 class="card-title">Value&Reference Type</h4>
                    <p class="card-text"style="direction: rtl; text-align: right" >-عمرك ما تسائلت على الvariable الي نستعملوها في الcode متاعنا وين تتسجل؟
                        -تي ماهو في mémoire!
                        -هاذي الفكرة الي عند الناس الكل.تبعني توا تعرف كيفاش, وين, و علاه</p>
                  </div>
                  <div class="card-footer" style="background: inherit; border-color: inherit;">
                    <a target="blank" href="https://www.facebook.com/waytolearnn/photos/a.104560468762627/125319953353345" class="btn btn-outline-primary">Learn more</a>
                  </div>
                </div>
              </div>
              <div class="col-md-3 pb-3">
                <div class="card card-custom bg-white border-white border-0">
                  <div class="card-custom-img" style="background-image: url(https://scontent.ftun16-1.fna.fbcdn.net/v/t39.30808-6/270226722_110378301514177_5050170458501214793_n.jpg?_nc_cat=103&ccb=1-5&_nc_sid=8bfeb9&_nc_ohc=w1gxei7S0PwAX8ONMD3&_nc_ht=scontent.ftun16-1.fna&oh=00_AT-k1DHD9u2n7CdpIDOdjR8-B7qCrXL1vw0FOEEkAWytZg&oe=62167A84);"></div>
                  <div class="card-custom-avatar">
                    <img class="img-fluid" src="assets/icons/facebookhome.png" alt="Avatar" />
                  </div>
                  <div class="card-body" style="overflow-y: auto" >
                    <h4 class="card-title">Virtualisation Concept</h4>
                    <p class="card-text"style="direction: rtl;  text-align: right" >الVirtualization ماهاش صعيبة كيف ما تتصور...<br>
                        هي عملية باش تخلينا، منmachine وحدة، نصنعوا برشىpartitions اخرينة٠
                        الكل بفضل software يتصبفي الmachine، و هو الي باش يقسملنا الdevice لبرشى virtual machines (VM)...</p>
                  </div>
                  <div class="card-footer" style="background: inherit; border-color: inherit;">
                    <a target="blank" href="https://www.facebook.com/waytolearnn/photos/a.104560468762627/110378144847526" class="btn btn-outline-primary">Learn more</a>
                  </div>
                </div>
              </div>
              <div class="col-md-3 pb-3">
                <div class="card card-custom bg-white border-white border-0">
                  <div class="card-custom-img" style="background-image: url(https://scontent.ftun16-1.fna.fbcdn.net/v/t39.30808-6/269914574_107366061815401_4217805307904554602_n.jpg?_nc_cat=102&ccb=1-5&_nc_sid=8bfeb9&_nc_ohc=cw5VrmFIg4wAX_du85X&_nc_ht=scontent.ftun16-1.fna&oh=00_AT8HZaj_RSdE4cVWDXnxk0P9p6kT-mgpKjKl7HcB9FGGZA&oe=62172B79);"></div>
                  <div class="card-custom-avatar">
                    <img class="img-fluid" src="assets/icons/facebookhome.png" alt="Avatar" />
                  </div>
                  <div class="card-body" style="overflow-y: auto" >
                    <h4 class="card-title">Solid Design Principles</h4>
                    <p class="card-text"style="direction: rtl; text-align: right" >كيف الcode متاعنا يكبر مع الوقت، نوصلوا نستحقوا لتعليمات و نصايح تخلي الapplication متاعنا ديما performante و هوني يجي الSOLID الي يشمل 5 principes نطبقوهم في خدمتنا
                        S: Single Responsibility Principle
                        O: Open-Closed Principle
                        L: Liskov Substitution Principle
                        I: Interface Segregation Principle
                        D: Dependency Inversion Principle
                        </p>
                  </div>
                  <div class="card-footer" style="background: inherit; border-color: inherit;">
                    <a target="blank" href="https://www.facebook.com/waytolearnn/photos/a.104560468762627/1073658984820845" class="btn btn-outline-primary">Learn more</a>
                  </div>
                </div>
              </div>
          </div>
          <div class="row pt-5 m-auto" >
            <div class="col-md-3 pb-3">
                <div class="card card-custom bg-white border-white border-0">
                  <div class="card-custom-img" style="background-image: url(https://scontent.ftun16-1.fna.fbcdn.net/v/t39.30808-6/274356344_126411443244196_7427234011543680711_n.jpg?_nc_cat=104&ccb=1-5&_nc_sid=8bfeb9&_nc_ohc=GUVVljsA5fYAX8YxIYn&_nc_ht=scontent.ftun16-1.fna&oh=00_AT-_EC-WEVJxx9Iv370FpdxRFFBsg8PaUo96bIecbhW7DA&oe=6216602C);"></div>
                  <div class="card-custom-avatar">
                    <img class="img-fluid" src="assets/icons/facebookhome.png" alt="Avatar" />
                  </div>
                  <div class="card-body" style="overflow-y: auto" >
                    <h4 class="card-title">HTTP Status Code</h4>
                    <p class="card-text"style="direction: rtl;  text-align: right" >وقة تبدا تكودي، ينجموا يعرضوك الارقام هاذوما ديما.
                        اسمهم HTTP Status Code.
                        و هوما عبارة على الresponse الي تجينا من عند الserveur وقة الي نبعثولوا request باش نعملوا حاجة معينة.
                        الرموز هاذي المتمثلة من 3 ارقام مقسمة ل5 classes ولا types على حسب اول digit تبدا بيه.</p>
                  </div>
                  <div class="card-footer" style="background: inherit; border-color: inherit;">
                    <a target="blank" href="https://www.facebook.com/waytolearnn/photos/a.104560468762627/126411373244203" class="btn btn-outline-primary">Learn more</a>
                  </div>
                </div>
              </div>
            <div class="col-md-3 pb-3">
                <div class="card card-custom bg-white border-white border-0">
                  <div class="card-custom-img" style="background-image: url(https://scontent.ftun16-1.fna.fbcdn.net/v/t39.30808-6/272743240_119676807250993_8132086457918941908_n.png?_nc_cat=111&ccb=1-5&_nc_sid=730e14&_nc_ohc=W8oQmS7I3EIAX-ZZAoN&tn=U2NeXCnLlXRJUS2G&_nc_ht=scontent.ftun16-1.fna&oh=00_AT8VMHHjSOeJ_W18EnKYPLC47-yA1vbO3ANcjcpc4ejFaQ&oe=62172253);"></div>
                  <div class="card-custom-avatar">
                    <img class="img-fluid" src="assets/icons/facebookhome.png" alt="Avatar" />
                  </div>
                  <div class="card-body" style="overflow-y: auto" >
                    <h4 class="card-title">SRP Design Principle</h4>
                    <p class="card-text"style="direction: rtl;  text-align: right" >ال SRP (Single responsibility Principle) يقوللنا
                        “a class should have only one reason to change”,
                        يعني كل class يلزمها تكون مسؤولة على خدمة وحدة لاكثر لا اقل...</p>
                  </div>
                  <div class="card-footer" style="background: inherit; border-color: inherit;">
                    <a target="blank" href="https://www.facebook.com/waytolearnn/photos/a.113460711205936/119676810584326" class="btn btn-outline-primary">Learn more</a>
                  </div>
                </div>
              </div>
              <div class="col-md-3 pb-3">
                <div class="card card-custom bg-white border-white border-0">
                  <div class="card-custom-img" style="background-image: url(https://scontent.ftun16-1.fna.fbcdn.net/v/t39.30808-6/271241464_113458871206120_6718556229326507968_n.png?_nc_cat=100&ccb=1-5&_nc_sid=730e14&_nc_ohc=9hTA_Kyt0ykAX_suJSq&_nc_ht=scontent.ftun16-1.fna&oh=00_AT-W_GB1HTs9cBaX1hBS2wNvtrkNS-Wv8sEeawY2KjfRQA&oe=6217A647);"></div>
                  <div class="card-custom-avatar">
                    <img class="img-fluid" src="assets/icons/facebookhome.png" alt="Avatar" />
                  </div>
                  <div class="card-body" style="overflow-y: auto" >
                    <h4 class="card-title">Caching and Local Storage</h4>
                    <p class="card-text"style="direction: rtl;  text-align: right" >عمرك ما تساءلت على الفرق مابين الCookies و الCache؟<br>
                        بالCaching نجموا نستحفظوا مؤقتا على معلومات إلي ديما نستحقولها, مالا بالCookies شنوا نجموا نعملوا زعما؟</p>
                  </div>
                  <div class="card-footer" style="background: inherit; border-color: inherit;">
                    <a target="blank" href="https://www.facebook.com/waytolearnn/photos/a.113460711205936/113458874539453/" class="btn btn-outline-primary">Learn more</a>
                  </div>
                </div>
              </div>
              <div class="col-md-3 pb-3">
                <div class="card card-custom bg-white border-white border-0">
                  <div class="card-custom-img" style="background-image: url(https://scontent.ftun16-1.fna.fbcdn.net/v/t39.30808-6/271597970_114215817797092_6522169582511099249_n.jpg?_nc_cat=105&ccb=1-5&_nc_sid=730e14&_nc_ohc=EyQLXCg40sAAX8rUyGj&_nc_ht=scontent.ftun16-1.fna&oh=00_AT8s9_A9GrDniv15WfMkTDFhGaN9B2iuQTZZFpSMsy19sQ&oe=6216D5E7);"></div>
                  <div class="card-custom-avatar">
                    <img class="img-fluid" src="assets/icons/facebookhome.png" alt="Avatar" />
                  </div>
                  <div class="card-body" style="overflow-y: auto" >
                    <h4 class="card-title">HTTP <br>Requests</h4>
                    <p class="card-text"style="direction: rtl; text-align: right" >
                        كيفاش يلزمني نحكي باش الapplication متاعي تفهمني؟<br>

كل ما الuser يعمل action, تتصنع request و تتبعث. هاة نبصطوها شوية في example صغير, الي باش تلقاوه فالlien هاذا
                        </p>
                  </div>
                  <div class="card-footer" style="background: inherit; border-color: inherit;">
                    <a target="blank" href="https://www.facebook.com/waytolearnn/photos/a.113460711205936/114215821130425/" class="btn btn-outline-primary">Learn more</a>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <!--#endregion-->
    </div>   
<br><br>
</section>
  
<!--#endregion"-->

<!--#region videos-->

<div class="parallax bg1">
    <br><br>
    <h1 class="mb-3" style="color: #011C40;">Our Videos</h1>
    <div class="container" >
        <div class="row pt-5 m-auto" >
             <div class="col-md-4 pb-3">
              <div class="card card-custom bg-white border-white border-0">
                <div class="card-custom-img" >
                    <div class="widget widget-latest-post ">
                        <div class="widget-body">
                          <div class="embed-responsive embed-responsive-21by9">
                            <h5>Video loading...</h5>
                            <youtube-player
                              videoId="ZlRFJnU0r2Q"
                              suggestedQuality="highres"
                              style="width: 100%"
                              id="youtube"
                            >
                            </youtube-player>
                          </div>
                        </div>
                      </div>
                </div>
                <div class="card-custom-avatar">
                  <img class="img-fluid" src="assets/icons/youtubehome.png" alt="Avatar" />
                </div>
                <div class="card-body" style="overflow-y: auto" >
                  <h4 class="card-title">Microservice Architecture</h4>
                </div>
              </div>
             </div>
             <div class="col-md-4 pb-3">
                <div class="card card-custom bg-white border-white border-0">
                  <div class="card-custom-img" >
                      <div class="widget widget-latest-post ">
                          <div class="widget-body">
                            <div class="embed-responsive embed-responsive-21by9">
                              <h5>Video loading...</h5>
                              <youtube-player
                                videoId="yXbu9H0dUDY"
                                suggestedQuality="highres"
                                style="width: 100%"
                                id="youtube"
                              >
                              </youtube-player>
                            </div>
                          </div>
                        </div>
                  </div>
                  <div class="card-custom-avatar">
                    <img class="img-fluid" src="assets/icons/youtubehome.png" alt="Avatar" />
                  </div>
                  <div class="card-body" style="overflow-y: auto" >
                    <h4 class="card-title">Cloud Computing</h4>
                  </div>
                </div>
              </div>
              <div class="col-md-4 pb-3">
                <div class="card card-custom bg-white border-white border-0">
                  <div class="card-custom-img" >
                      <div class="widget widget-latest-post ">
                          <div class="widget-body">
                            <div class="embed-responsive embed-responsive-21by9">
                              <h5>Video loading...</h5>
                              <youtube-player
                                videoId="_ljNhEA4fp4"
                                suggestedQuality="highres"
                                style="width: 100%"
                                id="youtube"
                              >
                              </youtube-player>
                            </div>
                          </div>
                        </div>
                  </div>
                  <div class="card-custom-avatar">
                    <img class="img-fluid" src="assets/icons/youtubehome.png" alt="Avatar" />
                  </div>
                  <div class="card-body" style="overflow-y: auto" >
                    <h4 class="card-title">Caching</h4>
                  </div>
                </div>
              </div>
        </div> 
        <div class="row pt-5 m-auto" > 
              <div class="col-md-4 pb-3">
                <div class="card card-custom bg-white border-white border-0">
                  <div class="card-custom-img" >
                      <div class="widget widget-latest-post ">
                          <div class="widget-body">
                            <div class="embed-responsive embed-responsive-21by9">
                              <h5>Video loading...</h5>
                              <youtube-player
                                videoId="GtgVgtRMsyc"
                                suggestedQuality="highres"
                                style="width: 100%"
                                id="youtube"
                              >
                              </youtube-player>
                            </div>
                          </div>
                        </div>
                  </div>
                  <div class="card-custom-avatar">
                    <img class="img-fluid" src="assets/icons/youtubehome.png" alt="Avatar" />
                  </div>
                  <div class="card-body" style="overflow-y: auto" >
                    <h4 class="card-title">SOLID Design Principle</h4>
                  </div>
                </div>
              </div>
              <div class="col-md-4 pb-3">
                  <div class="card card-custom bg-white border-white border-0">
                    <div class="card-custom-img" >
                        <div class="widget widget-latest-post ">
                            <div class="widget-body">
                              <div class="embed-responsive embed-responsive-21by9">
                                <h5>Video loading...</h5>
                                <youtube-player
                                  videoId="nkGNWuW_B7c"
                                  suggestedQuality="highres"
                                  style="width: 100%"
                                  id="youtube"
                                >
                                </youtube-player>
                              </div>
                            </div>
                          </div>
                    </div>
                    <div class="card-custom-avatar">
                      <img class="img-fluid" src="assets/icons/youtubehome.png" alt="Avatar" />
                    </div>
                    <div class="card-body" style="overflow-y: auto" >
                      <h4 class="card-title">Value& Reference Type</h4>
                    </div>
                  </div>
              </div>
              <div class="col-md-4 pb-3">
                  <div class="card card-custom bg-white border-white border-0">
                    <div class="card-custom-img" >
                        <div class="widget widget-latest-post ">
                            <div class="widget-body">
                              <div class="embed-responsive embed-responsive-21by9">
                                <h5>Video loading...</h5>
                                <youtube-player
                                  videoId="OjOgezW3OKA"
                                  suggestedQuality="highres"
                                  style="width: 100%"
                                  id="youtube"
                                >
                                </youtube-player>
                              </div>
                            </div>
                          </div>
                    </div>
                    <div class="card-custom-avatar">
                      <img class="img-fluid" src="assets/icons/youtubehome.png" alt="Avatar" />
                    </div>
                    <div class="card-body" style="overflow-y: auto" >
                      <h4 class="card-title">Json Web Token
                      </h4>
                    </div>
                  </div>
              </div>
        </div>
        <div class="row pt-5 m-auto" >
            <div class="col-md-4 pb-3">
                <div class="card card-custom bg-white border-white border-0">
                  <div class="card-custom-img" >
                      <div class="widget widget-latest-post ">
                          <div class="widget-body">
                            <div class="embed-responsive embed-responsive-21by9">
                              <h5>Video loading...</h5>
                              <youtube-player
                                videoId="egQfAl8FFD4"
                                suggestedQuality="highres"
                                style="width: 100%"
                                id="youtube"
                              >
                              </youtube-player>
                            </div>
                          </div>
                        </div>
                  </div>
                  <div class="card-custom-avatar">
                    <img class="img-fluid" src="assets/icons/youtubehome.png" alt="Avatar" />
                  </div>
                  <div class="card-body" style="overflow-y: auto" >
                    <h4 class="card-title">APIs</h4>
                  </div>
                </div>
              </div> 
        </div>
<br><br>
</div>

<!--#endregion-->
