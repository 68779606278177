<main>
    <article>
    <!--#region content-->
      <div class="container-fluid" >
        <div class="row align-items-start">
          <div class="col article ">
            <div class="article-img align-items-center">
              <img src="assets/image/cache/cachecover.jpg" title="" alt="" />
            </div>
          </div>
        </div>
        <div class="row align-items-start">
          <!--#region Left hand-->
          <div class="col-lg-3 m-15px-tb blog-aside sticky" >
            <div class="">
              <!--#region video -->
  <div class="widget widget-latest-post ">
    <div class="widget-body">
      <div class="embed-responsive embed-responsive-21by9">
        <h5>Video loading...</h5>
        <youtube-player
          videoId="_ljNhEA4fp4"
          suggestedQuality="highres"
          style="width: 100%"
          id="youtube"
        >
        </youtube-player>
      </div>
    </div>
  </div>
  <!--#endregion -->
 
              <!--#region Rate -->
  <div class="widget widget-latest-post">
    <div class="widget-title">
      <div class="row">
        <div class="col-6">
          <h3 style="color: #0396A6;" >Rate</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <p style="font-size: 12px">
            (Current {{ currentRate }} <i class="fa fa-star"></i>)
          </p>
        </div>
      </div>
    </div>
    <div class="widget-body">
      <div class="">
        <div class="rating">
          <!--#region stars-->
          <input
            type="radio"
            name="rating"
            id="rating-5"
            (click)="onRate(5)"
          />
          <label for="rating-5"></label>
          <input
            type="radio"
            name="rating"
            id="rating-4"
            (click)="onRate(4)"
          />
          <label for="rating-4"></label>
          <input
            type="radio"
            name="rating"
            id="rating-3"
            (click)="onRate(3)"
          />
          <label for="rating-3"></label>
          <input
            type="radio"
            name="rating"
            id="rating-2"
            (click)="onRate(2)"
          />
          <label for="rating-2"></label>
          <input
            type="radio"
            name="rating"
            id="rating-1"
            (click)="onRate(1)"
          />
          <label for="rating-1"></label>
          <!--#endregion-->

          <!--#region Emojis-->
          <div class="emoji-wrapper">
            <div class="emoji">
              <!--#region none -->
              <img src="assets/icons/0.png" />
              <!--#endregion-->

              <!--#region 1s -->
              <img src="assets/icons/00.png" />
              <!--#endregion-->

              <!--#region 2s -->
              <img src="assets/icons/2.png" />

              <!--#endregion-->

              <!--#region 3s -->
              <img src="assets/icons/3.png" />
              <!--#endregion-->

              <!--#region 4s -->
              <img src="assets/icons/4.png" />
              <!--#endregion-->

              <!--#region 5s -->
              <img src="assets/icons/5.png" />
              <!--#endregion-->
            </div>
          </div>

          <!--#endregion-->
        </div>

        <button
          type="button"
          class="btn input btn-outline-dark btn-rounded button-wrapper"
          (click)="onSetRate()"
        >
          Submit
        </button>
        <br />
        <div
          class="alert alert-warning"
          *ngIf="!firstRate == false"
          role="alert"
        >
          Be the first to rate this Content!
        </div>
        <div
          class="alert alert-success alert-dismissible fade show"
          role="alert"
          *ngIf="!success == false"
        >
          <strong>Success!</strong> Thank you for rating us!
        </div>
      </div>
    </div>
  </div>
  <!--#endregion -->

              <!--#region knowledge check -->

  <div
    class="
      alert alert-dismissible
      fade
      show
      widget widget-author
      timeline-card
    "
    role="alert"
  >
    <div
      class="media align-items-center"
      (click)="OpenPopUp(check, 'lg')"
    >
      <a role="button" href="javascript:void(0)">
        <h3
          
          style="font-size: 20px; font-weight: 700; margin: 0;color: #0396A6;"
        >
          Check your Knowledge!
        </h3>
      </a>

      <img src="assets/icons/trophy.png" class="follow" />
    </div>

    <button
      type="button"
      class="close"
      data-dismiss="alert"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!--#region follow pop up-->
  <!-- #region pop up screen for solution-->

  <ng-template #check let-modal>
    <div class="modal-header">
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="fa fa-close"></i>
      </button>
    </div>

    <div class="modal-body">
      <div class="row h-100">
        <div class="col-12">
          <div class="align-items-center">
            <img src="assets/image/question.jpg" />
          </div>
          <div
            class="about-section bg-white rounded shadow-sm py-5 px-4"
          >
            <hr />
            <form class="form-group">
              <!--#region Q1-->
              <div class="row">
                <div class="col">
                  <p>1. <u>Cache information are saved in:</u></p>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="q1"
                      id="q1r1"
                      value="Browser only."
                      [(ngModel)]="qestions.q1"
                    />
                    <label class="form-check-label" for="q1r1">
                      Browser only.
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="q1"
                      id="q1r2"
                      value="Server only."
                      checked
                      [(ngModel)]="qestions.q1"
                    />
                    <label class="form-check-label" for="q1r2">
                      Server only.
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="q1"
                      id="q1r3"
                      checked
                      value="Both browser and server."
                      [(ngModel)]="qestions.q1"
                    />
                    <label class="form-check-label" for="q1r3">
                      Both browser and server.
                    </label>
                  </div>
                </div>
              </div>
              <!--#endregion-->
              <hr />
              <!--#region Q2-->
              <div class="row">
                <div class="col">
                  <p>2. <u>The three Caching Techniques are:</u></p>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="q2"
                      id="q2r1"
                      value="Write-Through/Write-Behind/Write-Around."
                      [(ngModel)]="qestions.q2"
                    />
                    <label class="form-check-label" for="q2r1">
                      Write-Through/Write-Behind/Write-Around.
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="q2"
                      id="q2r2"
                      value="Write-Through/Write-Behind/Write-forward."
                      checked
                      [(ngModel)]="qestions.q2"
                    />
                    <label class="form-check-label" for="q2r2">
                      Write-Through/Write-Behind/Write-forward.
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="q2"
                      id="q2r3"
                      checked
                      value="Write-Through/Write-Aside/Write-Around."
                      [(ngModel)]="qestions.q2"
                    />
                    <label class="form-check-label" for="q2r3">
                      Write-Through/Write-Aside/Write-Around.
                    </label>
                  </div>
                </div>
              </div>
              <!--#endregion-->
              <hr />
              <!--#region Q3-->
              <div class="row">
                <div class="col">
                  <p>
                    3. <u>With Write-Through Caching:</u>
                  </p>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="q3"
                      id="q3r1"
                      value="Both data in the cache and database are updated at the same time."
                      [(ngModel)]="qestions.q3"
                    />
                    <label class="form-check-label" for="q3r1">
                      Both data in the cache and database are updated at the same time.
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="32"
                      id="q3r2"
                      value="Data in the cache are updated first then the database."
                      checked
                      [(ngModel)]="qestions.q3"
                    />
                    <label class="form-check-label" for="q3r2">
                      Data in the cache are updated first then the database.
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="q3"
                      id="q3r3"
                      checked
                      value="Database values always are updated first."
                      [(ngModel)]="qestions.q3"
                    />
                    <label class="form-check-label" for="q3r3">
                      Database values always are updated first.
                    </label>
                  </div>
                </div>
              </div>
              <!--#endregion-->
            </form>
            <ng-template #check let-modal>
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  (click)="modal.dismiss('Cross click')"
                >
                  <i class="fa fa-close"></i>
                </button>
              </div>

              <div class="modal-body">
                <div class="row h-100">
                  <div class="col-12">
                    <div
                      class="
                        about-section
                        bg-white
                        rounded
                        shadow-sm
                        py-5
                        px-4
                      "
                    >
                      <div class="alert alert-{{ alert }}" role="alert">
                        {{ replay }}
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col">
                          <table class="table">
                            <thead class="thead">
                              <tr scope=" table-success">
                                <th scope="col ">Corract Answers</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                class="table-success"
                                *ngFor="let row of correct"
                              >
                                <td class="table-success">
                                  {{ row }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col">
                          <table class="table">
                            <thead class="thead-danger">
                              <tr scope=" table-danger">
                                <th scope="col table-danger">
                                  Wrong Answers
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                class="table-danger"
                                *ngFor="let wrong of wrong"
                              >
                                <td class="table-danger">
                                  {{ wrong }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <div class="col">
              <button
                type="button"
                class="
                  btn btn-outline-dark btn-rounded
                  button-wrapper
                  input
                "
                (click)="OpenPopUp(check, 'lg')"
                (click)="onCheck()"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- #endregion -->

  <!--#endregion-->
  <!--#endregion -->
          </div>
          </div>
          <!--#endregion-->
          <!--#region Middle hand-->
          <div class="col-lg-6 m-15px-tb">
            <!--#region article-->
            <article class="article">
              <div class="article-title">
                <div class="row">
                  <div class="col-6 col-md-9">
                    <h2>Cache and Local Storage</h2>
                  </div>
                  <div class="col-6 col-md-3">
                    <span (click)="onChange()"
                      ><input
                        type="checkbox"
                        checked
                        data-on="Tunisian"
                        data-off="English"
                        data-toggle="toggle"
                        data-onstyle="info"
                        data-offstyle="secondary"
                    /></span>
                  </div>
                </div>
              </div>
              <hr />
              <!--#region english article-->
              <div
                class="article-content"
                style="font-family: 'Segoe UI'"
                *ngIf="english == true"
              >
                <p>
                    Local storage is so important and we need it a lot when it comes to saving data related to our application in the browser whether for authentication processes or others. 
                    <br>It's a piece of data that persists even if you refresh the page or close the browser.<br>
                    Well, still a lot of people confuse it and session storage, and let me say they are different as session storage data will be deleted as soon as the browser is closed.
                    <br>Let's start with explaining the differences between these two terms 
                  
                <h4>Caching vs Session</h4>
                <h6>Caching</h6>
                <p>
                
                With caching we can store frequently used data that will be saved temporarily on the local hard disk so we can easily retrieve it anytime we need it. 
                <br>Thus, rather than relying on sending requests to the server and database in order to get some data, we can easily cash it and have it stored in our local storage.
                
                </p>
                <br>
                <h6>Session</h6>
                <p>
                
                    A session represents a limited time of communication between the user and the application, as each user has his own session.
                    <br>And concerning session variables, they are containers that will store information related to the user.
                    <br>And all this information won't be stored in the local storage, but on the web server's memory.
                </p>
                <img class="centralized-image" src="assets/image/cache/1.PNG" />
                <br />
                <table class="table">
                  <thead class="thead-light">
                    <tr scope=" table-primary">
                      <th scope="col bg-secondary text-center">Cache</th>
                      <th scope="col ">Session</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Data is stored in client app side (local disk).</td>
                      <td>
                        Data is stored at the server level (web server).

                      </td>
                    </tr>
                    <tr>
                      <td>
                        Will improve site performance.
                      </td>
                      <td>
                       Wont affect the site's performance.
                      </td>
                    </tr>
                   
                  </tbody>
                </table>
                <hr />
                <br />
                <h4>Cookies vs Session</h4>
                <h6>Cookies</h6>
                <p>
                
                    Are pieces of information stored in the client's computer for tracking purposes and future use, for example, name, ID, etc.
                    <br>And each time the client app sends a request from your browser to the web server it sends these cookies along with the request, and the server will use them in order to identify the user.
                </p>
                <br>
              
                <table class="table">
                    <thead class="thead-light">
                      <tr scope=" table-primary">
                        <th scope="col bg-secondary text-center">Cookies</th>
                        <th scope="col ">Session</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Cookies are text files saved for tracking purpose in the client app side.
                        </td>
                        <td>
                         Session data is temporarely saved in the server side.
  
                        </td>
                      </tr>
                      <tr>
                        <td>
                            Server send cookies to the browser.Then the browser stores this information in local storage for future use.

                        </td>
                        <td>
                         When user login he create a session, this later ends when the user closes the browser or after leaving the site, else the server will end the session after a predefined duration.
                        </td>
                      </tr>
                     
                    </tbody>
                  </table>
                  <hr>
                  <br>
                  <h4>Cookies vs Cache</h4>
                  <br>
                  
                <table class="table">
                  <thead class="thead-light">
                    <tr scope=" table-primary">
                      <th scope="col bg-secondary text-center">Cache</th>
                      <th scope="col ">Cookies</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Used to store frequently used data.

                      </td>
                      <td>
                       Used to store data that we may need in specific situation like identifying someone.

                      </td>
                    </tr>
                    <tr>
                      <td>
                          Its cleared manually and you can freely chose the strategy 


                      </td>
                      <td>
                       expires after a periode</td>
                    </tr>
                    <tr>
                      <td>
                         Cache data can only be stored in the browser.


                      </td>
                      <td>
                       Cookies will be stored in both the server and the browser.</td>
                    </tr>
                   
                  </tbody>
                </table>
                <hr>
                <br>
                <h4>How memory caching works?</h4>
                <p>
                  always a partition of the RAM will be used as a cache, and when a request for retrieving data is sent, 
                  before sending a response there is a check that will always happen in the cache to see if it already exist,
                   and in that case, it will be unnecessary to send a request for retrieval purposes, and the application will 
                   read the data from the cache, this operation is called <span style="font-weight: bold;color: red">Cache Hit</span> .</p>
                  
                   <img class="centralized-image" src="assets/image/cache/2.PNG" />
                   <br />
                   <p>
                   And if data won't be found in the cache a request will be sent to the server to retrieve it and after getting it it 
                   will be saved next in the cache, and this operation is called <span style="font-weight: bold;color: red">Cache Miss</span>.</p>
                   <img class="centralized-image" src="assets/image/cache/3.PNG" />
                   <br />
                 
                
                <h4>When we need to cache data and when we don't?</h4>
                <p>
                  -We need cache when we frequently request to get a piece of data
and it won't be necessary for the app to send requests all the time to retrieve them from the database.<br><br>
-Also we don't need to cache data that take as much time to retrieve it from the database as retrieving it from the cache. 
                </p>
                <h4>Caching Techniques</h4>
                <p>
                  A cache contains copies of data, so we always have to know in advance when do we need to copy it and when not to.
This is what caching techniques were created for.<br><br>
 1-<u><b>Write-Through Cache (Synchronous)</b></u>:<br>
It's when we update some data in the cache and we also need it to be updated in the database.
So here both the cache and database will be synchronously updated.</p>
<img class="centralized-image" src="assets/image/cache/4.PNG" />
<br />
<p>
this operation is provided thanks to the IwriteThruProvider interface that will provide us the ability to update data in the database along with cache changes.<br><br>
2-<u><b>Write-Behind Cache (asynchronous)</b></u>:
<br>similar to write-through, but instead of updating data at the same time in the cache and database, the cache will be updated first then the database.<br>
Up to this point, in the first technique, all actions happen simultaneously, but in the second technique, data will be updated only after the updated cache line will change.
</p>
<img class="centralized-image" src="assets/image/cache/5.PNG" />
<br />
<p>
3-<u><b>Write-Around</b></u>:<br>

Here the application directly updates the database, and right after the cache check the database to update its values.</p>
<img class="centralized-image" src="assets/image/cache/6.PNG" />
<br />
<h4>Evacuation strategies:</h4>
<p>
  Of course, not only our database is limited in size but also our cache since it's a memory too.<br>
And so it can always have free space for new data some strategies were created to evacuate the cache according to the type of the data.<br><br>
1-<u><b>Least-Recently-used (LRU) Strategy</b></u>:<br>
Here the cache will keep track of the oldest registered data and when it is needed it will delete it and so on.<br><br>
2-<u><b>Least-Frequently-used (LFU) Strategy</b></u>:<br>
Removing data will happen according to those who have the least amount of access.
</p>
                <br />
                <hr />
                <p class="blockquote-footer">
                  Feel free to rate the blog and send me feedback,questions or
                  suggestions through the
                  <span style="color: red; font-weight: bold">comment</span>
                  section below. <br />To be updated for Upcoming content, please
                  <span style="color: red; font-weight: bold">follow</span> this
                  website.
                </p>
              </div>
              <!--#endregion -->
              <!--#region tunisian article-->
              <div
                *ngIf="tunisian == true"
                class="article-content"
                style="direction: rtl; display: inline-block; text-align: right"
              >
              <p>
                الmemoire cache حاجة ياسر مهمة بش نتعرضولها ديما و يلزمنا نفهموها بالباهي.<br>
                نستحقولها باش نجموانستحفظوا بمعلوماتنا الخاصّة في الbrowser باش نجمو نرجعولها منغير ما نطلبوا مالserveur يبعثهالنا مرّة اخرى.<br>
                هي information تبقى موجودة حتى كان عملنا refresh للpage ولّا خرجنا مالbrowser.<br>
                اما باقي برشا يغلطوا مابينها و ما بين ال session storage.
                هات نبداو نفسروا كل وحدة منهم باش نفهموا الفرق.<br>
              </p>
            <h4>Caching vs Session</h4>
            <h6>Caching</h6>
            <p>
            
              بالCaching نجموا نستحفظوا مؤقتا على معلومات إلي ديما نستحقولها و هكا ما نتلزوش باش نبعثوا request للserver باش ناخذوها كل مرّة.
  
            
            </p>
            <br>
            <h6>Session</h6>
            <p>
            
              الsession تمثل فترة مؤقتة تصير فيها communication ما بين المستعمل و الapplication و كل user عندو session خاصّة بيه.<br>
              و بالنسبة للsession variables هوما عبارة على صنادق نخبيو فيهم المعلومات الخاصة بالuser.<br>
              و الفرق انوا المعلومات هاذي موش باش نستحفظوا عليها فالlocal storage اما فالserveur بيدو.
            </p>
            <img class="centralized-image" src="assets/image/cache/1.PNG" />
            <br />
            <table class="table">
              <thead class="thead-light">
                <tr scope=" table-primary">
                  <th scope="col bg-secondary text-center">Cache</th>
                  <th scope="col ">Session</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>المعلومات متاعنا نلقاوها مخبية في الBrowser (local storage)</td>
                  <td>
                    معلوماتنا نلقاوها مخبية في الserveur (web server).

                  </td>
                </tr>
                <tr>
                  <td>
                    يحسن الperformance متاع الsite.<br />
                    خاطر باش ينقص الnumber of requests بالنسبة لبعض الinformations الي نستحقولها.
                  </td>
                  <td>
                    ماهوش باش يآثر في الperformance متاع الsite.
                  </td>
                </tr>
               
              </tbody>
            </table>
            <hr />
            <br />
            <h4>Cookies vs Session</h4>
            <h6>Cookies</h6>
            <p>
              هوما معلومات مهمة نستحقولها و نستحفظو عليهم في الdevice متاعنا كيف usernames,ID ...
              وعلى كل request نبعثوها الcookies يتبعثوا معاها باش ااserveur ينجم يتعرف عالuser.
              
                </p>
            <br>
          
            <table class="table">
                <thead class="thead-light">
                  <tr scope=" table-primary">
                    <th scope="col bg-secondary text-center">Cookies</th>
                    <th scope="col ">Session</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>هوما text files نستعملوهم باش نتبعوا الclient متاعنا.
                    </td>
                    <td>
                      المعلومات مخبية في الserver بصفة مؤقتة.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      الServer يبعث cookies للbrowser و بعد يخبd المعلومات هاكي عنده في ال local storage  باش ينجم يستعملها مرة اخرى

                    </td>
                    <td>وقة الuser يدخل لcompte, تتحل session و ما تسكر كان ما الuser يخرج مالbrowser ولا الsite, وكان موش هاكا الserver يسكر الsession وحدو بعد مدة
                    </td>
                  </tr>
                 
                </tbody>
              </table>
              <hr>
              <br>
              <h4>Cookies vs Cache</h4>
              <br>
              
            <table class="table">
              <thead class="thead-light">
                <tr scope=" table-primary">
                  <th scope="col bg-secondary text-center">Cache</th>
                  <th scope="col ">Cookies</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>نسجلوا فيها معلومات الي ديما نرجعولها

                  </td>
                  <td>
                   
                   نسجلو فيها معلوما نستحقوهم في حالات معينة كيف وقة الي نستحقو باش نسجلوا informations على user 

                  </td>
                </tr>
                <tr>
                  <td>
                      تتفصخ manuellement و انتي تختار ال strategy الي باش تفصخ بيها.


                  </td>
                  <td>توفا صلوحيتها بعد مدة
                   </td>
                </tr>
                <tr>
                  <td>
                     معلوماتنا تتسجل كان في الbrowser


                  </td>
                  <td>
                   cookies يتسجلوا في الBrowser و الServer الزوز.</td>
                </tr>
               
              </tbody>
            </table>
            <hr>
            <br>
            <h4>كيفاش الcaching يصير؟</h4>
            <p>
              ديما باش نلقاو جزء مالRAM متاعنا مخصص كان للcaching.<br>
              و وقة الي الuser يبعث request, قبل ما هاكي ال request توصل للserveur فما check يصير على الcache باش يشوف كان المعلومات deja موجودا غادي ولا لا و كان لقاها ياخوها طول مالcache موش مالserveur.<br>
              العملية هاذي اسمها
              <span style="font-weight: bold;color: red">Cache Hit</span> .</p>
              
               <img class="centralized-image" src="assets/image/cache/2.PNG" />
               <br />
               <p>
               
              و كان المعلومات الى حاجتنا بيها ما نلقاوهمش وقتها نتلزوا ناخذوهم مالserveur و العملية هاذي اسمها <span style="font-weight: bold;color: red">Cache Miss</span>.</p>
               <img class="centralized-image" src="assets/image/cache/3.PNG" />
               <br />
             
            
            <h4>وقتاش نستحقوا للcaching و وقتاش لا؟</h4>
            <p>
              <br>-نستحقولو وقت الي تبدا عندنا data ديما نرجعولها و نستحقوها و وقتها موش باش يكون لازم انوا نبعثوا request على خاطرها كل مرة<br><br>
-نستحقوها زادا وقة الdata الي باش نطلبوها تاخذلنا برشى وقة باش توصل.
            </p>
            <h4>Caching Techniques</h4>
            <p>
              ال cache ماهو الى container يتحطوا فيه copies مالdata, هاكا علاش ديما يلزمنا نعرفوا بالمسبق وقتاش نستحقوا باش نعملوا copies و وقتاش لا.
              <br>و هاكا علاش توجدوا ال caching techniques.<br><br>
1-<u><b>Write-Through Cache (Synchronous)</b></u>:<br>
وقة الي نستحقوا باش نعملوا update للvalue متع data متاعنا في الcache و الdatabase في نفس الوقة.<br>
والعملية هاذي تصير كيف نستعملوا ال IWriteThruProvider interface في الcode متاعنا.
 </p>
<img class="centralized-image" src="assets/image/cache/4.PNG" />
<br />
<p>
2-<u><b>Write-Behind Cache (asynchronous)</b></u>:
<br>يشبه لل write through  اما في عوض ما الupdate يصير على ال cache و الdatabase فرد وقت،باش يصير على الcache بعد على الdatabase. 
و وقتاش بالضبط، وقة الي ال valeur الي صارلها update في الcache تكون باش تتبدل بvaleur جديدة.
</p>
<img class="centralized-image" src="assets/image/cache/5.PNG" />
<br />
<p>
3-<u><b>Write-Around</b></u>:<br>

هوني الapplication تعمل update للdatabase و بعد الcache يتفقد الdatabase و يعمل update للvalues متاعو.</p>
<img class="centralized-image" src="assets/image/cache/6.PNG" />
<br />
<h4>Evacuation strategies:</h4>
<p>
  و بالطبيعا موش كان الspace متاع الdatabase محدود اما الcache زادة.
  هاكا علاه يلزم تكون عندنا مجموعة قواعد نفرغوا الcache space على حسابها الي هوما<br><br>
1-<u><b>Least-Recently-used (LRU) Strategy</b></u>:<br>
هوني الcache باش يتفقد اقدم data موجودا عندوا و يفصخها هي.<br><br>
2-<u><b>Least-Frequently-used (LFU) Strategy</b></u>:<br>
هوني العملي تصير على حساب اكثر piece of data ماناش قاعدين نستخدموا فيها ديما يعني نستحقولها اقل.
</p>
            <br />
            <hr />
            <p class="blockquote-footer">
              Feel free to rate the blog and send me feedback,questions or
              suggestions through the
              <span style="color: red; font-weight: bold">comment</span>
              section below. <br />To be updated for Upcoming content, please
              <span style="color: red; font-weight: bold">follow</span> this
              website.
            </p>
              </div>
              <!--#endregion -->
            </article>
            <!--#endregion-->
          </div>
          <br />
          <!--#endregion-->
          <!--#region right hand-->
          
          <div class="col-lg-3 m-15px-tb blog-aside "  >
        
<div class="sticky-sidebar">
              <app-sidebar ></app-sidebar>
              <div></div>
            </div> 
           </div>
          <!--#endregion-->
        </div>
        <div class="row">
          <div class="col">
           
            <app-comment></app-comment>
         
           <br /><br />
          </div>
        </div>
      </div>
  
      <!--#endregion-->
    </article>
    <div id="mybutton">
      <a class="btn feedback text-m3awaj" href="/" role="button"> Home >></a>
    </div>
    <!--
    <div id="knowledge-checks">
      <div class="floating-avatar">
        <img class="check-img" src="assets/icons/trophy.png" />
      </div>
    </div>-->
  </main>
  
