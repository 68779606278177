import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApiComponent } from './components/api/api.component';
import { CacheComponent } from './components/cache/cache.component';
import { CloudComponent } from './components/cloud/cloud.component';
import { HomeComponent } from './components/home/home.component';
import { JwtComponent } from './components/jwt/jwt.component';
import { MicroservicesComponent } from './components/microservices/microservices.component';
import { SolidComponent } from './components/solid/solid.component';
import { BloglayoutComponent } from './layouts/bloglayout/bloglayout.component';
import { HomelayoutComponent } from './layouts/homelayout/homelayout.component';
import { PortfoliolayoutComponent } from './layouts/portfoliolayout/portfoliolayout.component';
import { ValueReferenceTypesComponent } from './components/value-reference-types/value-reference-types.component';
import { SocialMediaComponent } from './components/social-media/social-media.component';
import { DatainterchangeformatsComponent } from './components/datainterchangeformats/datainterchangeformats.component';

const routes: Routes = [
  { path: 'mariem-moalla', component: PortfoliolayoutComponent },
  {
    path: 'letslearn', component: BloglayoutComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'designprinciples&patterns', component: SolidComponent },
      { path: 'jsonwebtokens', component: JwtComponent },
      { path: 'cache', component: CacheComponent },
      { path: 'api', component: ApiComponent },
      { path: 'microservices', component: MicroservicesComponent },
      { path: 'cloudcomputing', component: CloudComponent },
      { path: 'value&referencetypes', component: ValueReferenceTypesComponent },
      { path: 'socialmedia', component: SocialMediaComponent },
      { path: 'datainterchangeformats', component: DatainterchangeformatsComponent }

    ]
  },
  { path: '**', component: HomelayoutComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
