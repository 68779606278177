import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class BlogService {
  constructor(private http: HttpClient) { }

  getCurrentRate(blogId) {
    return this.http.get("https://waytolearnblog.azurewebsites.net/api/GetCurrentRate?code=aarjGrK6AVbY8a5JHKxXraKXBDzDWRxmVxnSmxLmmDlTwDuhenSyQQ==&blogId=" + blogId);
  }
  setRate(blogId, rate) {
    return this.http.post("https://waytolearnblog.azurewebsites.net/api/RateBlog?code=kQmV0UpEOqVjusDTQjDqhe1ugS37Mly8svGjavF73nmUbpdkMHYrtA==&blogId=" + blogId, rate);
  }
  follow(follower) {
    console.log("from service");
    return this.http.post("https://waytolearnblog.azurewebsites.net/api/Follow?code=Zo4qXVhqqDnTLFtyWOfVOa0PykXOEI1yXRG/siIZI4aa/IhPfSQQ5A==", follower);
  }
  comment(comment, blogId) {
    console.log("from service");
    return this.http.post("https://waytolearnblog.azurewebsites.net/api/Comment?code=lM3Vb5fkP4n5M/O02gduu6fnNPUoh5S6APBSgsZtM3t5LPxODB172g==&blogId=" + blogId, comment);
  }
}
