import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Follower } from 'src/app/Models/Follower';
import { BlogService } from 'src/app/Services/blog.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public value = "contact@waytolearn.org";
  //follow
  public result: boolean;
  public follower: Follower = new Follower("", "", "");
  public followerCreated = false;
  public followerExist = false;
  public checkingfollow = false;
  constructor(private blogservice: BlogService, private modalService: NgbModal) { }
  //popup
  public closeResult = '';
  public isCreated: Boolean = false;
  ngOnInit(): void {
  }
  copyMail() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = "contact@waytolearn.org";
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  OpenPopUp(content, size) {
    // { windowClass: "myCustomModalClass" }
    this.modalService.open(content, { size: size, ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.isCreated == false;
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.isCreated == false;

      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  onFollow() {
    console.log(this.follower);
    this.checkingfollow = true;
    this.blogservice.follow(this.follower).subscribe(async (data: any) => {
      this.checkingfollow = false;
      this.result = data;
      if (this.result == true) {
        this.followerCreated = true;
        await this.delay(5000);
        this.followerCreated = false;

      } else {
        this.followerExist = true;
        console.log(this.followerExist);

        await this.delay(3000);
        this.followerExist = false;

      }
    });
  }
}
