import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OneforwholeService {

  constructor(private http: HttpClient) { }

  incriment(id: string) {

    return this.http.put("https://oneforwhole.azurewebsites.net/api/Incriment?code=XGLXje4SIZGOy1ZDnnCVD/kfQ1cIMk92X8IxYNUlF7rQj1nsSdY4CA==&id=" + id, null);
  }
}
