import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Contact } from 'src/app/Models/Contact';
import { OneforwholeService } from 'src/app/Services/oneforwhole.service';
import { PortfolioService } from 'src/app/Services/portfolio.service';

@Component({
  selector: 'app-portfoliolayout',
  templateUrl: './portfoliolayout.component.html',
  styleUrls: ['./portfoliolayout.component.css']
})
export class PortfoliolayoutComponent implements OnInit {

  
  title = 'MariemPersonalProject';
  closeResult = '';

  //#region Project Popup Variables
  public currentProject = 'erpcrm';
  public erpcrm: string = 'erpcrm';
  public blog: string = 'blog';
  public hms: string = 'hms';
  public homedecor: string = 'homedecor';
  //#####################################
  public currentMobile = 'livenmtc';
  public livenmtc: string = 'livenmtc';
  //#####################################
   public currentGame = 'bemlsa';
   public bemlsa: string = 'bemlsa';
   public portals: string = 'portals';
  //#####################################
  public currentTraining = 'presentiel';
  public presentiel: string = 'presentiel'; 
  public online: string = 'online';  
 
  //#endregion

  //#region contact
  contact: Contact = new Contact("", "", "");
  contactResult: Boolean = false;
  public success: Boolean = false;
  public waiting: boolean = false;
  //#endregion
  
  constructor(private modalService: NgbModal, private PortfolioService: PortfolioService, private oneforwhole: OneforwholeService) { }

  ngOnInit(): void {
    this.onStart();
    console.log("Started!")
  }
  onStart() {
    //
    this.oneforwhole.incriment("ab426b5e-81f6-4ea2-df1b-08d94203d8c2").subscribe(data => console.log(data));
  }
  onAddContact() {
    this.waiting = true;
    this.PortfolioService.addContact(this.contact).subscribe(async (data: any) => {
      console.log(data);
      this.contactResult = data;
      if (this.contactResult == true) {
        this.waiting = false;
        this.success = true;
        await this.delay(3000);
        this.success = false;
      }
    });
  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  open(content) {
    this.modalService.open(content,
   {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = 
         `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  onOpenProject(value) {
    this.currentProject = value;
    console.log(this.currentProject);
  }
  onOpenMobile(value) {
    this.currentMobile = value;
    console.log(this.currentMobile);
  }
  onOpenGame(value) {
    this.currentGame = value;
    console.log(this.currentGame);
  }
  onOpenTraining(value) {
    this.currentTraining = value;
    console.log(this.currentTraining);
  }
  
  }

