<main>
    <article>
    <!--#region content-->
      <div class="container-fluid" >
        <div class="row align-items-start">
          <div class="col article ">
            <div class="article-img align-items-center">
              <img src="assets/image/Value & Reference types/cover.png" title="" alt="" />
            </div>
          </div>
        </div>
        <div class="row align-items-start">
          
          <!--#region Left Hand-->
          <div class="col-lg-3 m-15px-tb blog-aside sticky" >
            <div class="">

              <!--#region video -->
              <div class="widget widget-latest-post ">
                <div class="widget-body">
                  <div class="embed-responsive embed-responsive-21by9">
                    <h5>Video loading...</h5>
                    <youtube-player
                      videoId="nkGNWuW_B7c"
                      suggestedQuality="highres"
                      style="width: 100%"
                      id="youtube"
                    >
                    </youtube-player>
                  </div>
                </div>
              </div>
              <!--#endregion -->
 
              <!--#region Rate -->
              <div class="widget widget-latest-post">
                <div class="widget-title">
                  <div class="row">
                    <div class="col-6">
                      <h3 style="color: #0396A6;" >Rate</h3>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <p style="font-size: 12px">
                        (Current {{ currentRate }} <i class="fa fa-star"></i>)
                      </p>
                    </div>
                  </div>
                </div>
                <div class="widget-body">
                  <div class="">
              <div class="rating">
          <!--#region stars-->
          <input
            type="radio"
            name="rating"
            id="rating-5"
            (click)="onRate(5)"
          />
          <label for="rating-5"></label>
          <input
            type="radio"
            name="rating"
            id="rating-4"
            (click)="onRate(4)"
          />
          <label for="rating-4"></label>
          <input
            type="radio"
            name="rating"
            id="rating-3"
            (click)="onRate(3)"
          />
          <label for="rating-3"></label>
          <input
            type="radio"
            name="rating"
            id="rating-2"
            (click)="onRate(2)"
          />
          <label for="rating-2"></label>
          <input
            type="radio"
            name="rating"
            id="rating-1"
            (click)="onRate(1)"
          />
          <label for="rating-1"></label>
          <!--#endregion-->

          <!--#region Emojis-->
          <div class="emoji-wrapper">
            <div class="emoji">
              <!--#region none -->
              <img src="assets/icons/0.png" />
              <!--#endregion-->

              <!--#region 1s -->
              <img src="assets/icons/00.png" />
              <!--#endregion-->

              <!--#region 2s -->
              <img src="assets/icons/2.png" />

              <!--#endregion-->

              <!--#region 3s -->
              <img src="assets/icons/3.png" />
              <!--#endregion-->

              <!--#region 4s -->
              <img src="assets/icons/4.png" />
              <!--#endregion-->

              <!--#region 5s -->
              <img src="assets/icons/5.png" />
              <!--#endregion-->
            </div>
          </div>

          <!--#endregion-->
        </div>

        <button
          type="button"
          class="btn input btn-outline-dark btn-rounded button-wrapper"
          (click)="onSetRate()"
        >
          Submit
        </button>
        <br />
        <div
          class="alert alert-warning"
          *ngIf="!firstRate == false"
          role="alert"
        >
          Be the first to rate this Content!
        </div>
        <div
          class="alert alert-success alert-dismissible fade show"
          role="alert"
          *ngIf="!success == false"
        >
          <strong>Success!</strong> Thank you for rating us!
        </div>
      </div>
    </div>
  </div>
  <!--#endregion -->

              <!--#region knowledge check -->

              <div
                class="
                  alert alert-dismissible
                  fade
                  show
                  widget widget-author
                  timeline-card
                      "
                role="alert"
              >
                <div
                  class="media align-items-center"
                  (click)="OpenPopUp(check, 'lg')"
                >
                  <a role="button" href="javascript:void(0)">
                    <h3
                      
                      style="font-size: 20px; font-weight: 700; margin: 0;color: #0396A6;"
                    >
                      Check your Knowledge!
                    </h3>
                  </a>

                  <img src="assets/icons/trophy.png" class="follow" />
                </div>

                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <!-- #region pop up screen for solution-->

              <ng-template #check let-modal>
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    (click)="modal.dismiss('Cross click')"
                  >
                    <i class="fa fa-close"></i>
                  </button>
                </div>

                <div class="modal-body">
                  <div class="row h-100">
                    <div class="col-12">
                      <div class="align-items-center">
                        <img src="assets/image/question.jpg" />
                      </div>
                      <div
                        class="about-section bg-white rounded shadow-sm py-5 px-4"
                      >
                        <hr />
                        <form class="form-group">
                          <!--#region Q1-->
                          <div class="row">
                            <div class="col">
                              <p>1. <u>Variables in Python are concidered as:</u></p>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="q1"
                                  id="q1r1"
                                  value="Reference Type only."
                                  [(ngModel)]="qestions.q1"
                                />
                                <label class="form-check-label" for="q1r1">
                                  Reference Type only.
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="q1"
                                  id="q1r2"
                                  value="Value Type only."
                                  checked
                                  [(ngModel)]="qestions.q1"
                                />
                                <label class="form-check-label" for="q1r2">
                                  Value Type only.
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="q1"
                                  id="q1r3"
                                  checked
                                  value="Value and Reference Type."
                                  [(ngModel)]="qestions.q1"
                                />
                                <label class="form-check-label" for="q1r3">
                                  Value and Reference Type.
                                </label>
                              </div>
                            </div>
                          </div>
                          <!--#endregion-->
                          <hr />
                          <!--#region Q2-->
                          <div class="row">
                            <div class="col">
                              <p>2. <u>Variables of Value Type :</u></p>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="q2"
                                  id="q2r1"
                                  value="Are saved in the heap."
                                  [(ngModel)]="qestions.q2"
                                />
                                <label class="form-check-label" for="q2r1">
                                  Are saved in the heap.
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="q2"
                                  id="q2r2"
                                  value="Are saved in the stack."
                                  checked
                                  [(ngModel)]="qestions.q2"
                                />
                                <label class="form-check-label" for="q2r2">
                                  Are saved in the stack.
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="q2"
                                  id="q2r3"
                                  checked
                                  value="Are saved in the stack or the heap depending on the programming language."
                                  [(ngModel)]="qestions.q2"
                                />
                                <label class="form-check-label" for="q2r3">
                                  Are saved in the stack or the heap <strong>depending</strong> on the programming language.
                                </label>
                              </div>
                            </div>
                          </div>
                          <!--#endregion-->
                          <hr />
                          <!--#region Q3-->
                          <div class="row">
                            <div class="col">
                              <p>
                              3. <u>String is always a Reference type :</u>
                              </p>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="q3"
                                  id="q3r1"
                                  value="False."
                                  [(ngModel)]="qestions.q3"
                                />
                                <label class="form-check-label" for="q3r1">
                                  False.
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="32"
                                  id="q3r2"
                                  value="True."
                                  checked
                                  [(ngModel)]="qestions.q3"
                                />
                                <label class="form-check-label" for="q3r2">
                                  True.

                                </label>
                              </div>
                              
                            </div>
                          </div>
                          <!--#endregion-->
                        </form>
                        <ng-template #check let-modal>
                          <div class="modal-header">
                            <button
                              type="button"
                              class="close"
                              aria-label="Close"
                              (click)="modal.dismiss('Cross click')"
                            >
                              <i class="fa fa-close"></i>
                            </button>
                          </div>

                          <div class="modal-body">
                            <div class="row h-100">
                              <div class="col-12">
                                <div
                                  class="
                                    about-section
                                    bg-white
                                    rounded
                                    shadow-sm
                                    py-5
                                    px-4
                                  "
                                >
                                  <div class="alert alert-{{ alert }}" role="alert">
                                    {{ replay }}
                                  </div>
                                  <hr />
                                  <div class="row">
                                    <div class="col">
                                      <table class="table">
                                        <thead class="thead">
                                          <tr scope=" table-success">
                                            <th scope="col ">Corract Answers</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            class="table-success"
                                            *ngFor="let row of correct"
                                          >
                                            <td class="table-success">
                                              {{ row }}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div class="col">
                                      <table class="table">
                                        <thead class="thead-danger">
                                          <tr scope=" table-danger">
                                            <th scope="col table-danger">
                                              Wrong Answers
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            class="table-danger"
                                            *ngFor="let wrong of wrong"
                                          >
                                            <td class="table-danger">
                                              {{ wrong }}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                        <div class="col">
                          <button
                            type="button"
                            class="
                              btn btn-outline-dark btn-rounded
                              button-wrapper
                              input
                            "
                            (click)="OpenPopUp(check, 'lg')"
                            (click)="onCheck()"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
              <!-- #endregion -->

              <!--#endregion -->
       </div>
          </div>
          <!--#endregion-->
          
          <!--#region Middle hand-->
          <div class="col-lg-6 m-15px-tb">
            <!--#region article-->
            <article class="article">
              <div class="article-title">
                <div class="row">
                  <div class="col-6 col-md-9">
                    <h2>Value & Reference Types</h2>
                  </div>
                  <div class="col-6 col-md-3">
                    <span (click)="onChange()"
                      ><input
                        type="checkbox"
                        checked
                        data-on="Tunisian"
                        data-off="English"
                        data-toggle="toggle"
                        data-onstyle="info"
                        data-offstyle="secondary"
                    /></span>
                  </div>
                </div>
              </div>
              <hr />
              <!--#region english article-->
              <div
                class="article-content"
                style="font-family: 'Segoe UI'"
                *ngIf="english == true"
              > 
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">In programming, even types such as integer, string, boolean, and others are divided into two categories depending on the way they are saved in memory.&nbsp;</span></p>
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><br></p>
                <h4>Value Types:</h4>
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">The first category is &quot;</span><strong style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">Value Type</span></strong><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">&quot; when you create one, the new value will be stored in one single space in memory.</span></p>
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">This means, the variable of this type directly contains the&nbsp;</span><strong style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">value&nbsp;</span></strong><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">and&nbsp;</span><u style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(226, 80, 65); background: transparent; margin-top: 0pt; margin-bottom: 0pt;">not</span></u><span data-preserver-spaces="true" style="color: rgb(226, 80, 65); background: transparent; margin-top: 0pt; margin-bottom: 0pt;">&nbsp;</span><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">a&nbsp;</span><strong style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">reference</span></strong><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">&nbsp;to it.</span></p>
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">In this case, if you assign a Value Type variable to another variable, the value will be copied directly and both variables work independently.&nbsp;</span></p>
                <br>
                <img class="centralized-image" src="assets/image/Value & Reference types/value type example.PNG" />
                <br>
                <p> Console Output: </p>
                <img class="centralized-image" src="assets/image/Value & Reference types/valueconsoleoutput.png" />
                <br>
                <h4>Reference Types:</h4>
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">The second category is &quot;</span><strong style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">Reference Type</span></strong><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">&quot; in this case, the new value will be none but a <u>pointer</u></span></p>
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">(address).</span></p>
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">With reference type, the value won&apos;t be stored directly in memory,</span><strong style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">&nbsp;</span></strong><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">instead, an</span><strong style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">&nbsp;</span></strong><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">address will be, and it will point at where the value is being stored.</span></p>
                <br>
                <img class="centralized-image" src="assets/image/Value & Reference types/refernce type example.PNG" />
                <br>
                <p> Console Output: </p>
                <img class="centralized-image" src="assets/image/Value & Reference types/referenceconsoleoutput.png" />
                <br><br>
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">Value Types and reference types actually depend a lot on the language we are working with, for example in python all variables belong to the Reference type category, which means if I assign the same content of a variable a to b, b won&apos;t copy it but instead will point on that space in memory which contains the value itself and at the end, both a and b will point at the same memory space.</span></p>
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">And in other languages value types mostly represent simple types such as integer, boolean, enums, etc. and reference types will include objects, strings, Delegates, and others.</span></p>
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><br></p>
                <h4>Where are we stored?</h4>
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">Now after understanding both of these categories we can go to the next step to know where the actual value and addresses are stored.</span></p>
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><br></p>
                <h6>The Stack:</h6>
                <img class="centralized-image" style="height:400px; "src="assets/image/Value & Reference types/Stack.png" />
                <br>
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">It&apos;s a <u>linear data structure</u>, which means its</span><strong style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">&nbsp;</span></strong><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">components are organized<u> </u><u>sequentially&nbsp;</u>and each one is connected to the previous and next element at once.</span></p>
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">we can compare it to a table or linked list.</span></p>
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">Stack memory is where we stock either value of value types&nbsp;</span><span data-preserver-spaces="true" style="color: rgb(226, 80, 65); background: transparent; margin-top: 0pt; margin-bottom: 0pt;"><u>and</u>&nbsp;</span><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">references (addresses) that belongs to objects of Reference types.</span></p>
                <br>
                <h6>The Heap:</h6>
                <img class="centralized-image"  style="height:400px; " src="assets/image/Value & Reference types/Heap.png" />
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">Unlike the&nbsp;</span><strong style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">stack</span></strong><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">&nbsp;where memory is allocated and organized, memory in the heap is allocated&nbsp;</span><span data-preserver-spaces="true" style="color: rgb(226, 80, 65); background: transparent; margin-top: 0pt; margin-bottom: 0pt;"><u>randomly</u></span><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">.&nbsp;</span></p>
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">It&apos;s where we save values of Reference type.</span></p>              
                <br />
                <hr />
                <p class="blockquote-footer">
                  Feel free to rate the blog and send me feedback,questions or
                  suggestions through the
                  <span style="color: red; font-weight: bold">comment</span>
                  section below. <br />To be updated for Upcoming content, please
                  <span style="color: red; font-weight: bold">follow</span> this
                  website.
                </p>              
              </div>
              <!--#endregion -->
              <!--#region tunisian article-->
              <div
                *ngIf="tunisian == true"
                class="article-content"
                style="direction: rtl; display: inline-block; text-align: right"
              >
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top: 0pt; margin-bottom: 0pt; text-align: right;">في الprogrammation كلtype كيف الinteger, boolean, string و غيرهم، مقسمين لزوز categories على حسب الطريقة الي هوما تسجلوا بيها في الmemory.&nbsp;</p>
                <br>
                <h4>Value Types:</h4>
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top: 0pt; margin-bottom: 0pt; text-align: right;">اول category باش نحكيوا عليها هي ال&quot;Value Type&quot; وقت تصنع variable الcategory الي تنتمي ليها هي الvalue type، الvaleur الي تصنعت باش تتسجل directement في un espace memoire.<br/> هاذا يعني انو الvariable هاكي باش تهز طول الvalue الي تصنعت فيها و موش الreference ليها.<br/> في الحالة هاذي،كان مثال صنعت variable اخرى و you affected ليها الvaleur القديمة الي موجودة في variable صنعتها، الvaleur هاكي باش تصيرلها copie، هي الي باش تتسجل فالvariable الجديدة و en fin الزوز variables باش تلقاهم independent من بعضهم حتى لوكان عندهم نفس الvalue.</p>
                <br>
                <img class="centralized-image" src="assets/image/Value & Reference types/value type example.PNG" />
                <br>
                <p> Console Output: </p>
                <img class="centralized-image" src="assets/image/Value & Reference types/valueconsoleoutput.png" />
                <br>
                <h4>Reference Types:</h4>
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top: 0pt; margin-bottom: 0pt; text-align: right;">&nbsp;ثاني category هي ال&quot;Reference Type&quot; في الحالة هاذي الvaleur الجديدة الي صنعناها في الحقيقة ماهاش باش تهزّ ولا تحمل directement الvaleur الي عتيناها للvariable اما باش تسجل فيها adresse ولا pointeur وهاذا الاخير هو الي باش يشير على البلاصة في الmemory الي تسجلت فيها الvaleur الأصلية.&nbsp;</p>
                <br>
                <img class="centralized-image" src="assets/image/Value & Reference types/refernce type example.PNG" />
                <br>
                <p> Console Output: </p>
                <img class="centralized-image" src="assets/image/Value & Reference types/referenceconsoleoutput.png" />
                <br><br>
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top: 0pt; margin-bottom: 0pt; text-align: right;">الValue Types و ال Reference Types في الحقيقة depend ياسر مالlanguage de programmation الي نخدموا بيها، مثال في Python الvariables الكل الcategory متاعهم هي Reference Type هاذا يعني انو اذا عطيت نفس الvaleur لزوز variables كل وحدة فيهم r&eacute;ellement موش باش تتسجل فيها الvaleur هاكي mais باش تلقاهم pointing على نفس الcase memoire الي تسجلت فيه الvaleur وكان بدلنا الvalue الي مسجلة في وحدة مالvariables هاكم موش الvaleur باش تتبدل mais الpointeur متاع الvariable هاكي باش يpointi على case memoire اخرى الي حاجتنا بيها.<br/> و في des languages اخرين، عموما الvalue types variables باش يمثلوا simple types كيف ال integer, boolean, enums, etc.</p>
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top: 0pt; margin-bottom: 0pt; text-align: right;">و الReference Types باش يمثلوا other types اكثر complicated كيف الstrings, objects, delegates و غيرهم.</p>
                <h4>Where are we stored؟</h4>
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top: 0pt; margin-bottom: 0pt; text-align: right;">&nbsp;توا بعد ما فهمنا الفرق مابين ال Value و الReference Type نجموا ناخذوا خطوة القدام و نعرفوا في الحقيقة les valeurs وين قاعدين يتسجلوا.&nbsp;</p>
                <br/>
                <h6>The Stack:</h6>
                <img class="centralized-image" style="height:400px; "src="assets/image/Value & Reference types/Stack.png" />
                <br>
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top: 0pt; margin-bottom: 0pt; text-align: right;">&nbsp;هي تعتبر linear data structure وين معلوماتنا مسجل بنظام وحدة وراء الاخرى كيف المعلومات الي نسجلوهم في table ولا liste. ال memory هاذي هي الي يتسجلوا فيها الvalues الي يخصوا ال variables of Value types ولا الadresses الي يخصوا الvariables of Reference types.&nbsp;</p>
                <br>
                <h6>The Heap:</h6>
                <img class="centralized-image"  style="height:400px; " src="assets/image/Value & Reference types/Heap.png" />
                <br />
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top: 0pt; margin-bottom: 0pt; text-align: right;">على عكس ال stack وين الvalues مسجلين بنظام و متسلسلين ، ال data في الcas هاذه منظمة عشوائيا.<br /> و هوني في الحقيقة وين تتسجل les valeurs الي الpointeur متاع variables of reference types &nbsp;يشيروا عليهم ولا بلغة اخرى point at them.&nbsp;</p>
                <hr />
                <p class="blockquote-footer">
                  Feel free to rate the blog and send me feedback,questions or
                  suggestions through the
                  <span style="color: red; font-weight: bold">comment</span>
                  section below. <br />To be updated for Upcoming content, please
                  <span style="color: red; font-weight: bold">follow</span> this
                  website.
                </p>    
              </div>
              <!--#endregion -->
            </article>
            <!--#endregion-->
          </div>
          <br />
          <!--#endregion-->
         
          <!--#region right hand-->
          <div class="col-lg-3 m-15px-tb blog-aside "  >
        
        <div class="sticky-sidebar" >
              <app-sidebar ></app-sidebar>
        <div></div>
            </div> 
           </div>
          <!--#endregion-->
        
        </div>
        <div class="row">
          <div class="col">
           
            <app-comment></app-comment>
         
           <br /><br />
          </div>
        </div>
      </div>
      <!--#endregion-->
    </article>
    <div id="mybutton">
      <a class="btn feedback text-m3awaj" href="/" role="button"> Home >></a>
    </div>
</main>

