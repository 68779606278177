import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlogService } from 'src/app/Services/blog.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public searchValue: string;
  
  public solidcurrentRate: number = 0;
  public microserviceRate: number = 0;
  public jwtcurrentRate: number = 0;
  public cachingcurrentRate: number = 0;
  public apicurrentRate: number = 0;
  public cloudcurrentrate: number = 0;
  public valuereferencecurrentrate: number = 0;
  public datainterchangeformats: number =0;
  constructor(private router: Router, private blogservice: BlogService) { }

  ngOnInit(): void {
    this.start();
  }

  start() {
    this.blogservice.getCurrentRate("e7661991-841f-4958-2964-08d989cf6235").subscribe((data: any) => {
      this.microserviceRate = data;
      console.log(this.microserviceRate);
    });
    this.blogservice.getCurrentRate("b58334a8-7226-422d-ad4e-08d958fba089").subscribe((data: any) => {
      this.solidcurrentRate = data;
      console.log(this.solidcurrentRate);
    });
    this.blogservice.getCurrentRate("dee21952-ae1b-48d5-ad10-08d965a020d8").subscribe((data: any) => {
      this.jwtcurrentRate = data;
      console.log(this.jwtcurrentRate);
    });
    this.blogservice.getCurrentRate("e33cfa9b-4c46-429f-ffd7-08d9717747d5").subscribe((data: any) => {
      this.cachingcurrentRate = data;
      console.log(this.cachingcurrentRate);
    });
    this.blogservice.getCurrentRate("2bbd789a-ec71-44ce-6adb-08d97ae66c47").subscribe((data: any) => {
      this.apicurrentRate = data;
      console.log(this.apicurrentRate);
    });
    this.blogservice.getCurrentRate("f3d47164-ded3-4495-492e-08d9a3c28828").subscribe((data: any) => {
      this.cloudcurrentrate = data;
      console.log(this.cloudcurrentrate);
    });
    this.blogservice.getCurrentRate("d0258927-7fdc-4fe3-53f1-08d9ee698d39").subscribe((data: any) => {
      this.valuereferencecurrentrate = data;
      console.log(this.valuereferencecurrentrate);
    });
    this.blogservice.getCurrentRate("a2c9b429-4e1e-4629-31d8-08da24628a8").subscribe((data: any) => {
      this.datainterchangeformats = data;
      console.log(this.datainterchangeformats);
    });
  }

  search ()
  {
    console.log(this.searchValue);
    switch(this.searchValue) { 
        case "APIs": { 
          this.router.navigateByUrl('/letslearn/api').then(() => {
            window.location.reload();
          }); 
          break; 
        } 
        case "S.O.L.I.D Design Principles": { 
          this.router.navigateByUrl('/letslearn/designprinciples&patterns');           
          break; 
        } 
        case "JWT Json Web Tokens": { 
          this.router.navigateByUrl('/letslearn/jsonwebtokens');          
           break; 
      }
        case "Caching": { 
          this.router.navigateByUrl('/letslearn/cache');           
          break; 
      }
        case "Microservices": { 
          this.router.navigateByUrl('/letslearn/microservices');           
          break; 
        }
        case "Cloud Computing": { 
          this.router.navigateByUrl('/letslearn/cloudcomputing');           
          break; 
        }
        case "Value & Reference Type": { 
          this.router.navigateByUrl('/letslearn/value&referencetypes');           
          break; 
        }
        case "Data Interchange Formats": { 
          this.router.navigateByUrl('/letslearn/datainterchangeformats');           
          break; 
        }
      default: { 
        this.router.navigateByUrl('/letslearn');          
        break; 
      } 
   } 
  }
}
