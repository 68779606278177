<main>
    <article>
    <!--#region content-->
      <div class="container-fluid" >
        <div class="row align-items-start">
          <div class="col article ">
            <div class="article-img align-items-center">
              <img src="assets/image/datainterchangeformats/DataEnterchange.png" title="" alt="" />
            </div>
          </div>
        </div>
        <div class="row align-items-start">
          
          <!--#region Left Hand-->
          <div class="col-lg-3 m-15px-tb blog-aside sticky" >
            <div class="">

              <!--#region video 
              <div class="widget widget-latest-post ">
                <div class="widget-body">
                  <div class="embed-responsive embed-responsive-21by9">
                    <h5>Video loading...</h5>
                    <youtube-player
                      videoId="nkGNWuW_B7c"
                      suggestedQuality="highres"
                      style="width: 100%"
                      id="youtube"
                    >
                    </youtube-player>
                  </div>
                </div>
              </div>
              #endregion -->
 
              <!--#region Rate -->
              <div class="widget widget-latest-post">
                <div class="widget-title">
                  <div class="row">
                    <div class="col-6">
                      <h3 style="color: #0396A6;" >Rate</h3>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <p style="font-size: 12px">
                        (Current {{ currentRate }} <i class="fa fa-star"></i>)
                      </p>
                    </div>
                  </div>
                </div>
                <div class="widget-body">
                  <div class="">
              <div class="rating">
          <!--#region stars-->
          <input
            type="radio"
            name="rating"
            id="rating-5"
            (click)="onRate(5)"
          />
          <label for="rating-5"></label>
          <input
            type="radio"
            name="rating"
            id="rating-4"
            (click)="onRate(4)"
          />
          <label for="rating-4"></label>
          <input
            type="radio"
            name="rating"
            id="rating-3"
            (click)="onRate(3)"
          />
          <label for="rating-3"></label>
          <input
            type="radio"
            name="rating"
            id="rating-2"
            (click)="onRate(2)"
          />
          <label for="rating-2"></label>
          <input
            type="radio"
            name="rating"
            id="rating-1"
            (click)="onRate(1)"
          />
          <label for="rating-1"></label>
          <!--#endregion-->

          <!--#region Emojis-->
          <div class="emoji-wrapper">
            <div class="emoji">
              <!--#region none -->
              <img src="assets/icons/0.png" />
              <!--#endregion-->

              <!--#region 1s -->
              <img src="assets/icons/00.png" />
              <!--#endregion-->

              <!--#region 2s -->
              <img src="assets/icons/2.png" />

              <!--#endregion-->

              <!--#region 3s -->
              <img src="assets/icons/3.png" />
              <!--#endregion-->

              <!--#region 4s -->
              <img src="assets/icons/4.png" />
              <!--#endregion-->

              <!--#region 5s -->
              <img src="assets/icons/5.png" />
              <!--#endregion-->
            </div>
          </div>

          <!--#endregion-->
        </div>

        <button
          type="button"
          class="btn input btn-outline-dark btn-rounded button-wrapper"
          (click)="onSetRate()"
        >
          Submit
        </button>
        <br />
        <div
          class="alert alert-warning"
          *ngIf="!firstRate == false"
          role="alert"
        >
          Be the first to rate this Content!
        </div>
        <div
          class="alert alert-success alert-dismissible fade show"
          role="alert"
          *ngIf="!success == false"
        >
          <strong>Success!</strong> Thank you for rating us!
        </div>
      </div>
    </div>
  </div>
  <!--#endregion -->

              <!--#region knowledge check -->

              <div
                class="
                  alert alert-dismissible
                  fade
                  show
                  widget widget-author
                  timeline-card
                      "
                role="alert"
              >
                <div
                  class="media align-items-center"
                  (click)="OpenPopUp(check, 'lg')"
                >
                  <a role="button" href="javascript:void(0)">
                    <h3
                      
                      style="font-size: 20px; font-weight: 700; margin: 0;color: #0396A6;"
                    >
                      Check your Knowledge!
                    </h3>
                  </a>

                  <img src="assets/icons/trophy.png" class="follow" />
                </div>

                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <!-- #region pop up screen for solution-->

              <ng-template #check let-modal>
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    (click)="modal.dismiss('Cross click')"
                  >
                    <i class="fa fa-close"></i>
                  </button>
                </div>

                <div class="modal-body">
                  <div class="row h-100">
                    <div class="col-12">
                      <div class="align-items-center">
                        <img src="assets/image/question.jpg" />
                      </div>
                      <div
                        class="about-section bg-white rounded shadow-sm py-5 px-4"
                      >
                        <hr />
                        <form class="form-group">
                          <!--#region Q1-->
                          <div class="row">
                            <div class="col">
                              <p>1. <u>With Python we can only exchange data via YAMl Files:</u></p>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="q1"
                                  id="q1r1"
                                  value="True"
                                  [(ngModel)]="qestions.q1"
                                />
                                <label class="form-check-label" for="q1r1">
                                  True
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="q1"
                                  id="q1r2"
                                  value="False"
                                  checked
                                  [(ngModel)]="qestions.q1"
                                />
                                <label class="form-check-label" for="q1r2">
                                  False
                                </label>
                              </div>
                            </div>
                          </div>
                          <!--#endregion-->
                          <hr />
                          <!--#region Q2-->
                          <div class="row">
                            <div class="col">
                              <p>2. <u>XML and HTML are both Markup Languages</u></p>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="q2"
                                  id="q2r1"
                                  value="True"
                                  [(ngModel)]="qestions.q2"
                                />
                                <label class="form-check-label" for="q2r1">
                                  True
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="q2"
                                  id="q2r2"
                                  value="False"
                                  checked
                                  [(ngModel)]="qestions.q2"
                                />
                                <label class="form-check-label" for="q2r2">
                                  False
                                </label>
                              </div>
                            </div>
                          </div>
                          <!--#endregion-->
                          <hr />
                          <!--#region Q3-->
                          <div class="row">
                            <div class="col">
                              <p>
                              3. <u>The JSON format is syntactically identical to the code for creating JavaScript objects.</u>
                              </p>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="q3"
                                  id="q3r1"
                                  value="True"
                                  [(ngModel)]="qestions.q3"
                                />
                                <label class="form-check-label" for="q3r1">
                                  True
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="32"
                                  id="q3r2"
                                  value="False"
                                  checked
                                  [(ngModel)]="qestions.q3"
                                />
                                <label class="form-check-label" for="q3r2">
                                  False

                                </label>
                              </div>
                              
                            </div>
                          </div>
                          <!--#endregion-->
                        </form>
                        <ng-template #check let-modal>
                          <div class="modal-header">
                            <button
                              type="button"
                              class="close"
                              aria-label="Close"
                              (click)="modal.dismiss('Cross click')"
                            >
                              <i class="fa fa-close"></i>
                            </button>
                          </div>

                          <div class="modal-body">
                            <div class="row h-100">
                              <div class="col-12">
                                <div
                                  class="
                                    about-section
                                    bg-white
                                    rounded
                                    shadow-sm
                                    py-5
                                    px-4
                                  "
                                >
                                  <div class="alert alert-{{ alert }}" role="alert">
                                    {{ replay }}
                                  </div>
                                  <hr />
                                  <div class="row">
                                    <div class="col">
                                      <table class="table">
                                        <thead class="thead">
                                          <tr scope=" table-success">
                                            <th scope="col ">Corract Answers</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            class="table-success"
                                            *ngFor="let row of correct"
                                          >
                                            <td class="table-success">
                                              {{ row }}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div class="col">
                                      <table class="table">
                                        <thead class="thead-danger">
                                          <tr scope=" table-danger">
                                            <th scope="col table-danger">
                                              Wrong Answers
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            class="table-danger"
                                            *ngFor="let wrong of wrong"
                                          >
                                            <td class="table-danger">
                                              {{ wrong }}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                        <div class="col">
                          <button
                            type="button"
                            class="
                              btn btn-outline-dark btn-rounded
                              button-wrapper
                              input
                            "
                            (click)="OpenPopUp(check, 'lg')"
                            (click)="onCheck()"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
              <!-- #endregion -->

              <!--#endregion -->
       </div>
          </div>
          <!--#endregion-->
          
          <!--#region Middle hand-->
          <div class="col-lg-6 m-15px-tb">
            <!--#region article-->
            <article class="article">
              <div class="article-title">
                <div class="row">
                  <div class="col-6 col-md-9">
                    <h2>Data Interchange Formats</h2>
                  </div>
                  <div class="col-6 col-md-3">
                    <span (click)="onChange()"
                      ><input
                        type="checkbox"
                        checked
                        data-on="Tunisian"
                        data-off="English"
                        data-toggle="toggle"
                        data-onstyle="info"
                        data-offstyle="secondary"
                    /></span>
                  </div>
                </div>
              </div>
              <hr />
              <!--#region english article-->
              <div
                class="article-content"
                style="font-family: 'Segoe UI'"
                *ngIf="english == true"
              > 
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">Whenever we have a sort of communication between either two systems or applications we need to create a way to exchange data between each party.<br>
                  Us humans, for example, communicate via languages such as English, but in a different perspective, machines don't understand our language to transfer data instead it has their own formula.<br>
                  And the most widely used and famous data interchange formats  are:&nbsp;</span></p><br>
                  <ul>
                    <li>XML</li>
                    <li>YAML</li>
                    <li>JSON</li>
                  </ul>
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><br></p>
                <h4>XML(Extensible Markup Language):</h4>
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">
                  It's a Markup a language similar to HTML but doesn't have a limit of tags to use.<br>
                  In this case, you chose to define your tags the way you want.<br>
                  XML is used to store data in an understandable and well-structured formula.<br>
                  Used to share structured data between computers, systems, and programs.</span></p>
                <br>
                <img class="centralized-image" src="assets/image/datainterchangeformats/xml.PNG" />
                <br>
                <h4>YAML:</h4>
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">
                  On the other hand, YAML is not a markup language, it's though a data serialization language used to transmit data commonly used to write configuration files.<br>
                  Common for it is a super friendly interface.
                  And even though the syntax is so similar, it doesn't mean that Python uses YAML file for interchanging data.</span></p>
                <br>
                <img class="centralized-image" src="assets/image/datainterchangeformats/yaml.png" />
                <br>
                <h4>JSON(JavaScript Object Notation):</h4>
                <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">
                  Another data interchange format is used to store and transmit data.<br>
                  It is based on Javascript syntax, commonly used to exchange data between a server and a web app.</span></p>
                <br>
                <img class="centralized-image" src="assets/image/datainterchangeformats/json.PNG" />
                <br />
                <hr />
                <p class="blockquote-footer">
                  Feel free to rate the blog and send me feedback,questions or
                  suggestions through the
                  <span style="color: red; font-weight: bold">comment</span>
                  section below. <br />To be updated for Upcoming content, please
                  <span style="color: red; font-weight: bold">follow</span> this
                  website.
                </p>              
              </div>
              <!--#endregion -->
              <!--#region tunisian article-->
              <div
                *ngIf="tunisian == true"
                class="article-content"
                style="direction: rtl; display: inline-block; text-align: right"
              >
              <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">
                ديما في حالت فما communication ما بين زوز systems ولا applications, نستحقوا باش نوفروا طريقو ولا moyen ليهم الزوز باش ينجمزا يحكيوا.<br>
                احنا في حالتنا كبشر نحكيوا بفضل الصوت و لغتنا العامة  quelque soit عربية، انجليزية...<br>
                اما من منظور الmachine, نلقاوا طريقة اخرى باش ينجموا يحكيوا.
                ومن اكثر ال   data interchange formats استعمالا في وقتنا:
              </span></p><br>
                <ul>
                  <li>XML</li>
                  <li>YAML</li>
                  <li>JSON</li>
                </ul>
              <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><br></p>
              <h4>XML(Extensible Markup Language):</h4>
              <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">
                ال XML ياسر يشبه لل HTML الزوز يتسماو Markup language يخدموا بالtags, الفرق هوني انوا ال XML ما عندوش حدود في اسامي الtags الي تخدم بيهم كيف HTML.<br>
ماهوش HTML و ماعندهمش نفس الوضيفة.<br>
نستعملوه باش نبعثوا و نستحفظوا على معلوماتنا بطريقة مفهومة لينا و الmachine.</span></p>
              <br>
              <img class="centralized-image" src="assets/image/datainterchangeformats/xml.PNG" />
              <br>
              <h4>YAML:</h4>
              <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">
                اما هوني YAML موش كيف XML, و ماهوش Markup Language عندو طريقتو و كتيبتو الخاصة باش يسجللنا معلوماتنا.<br>
الاكثرية نستعملوه باش نصنعوا configuration files للapplications متاعنا.<br>
وكي نلاحضوا نلقاو الي الsyntax متاعوا يشبه ياسر للPython اما هاذا مايعنيش انوا خاطر يشبهوا بعضهم يعنيPython يستعمل YAML files.</span></p>
              <br>
              <img class="centralized-image" src="assets/image/datainterchangeformats/yaml.png" />
              <br>
              <h4>JSON(JavaScript Object Notation):</h4>
              <p style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt;">
                نلقاوا زادا JSON نستعملوه لنفس الشيء.<br>
وكيف نجيو نثبتوا نلقاوه based على JavaScript syntax, نستعملوه اكثر الوقت باش نتبادلو معلومات ما بين الserver و الapp.</span></p>
              <br>
              <img class="centralized-image" src="assets/image/datainterchangeformats/json.PNG" />
                 <hr />
                <p class="blockquote-footer">
                  Feel free to rate the blog and send me feedback,questions or
                  suggestions through the
                  <span style="color: red; font-weight: bold">comment</span>
                  section below. <br />To be updated for Upcoming content, please
                  <span style="color: red; font-weight: bold">follow</span> this
                  website.
                </p>    
              </div>
              <!--#endregion -->
            </article>
            <!--#endregion-->
          </div>
          <br />
          <!--#endregion-->
         
          <!--#region right hand-->
          <div class="col-lg-3 m-15px-tb blog-aside "  >
        
        <div class="sticky-sidebar" >
              <app-sidebar ></app-sidebar>
        <div></div>
            </div> 
           </div>
          <!--#endregion-->
        
        </div>
        <div class="row">
          <div class="col">
           
            <app-comment></app-comment>
         
           <br /><br />
          </div>
        </div>
      </div>
      <!--#endregion-->
    </article>
    <div id="mybutton">
      <a class="btn feedback text-m3awaj" href="/" role="button"> Home >></a>
    </div>
</main>
