export class Question {
    q1: string;
    q2: string;
    q3: string;

    constructor(q1: string,
        q2: string,
        q3: string) {
        this.q1 = q1;
        this.q2 = q2;
        this.q3 = q3;

    }
}