<footer class="text-center" id="contact">
  <br />
  <br />
  <!--#region main section -->
  <div class="container">
    <div class="row">
      <!--#region ABout us-->
      <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
        <h5 class="text-uppercase mb-4" style="color: white">About Way to Learn</h5>

        <p style="color:#0396A6">
          waytolearn.org is dedicated for learning various concepts technologies
          and solutions. This blog requires no user registration. Users can
          visit waytolearn.org, and navigate all its pages.
        </p>
      </div>
      <!--#endregion-->

      <!--#region content-->
      <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
        <h5 class="text-uppercase mb-4" style="color: white">Content</h5>

        <ul class="list-unstyled">
          <li>
            <a
              href="/letslearn/designprinciples&patterns"
              style="color: #0396A6"
              >SOLID Design Principles</a
            >
          </li>
           <li>
            <a
              href="/letslearn/jsonwebtokens"
              style="color: #0396A6"
              >Json Web Tokens</a
            >
          </li>
          <li>
            <a
              href="/letslearn/cache"
              style="color: #0396A6"
              >Caching</a
            >
          </li>
          <li>
            <a
              href="/letslearn/api"
              style="color: #0396A6"
              >APIs</a
            >
          </li>
          <li>
            <a
              href="/letslearn/microservices"
              style="color: #0396A6"
              >Microservices</a
            >
          </li>
          <li>
            <a
              href="/letslearn/cloudcomputing"
              style="color: #0396A6"
              >Cloud Computing</a
            >
          </li>
          <li>
            <a
              href="/letslearn/value&referencetypes"
              style="color: #0396A6"
              >Value & Reference Type</a
            >
          </li>
          <li>
            <a
              href="/letslearn/datainterchangeformats"
              style="color: #0396A6"
              >Data Interchange Formats</a
            >
          </li>
        </ul>
      </div>
      <!--#endregion-->

      <!--#region follow-->

      <div class="col-lg-5 col-md-6 mb-4 mb-lg-0">
        <p class="justify-content-center text-center">
          <span class="me-3"  style="color: white">For Upcoming Updates</span> &nbsp;&nbsp;&nbsp;
          <button
            type="button"
            class="btn btn-outline-dark btn-rounded text-light"
            (click)="OpenPopUp(follow, 'lg')"
          >
            Follow!
          </button>
        </p>
        <!-- #region pop up screen for solution-->

        <ng-template #follow let-modal>
          <div class="modal-header">
            <button
              type="button"
              class="close"
              aria-label="Close"
              (click)="modal.dismiss('Cross click')"
            >
              <i class="fa fa-close"></i>
            </button>
          </div>

          <div class="modal-body">
            <div class="row h-100">
              <div class="col-12">
                <div
                class="about-section bg-white rounded shadow-sm py-5 px-4"
              >
              <section class="get-in-touch">
                <h1 class="title">Follow Us</h1>
                <p class="text-muted text fs-6">
                  To recieve reminders about the last updates and new
                  content fill this form and become a precious Follower!
                </p>
    
                <div
                  class="alert alert-primary alert-dismissible fade show"
                  role="alert"
                  *ngIf="!checkingfollow == false"
                >
                  Please wait while checking your email...
                </div>
                <div
                  class="alert alert-success alert-dismissible fade show"
                  role="alert"
                  *ngIf="!followerCreated == false"
                >
                  <strong>Success!</strong> Thank you for following way to
                  learn.
                </div>
                <div
                  class="alert alert-danger alert-dismissible fade show"
                  role="alert"
                  *ngIf="!followerExist == false"
                >
                  <strong>hmmm... You are already a follower!</strong>
                </div>
                <form class="contact-form row">
                   <div class="form-field col-lg-6">
                    <label class="label" for="fn">First Name</label>
                    
                    <input
                      id="fn"
                      class=" input-text js-input"
                      type="text"
                      name="firstName"
                      required
                      [(ngModel)]="follower.firstName"
                    />
                   </div>
                   <div class="form-field col-lg-6 ">
                    <label  class="label" for="ln">Last Name</label>
                    
                    <input
                      id="ln"
                      class="input-text js-input"
                      type="text"
                      name="lastName"
                      required
                      [(ngModel)]="follower.lastName"
                    />
                   </div>
    
                   <div class="form-field col-lg-12">
                    <label class="label" for="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      class="input-text js-input"
                      name="email"
                      [(ngModel)]="follower.email"
                      required
                    />
                   </div>
                   <div class="form-field col-lg-12" >
                      <input class="submit-btn" type="submit" value="Submit" (click)="onFollow()"> 
                   </div>
                </form>
             </section>
               
            </div>
              </div>
            </div>
          </div>
        </ng-template>
        <!-- #endregion -->

        <p style="color: white;">
           Contact:
         

          <button
            class="btn btn-link text-decoration-none"
            style="
              display: inline-block;
              border-radius: 0px;
              box-shadow: 0px 0px 0px #888;
              padding: 0em 0em;
            "
            (click)="copyMail()"
          >
            contact@waytolearn.org

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-clipboard"
              viewBox="0 0 16 16"
            >
              <path
                d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"
              ></path>
              <path
                d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"
              ></path>
            </svg>
          </button>
        </p>
        <hr style="background-color: white;"/>
        <!--#region Section: Social media -->

        <section class="mb-4">
          <!-- Facebook -->
          <a
            class="btn btn-dark btn-floating m-1"
            style="background-color: #3b5998"
            target="blank"
            href="https://www.facebook.com/waytolearnn"
            role="button"
            ><i class="fa fa-facebook" style="width: 15px"></i
          ></a>

          <!-- Linkedin -->
          <a
            class="btn btn-dark btn-floating m-1"
            style="background-color: #0082ca"
            target="blank"
            href="https://www.linkedin.com/company/way-to-learn"
            role="button"
            ><i class="fa fa-linkedin"></i
          ></a>
          <!-- Instagram -->
          <a
            class="btn btn-primary m-1 btn-floating"
            style="background-color: #ac2bac;"
            target="blank"
            href="https://www.instagram.com/waytolearnn"
            role="button"
            ><i class="fa fa-instagram"></i
          ></a>
        </section>

        <!-- #endregion-->
      </div>

      <!--#endregion-->
    
    </div>
  </div>
  <hr>
  <div >
    <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
    <defs>
    <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
    </defs>
    <g class="parallax">
    <use xlink:href="#gentle-wave" x="48" y="0" fill="#04d9d9" />
    <use xlink:href="#gentle-wave" x="48" y="3" fill="#0396a6" />
    <use xlink:href="#gentle-wave" x="48" y="5" fill="#025e73" />
    <use xlink:href="#gentle-wave" x="48" y="7" fill="#023859" />
    </g>
    </svg>
    </div>
  <!--#endregion-->
</footer>
